import React from 'react'

import {
  Box,
  IconButton
} from '@material-ui/core'
import {
  Add,
  Remove
} from '@material-ui/icons'

import useStyles from './styles'

const ButtonCount = ({ count, setCount, ...rest }) => {
  const handleChangeCount = (number) => {
    if (number <= 0) return
    setCount(number)
  }

  const classes = useStyles()

  return (
    <Box
      mt={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      border={1}
      borderRadius="borderRadius"
      className={classes.root}
      {...rest}
    >
      <IconButton onClick={() => handleChangeCount(count - 1)} disabled={count === 1}>
        <Remove />
      </IconButton>
      {count}
      <IconButton onClick={() => handleChangeCount(count + 1)}>
        <Add />
      </IconButton>
    </Box>
  )
}

export default ButtonCount
