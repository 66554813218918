import axios from 'axios'
import history from 'services/history'

const api = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:3333' : 'https://api.menushop.com.br'
})

api.interceptors.request.use((request) => {
  const token = localStorage.getItem('@menu-shop/token')
  if (token != null) {
    request.headers.authorization = token
  }

  return request
})

api.interceptors.response.use((response) => {
  return response
}, async function (error) {
  if (error.response.status === 403) {
    localStorage.removeItem('@menu-shop/token')
    localStorage.removeItem('@menu-shop/user')
    history.push('/login')
  }
  return Promise.reject(error)
})

export default api
