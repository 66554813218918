import React, { useState, useEffect } from 'react'
import {
  Grid,
  Typography,
  Box,
  Button,
  Paper,
  Divider
} from '@material-ui/core'
import { Line } from 'react-chartjs-2';

import { Snackbar } from 'components'
import { TableGrid } from './components'

import history from 'services/history'
import api from 'services/api'
import useStyles from './styles'
import { requestStatusLabelHelper } from 'helpers'

function Dashboard () {
  const [requests, setRequests] = useState([])
  const [requestsDashboard, setRequestsDashboard] = useState([])
  const [requestsDashboardLabels, setRequestsDashboardLabels] = useState([])
  const [totalRequests, setTotalRequests] = useState(0)
  const [statusPending, setStatusPending] = useState(0)
  const [statusInProgress, setStatusInProgress] = useState(0)
  const [statusFinish, setStatusFinish] = useState(0)
  const classes = useStyles()

  const getRequests = async () => {
    try {
      const data = await api.get('/requests')

      const dataStatus = data.data.map((value) => {
        value.statusId = value.status
        value.status = requestStatusLabelHelper(value.status)
        value.total_price = value?.total_price?.toFixed(2)

        return value
      })
      setTotalRequests(dataStatus.length)
      calcGetRequests(dataStatus)
      setRequests(dataStatus)
    } catch (err) {
      console.log(err)
    }
  }

  const getRequestDashboard = async () => {
    try {
      const response = await api.get('/restaurants/dashboard')
      const values = response.data
      const dataDashboard = Object.keys(values).map((i) => values[i]?.totalPrice)
      setRequestsDashboardLabels(Object.keys(values) || [])
      setRequestsDashboard(dataDashboard || [])
    } catch (err) {
      console.error(err)
    }
  }

  const calcGetRequests = async (data) => {
    const statusPending = []
    const statusInProgress = []
    const statusFinish = []

    data.forEach((value) => {
      if (value.status === 'Em andamento') {
        statusPending.push(value)
      }
      if (value.status === 'Em preparo') {
        statusInProgress.push(value)
      }
      if (value.status === 'Concluído') {
        statusFinish.push(value)
      }
    })

    setStatusPending(statusPending.length)
    setStatusInProgress(statusInProgress.length)
    setStatusFinish(statusFinish.length)
  }

  useEffect(() => {
    getRequests()
    getRequestDashboard()

    // eslint-disable-next-line
  }, [totalRequests])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h4">Dashboard</Typography>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} sm={6} xs={12}>
            <Paper
              className={classes.boxInformation}
              elevation={1}
              component={Box}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              p={1}
            >
              <Box p={1}>
                <Typography variant="h6" component="h6">
                  Total de pedidos
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-end" p={1}>
                <Typography variant="h3" component="h3">
                  {totalRequests}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper
              className={classes.boxInformation}
              elevation={1}
              component={Box}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              p={1}
            >
              <Box p={1}>
                <Typography variant="h6" component="h6">
                  Pedidos em andamento
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant="h3" component="h3">
                  {statusPending}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper
              className={classes.boxInformation}
              elevation={1}
              component={Box}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              p={1}
            >
              <Box p={1}>
                <Typography variant="h6" component="h6">
                  Pedidos em preparo
                </Typography>
              </Box>
              <Box p={1}>
                <Typography variant="h3" component="h3">
                  {statusInProgress}
                </Typography>
              </Box>
            </Paper>
          </Grid>
          <Grid item md={3} sm={6} xs={12}>
            <Paper
              className={classes.boxInformation}
              elevation={1}
              component={Box}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              p={1}
            >
              <Box p={1}>
                <Typography variant="h6" component="h6">
                  Pedidos concluídos
                </Typography>
              </Box>
              <Box display="flex" alignItems="flex-end">
                <Divider />
                <Box p={1}>
                  <Typography variant="h3" component="h3">
                    {statusFinish}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid container component={Box} py={2}>
          <Grid item xs={12}>
            <Paper component={Box} p={1} mb={2} className={classes.chart}>
              <Line
                data={{
                  labels: requestsDashboardLabels,
                  datasets: [{
                    label: 'Faturamento',
                    data: requestsDashboard,
                    backgroundColor: [
                        'rgba(0, 0, 0, 0)'
                    ],
                    borderColor: [
                      '#335DFD'
                    ],
                    borderWidth: 1
                  }]
                }}
                options={{
                  maintainAspectRatio : false
                }}
                heigth={500}
              />
            </Paper>
          </Grid>

          <Grid item md={6} xs={12}>
            <Paper elevation={1} component={Box} p={1}>
              <Box p={1} component={Box} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  Pedidos
                </Typography>
                <Button variant="contained" color="primary" onClick={() => history.push('/app/requests')}>Ver</Button>
              </Box>
              <Divider />
              <Box p={1}>
                <TableGrid rows={requests} />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar />
    </>
  )
}

export default Dashboard
