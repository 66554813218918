import React from 'react'

import {
  LoadingProvider,
  UserProvider,
  SnackbarProvider,
  DialogProvider,
  CartProvider
} from './'

function AppProvider ({ children }) {
  return (
    <>
      <LoadingProvider>
        <SnackbarProvider>
          <DialogProvider>
            <UserProvider>
              <CartProvider>
                {children}
              </CartProvider>
            </UserProvider>
          </DialogProvider>
        </SnackbarProvider>
      </LoadingProvider>
    </>
  )
}

export default AppProvider
