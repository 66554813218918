import React, { useState } from 'react'
import {
  AppBar,
  Toolbar,
  Container,
  Box,
  Button,
  Drawer,
  Hidden,
  IconButton,
  Avatar,
  Popover,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'

import { Link } from 'react-router-dom'
import { Close } from '@material-ui/icons'
import { FiUser, FiMenu, FiLogOut } from 'react-icons/fi'

import history from 'services/history'

import styles from './styles'

import { ReactComponent as Logo } from 'assets/images/logo.svg'

import { useUser } from 'hooks'

export default function Navbar ({ site = false, ...rest }) {
  const classes = styles(rest)

  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  const { signed, user, logout } = useUser()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleUserRequests = () => {
    history.push('/user-requests')
  }

  const handleCart = () => {
    history.push('/cart')
  }

  const handleClickHome = (event) => {
    history.push('/')
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'simple-popover' : undefined

  return (
    <AppBar position="static" color="default" className={classes.appbar}>
      <Container>
        <Toolbar className={classes.tollbar}>
          <Box className={classes.leftTollbar}>
            <Logo className={classes.image} style={{ cursor:'pointer' }} onClick={handleClickHome}   />

            <Hidden xsDown>
              {site && (
                <Box ml={1}>
                  <a className={classes.linkRedirect} href={`${window.location.pathname}#services`}>Serviços</a>
                  <Link className={classes.linkRedirect} to="/">Restaurantes</Link>
                </Box>
              )}
            </Hidden>
          </Box>
          <Hidden xsDown>
            <Box display="flex" justifyContent="center">
              {signed ? (
                <>
                  <Avatar aria-describedby={id} onClick={handleClick} className={classes.avatar}>
                    {user?.name && user.name.split('')[0]}
                  </Avatar>
                  <Popover
                    open={openPopover}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right'
                    }}
                  >
                    <List component="nav">
                      {user?.profile_id === 3 && (
                        <ListItem button>
                          <ListItemText primary="Meus pedidos" onClick={handleUserRequests} /><br />
                        </ListItem>
                      )}
                      <ListItem button>
                        <ListItemText primary="Sair" onClick={logout} />
                      </ListItem>
                    </List>
                  </Popover>
                </>
              ) : (
                <Button
                  className={classes.button}
                  size="medium"
                  onClick={() => history.push('/login')}
                  startIcon={<FiUser size={24} />}
                >
                  Entrar
                </Button>
              )}
            </Box>
          </Hidden>
          <Hidden smUp>
            <Box>
              <IconButton onClick={() => setOpen(true)} className={classes.button} >
                <FiMenu />
              </IconButton>
            </Box>
          </Hidden>
          <Drawer classes={{ paper: classes.drawer }} anchor="right" open={open} onClose={() => setOpen(false)}>
            <IconButton onClick={() => setOpen(false)} className={classes.button} >
              <Close />
            </IconButton>
            {site && (
              <>
                <Button
                  className={classes.buttonNavbar}
                  size="medium"
                  component="a"
                  href={`${window.location.pathname}#services`}
                  onClick={() => setOpen(false)}
                >
                  SERVIÇOS
                </Button>
                <Button
                  className={classes.buttonNavbar}
                  size="medium"
                  onClick={() => history.push('/')}
                >
                  RESTAURANTES
                </Button>
              </>
            )}
            {signed ? (
              <>
                {user?.profile_id === 3 && (
                  <>
                  <Button
                    className={classes.buttonNavbar}
                    size="medium"
                    onClick={handleUserRequests}
                  >
                    Meus pedidos
                  </Button>
                  <Button
                    className={classes.buttonNavbar}
                    size="medium"
                    onClick={handleCart}
                  >
                    Meu Carrinho
                  </Button>
                  </>
                )}
                <Button
                  className={classes.buttonNavbar}
                  size="medium"
                  onClick={logout}
                  startIcon={<FiLogOut size={24} />}
                >
                  Sair
                </Button>
              </>
            ) : (
              <Button
                className={classes.buttonNavbar}
                size="medium"
                onClick={() => history.push('/login')}
                startIcon={<FiUser size={24} className={classes.iconButtonNavBar} />}
              >
                Entrar
              </Button>
            )}

          </Drawer>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
