import React , {useState} from 'react'
import {
  Typography,
  Divider,
  TextField,
  MenuItem,
  FormHelperText,
  InputLabel,
  FormControl,
  Select,
  Button,
  Box,
  Grid
} from '@material-ui/core'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { Dialog, Snackbar } from 'components'
import editSchema from './editSchema'
import createSchema from './createSchema'
import useStyles from './styles'
import api from 'services/api'

import { useDialog, useSnackbar, useUsers } from 'hooks'

function DialogUser ({ profiles, reload, restaurant_id, userss, setUserEdit, getUsers}) {

  const classes = useStyles()
  const { handleClose, mode } = useDialog()
  const { openSnackbar }     = useSnackbar()

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: mode === 'create' ? createSchema : editSchema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(mode === 'create' ? createSchema : editSchema)
  })

  const onSubmit = async (data) => {
    try {
        if (mode === 'create') {
            
            await api.post('/users', {
                name: data.name,
                email: data.email,
                password: data.password,
                profile_id: data.profile_id,
                restaurant_id: restaurant_id,
                phone: {
                  number: data.phoneNumber
                }
            })
            handleClose()
            getUsers()
            openSnackbar('Usuário criado com sucesso')
        }
        if (mode === 'edit') {
            await api.put(`/users/`, {
                adm: true,
                id: userss.id,
                name: data.name,
                profile_id: data.profile_id,
                restaurant_id: restaurant_id,
                phone: {
                  number: data.phoneNumber,
                  area: userss.phone.area,
                  ddd: userss.phone.ddd,
                  id: userss.phone.id
                }
            })
            handleClose()
            getUsers()
            openSnackbar('Usuário atualizado com sucesso')
        }

        reload()
    } catch (error) {
        openSnackbar('Ocorreu um erro: Já existe um usuario com esse E-mail', 'error')
    }
  }
 const trataPerfil = (perfil) => {
    if (perfil === "garcon") {
      return 'Garçon'
    } else if (perfil === "restaurant_responsible") {
      return 'Administrador'
    } 
  } 
  const limpaState = () => {
    setUserEdit([]);
    getUsers()
    handleClose()
  }


  return (
    <>
        <Dialog maxWidth="sm">
        <Typography variant="h5">
          {mode === 'edit' ? 'Editar' : 'Cadastrar' } de Usuários
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Nome"
                inputRef={register}
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
              />
            }
            defaultValue={userss.name || ''}
            control={control}
          />
          <Controller
            name="email"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Email"
                type="email"
                inputRef={register}
                fullWidth
                error={!!errors.email}
                helperText={errors?.email?.message}
                disabled={mode === 'edit'}
              />
            }
            defaultValue={userss?.email || ''}
            control={control}
          />
          <Controller
          name="phoneNumber"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Telefone"
              inputRef={register}
              fullWidth
              defaultValue={userss?.phone?.number || ''}
              error={!!errors.phoneNumber}
              helperText={errors?.phoneNumber?.message}
            />
          }
          control={control}
        />
          {mode === 'create' &&
            <Controller
              name="password"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Senha"
                  inputRef={register}
                  type="password"
                  fullWidth
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  disabled={mode === 'edit'}
                />
              }
              defaultValue={''}
              control={control}
            />
          }
          <FormControl fullWidth className={classes.selectInput} variant="outlined">
            <InputLabel id="categoryIdSelectLabel">Perfil</InputLabel>
            <Controller
              as={
                <Select
                  label="Perfil"
                  labelId="categoryIdSelectLabel"
                  id="categoryIdSelect"
                  variant="outlined"
                >
                  {profiles.map(profile => (
                    <MenuItem key={profile.id} value={profile.id}>
                      {trataPerfil(profile.name)}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="profile_id"
              control={control}
              defaultValue={userss?.profile?.id || ''}
              error={errors && errors.profile_id}
            />
            <FormHelperText>{errors.profile_id && errors.profile_id.message}</FormHelperText>
          </FormControl>

          <Divider />




          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={limpaState}>Voltar</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              className={classes.submitButton}
            >
              Enviar
            </Button>
          </Box>
        </form>
      </Dialog>
      <Snackbar />
    </>
  )
}

export default DialogUser
