import React, { useState } from 'react'

import CartContext from 'contexts/cartContext'

function CartProvider ({ children }) {
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem('@menu-shop/cart')) || [])

  const setCartStorage = (data) => {
    setCart([...data])
    localStorage.setItem('@menu-shop/cart', JSON.stringify(data))
  }

  const getCartStorage = () => {
    return JSON.parse(localStorage.getItem('@menu-shop/cart'))
  }

  return (
    <CartContext.Provider value={{ getCartStorage, setCartStorage, cart }}>
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
