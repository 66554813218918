import React from 'react'
import { Popover, IconButton } from '@material-ui/core'
import { FiMoreVertical } from 'react-icons/fi'

function DropMenu ({ children, onOpen, onClose }) {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
    if (onOpen) onOpen()
  }

  const handleClose = () => {
    setAnchorEl(null)
    if (onClose) onClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>

      <IconButton aria-describedby={id} onClick={handleClick}>
        <FiMoreVertical />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {children}
      </Popover>
    </div>
  )
}

export default DropMenu
