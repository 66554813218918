import React, { useState, useEffect } from 'react'
import {
  Tabs,
  Tab,
  Paper,
  Box,
  CircularProgress
} from '@material-ui/core'

import { useUser } from 'hooks'
import api from 'services/api'

import { Header } from 'components'
import { RestaurantSettings, QRCodeSettings } from './tabs'

function Settings () {
  const [restaurant, setRestaurant] = useState({})
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState(0)

  const { user } = useUser()

  const handleChange = (event, newValue) => {
    console.log(newValue)
    setValue(newValue)
  }
  const getRestaurants = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/restaurants?id=${user.restaurant_id}`)

      setRestaurant(response.data.object.restaurants[0])
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    getRestaurants()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header title="Configurações" withButton={false} />
      <Paper>
        <Tabs
          value={value}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
        >
          <Tab label="Restaurante" value={0} />
          <Tab label="QRCode" value={1} />
        </Tabs>
      </Paper>

      {loading ? (
        <Box
          display='flex'
          height='80vh'
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {value === 0 && <RestaurantSettings restaurant={restaurant} setRestaurant={setRestaurant} getRestaurants={getRestaurants} />}
          {value === 1 && <QRCodeSettings restaurant={restaurant} />}
        </>
      )}
    </>
  )
}

export default Settings
