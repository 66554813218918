import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button
} from '@material-ui/core'

import { DropMenu } from 'components'
import { Header, DialogCategory } from './components'

import { useDialog, useUser, useCategory, useSnackbar } from 'hooks'

import api from 'services/api'

function Category () {
  const { user } = useUser()
  const { setMode, handleOpen } = useDialog()
  const { setCategory } = useCategory()
  const { openSnackbar } = useSnackbar()

  const [categories, setCategories] = useState([])

  function handleOpenDialog (restaurant) {
    setMode('edit')
    setCategory(restaurant)
    handleOpen()
  }

  async function getCategories () {
    const response = await api.get(`/categories?${user.restaurant_id ? 'restaurant_id=' + user.restaurant_id : 'all_categories=true'}`)
    setCategories(response.data.object)
  }

  async function handleDelete (categoryId) {
    try {
      const response = await api.delete(`/categories/${categoryId}`)
      openSnackbar(response.data.message)
      reload()
    } catch (error) {
      openSnackbar(error.response.data.message || error.response.data.error, 'error')
    }
  }

  function reload () {
    getCategories()
  }

  useEffect(() => {
    getCategories()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header title="Categorias de produtos" />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="80%">Categoria</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categories.map((category, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{category.name}</TableCell>
                  <TableCell align="right">
                    <DropMenu>
                      <List>
                        <ListItem>
                          <Button onClick={() => handleOpenDialog(category)}>
                            EDITAR
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button onClick={() => handleDelete(category.id)}>
                            DELETAR
                          </Button>
                        </ListItem>
                      </List>
                    </DropMenu>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogCategory reload={reload} />
    </>
  )
}

export default Category
