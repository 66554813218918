import React, { useState } from 'react'

import RestaurantContext from 'contexts/restaurantContext'

function RestaurantProvider ({ children }) {
  const [restaurant, setRestaurant] = useState({})
  const [categoryToFilter, setCategoryToFilter] = useState('')

  return (
    <RestaurantContext.Provider value={{ restaurant, setRestaurant, categoryToFilter, setCategoryToFilter }}>
      {children}
    </RestaurantContext.Provider>
  )
}

export default RestaurantProvider
