import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    width: '100%',
    height: '250px'
  },
  arrow: {
    color: '#212121 !important'
  }
})
