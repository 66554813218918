import React from 'react'

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

function DialogFeedback ({ open, setOpen, handleSubmit }) {
  const handleClose = () => {
    setOpen(false)
    handleSubmit()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Pedido realizado com sucesso
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogFeedback
