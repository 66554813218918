import React, { useState } from 'react'
import clsx from 'clsx'
import { Box, Container } from '@material-ui/core'
import { isMobile } from 'react-device-detect'

import { Sidebar } from './components'

import useStyles from './styles'

function PlatformTemplate ({ children }) {
  const [open, setOpen] = useState(!isMobile)
  const classes = useStyles()
  return (
    <Box display="flex">
      <Sidebar open={open} setOpen={setOpen} />
      <Box className={clsx(classes.content, {
        [classes.contentShift]: open
      })}>
        <div className={classes.drawerHeader} />
        <Container>
          {children}
        </Container>
      </Box>
    </Box>
  )
}

export default PlatformTemplate
