import React from 'react'
import {
  Typography,
  Divider,
  TextField,
  Button,
  Box,
  Dialog,
  FormControlLabel,
  Checkbox
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import { Snackbar } from 'components'

import useStyles from './styles'
import api from 'services/api'

import { useSnackbar } from 'hooks'

function DialogProductOption ({
  open,
  mode,
  handleClose,
  categoryProductOption,
  productOption = {},
  reload
}) {
  const { openSnackbar } = useSnackbar()

  const classes = useStyles()

  const { register, handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur'
  })

  const onSubmit = async (data) => {
    try {
      if (mode === 'create') {
        await api.post(`/category-product-option/${categoryProductOption.id}/options`, data)
        handleClose()
        openSnackbar('Opcional criado com sucesso')
      }
      if (mode === 'edit') {
        await api.put(`/category-product-option/${categoryProductOption.id}/options/${productOption.id}`, data)
        handleClose()
        openSnackbar('Opcional atualizado com sucesso')
      }
    } catch (error) {
      openSnackbar('Ocorreu um erro', 'error')
    }
    console.log(reload())
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <Box className={classes.dialog}>
          <Typography variant="h5">Cadastro de Opcionais</Typography>
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="name"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Nome"
                  inputRef={register}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                />
              }
              defaultValue={productOption.name || ''}
              control={control}
            />
            <Controller
              name="price"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Preço"
                  inputRef={register}
                  fullWidth
                  type="number"
                  error={!!errors.price}
                  helperText={
                    errors.price &&
                    errors.price.message
                  }
                />
              }
              defaultValue={productOption.price || ''}
              control={control}
            />

            <Controller
              name="available"
              render={(props) =>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={e => props.onChange(e.target.checked)}
                      defaultChecked={productOption.available || false}
                    />
                  }
                  label='Disponível?'
                />
              }
              defaultValue={productOption.available || false}
              control={control}
            />

            <Box className={classes.buttons}>
              <Button variant="outlined" onClick={handleClose}>Voltar</Button>
              {mode !== 'show' &&
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  className={classes.submitButton}
                >
                  Enviar
                </Button>
              }
            </Box>
          </form>
        </Box>
      </Dialog>

      <Snackbar />
    </>
  )
}

export default DialogProductOption
