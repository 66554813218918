import { makeStyles } from '@material-ui/core'

export default makeStyles({
  container: {
    width: 280,
    padding: 10
  },
  printButton: {
    marginTop: 10
  },
  total: {
    textAlign: 'center',
    marginTop: '1em'
  }
})
