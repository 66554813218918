import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    cursor: 'pointer',
    textAlign: '-webkit-center'
  },
  image: {
    width: '160px',
    height: '153px',
    borderRadius: '8px'
  }
})
