import React, { useRef } from 'react'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useReactToPrint } from 'react-to-print'
import { FiPrinter } from 'react-icons/fi'

import PrintRequestHeader from './PrintRequestHeader'
import PrintRequestUserInfo from './PrintRequestUserInfo'
import PrintRequestProductList from './PrintRequestProductList'
import useStyles from './styles'

export default function PrintRequest ({ request }) {
  const componentRef = useRef()
  const classes = useStyles()

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const renderRequestType = (table) => {
    if (table) {
      return (
        <>
          <b>Mesa:</b>{` ${table.number}`}
        </>
      )
    }

    return <b>Entrega</b>
  }

  return (
    <>
      <Button
        fullWidth
        className={classes.printButton}
        startIcon={<FiPrinter />}
        onClick={handlePrint}
      >
        Imprimir
      </Button>

      <div style={{ display: 'none' }}>
        <div ref={componentRef} className={classes.container}>
          {!!request && (
            <>
              <PrintRequestHeader request={request} />

              {renderRequestType(request.table)}

              <PrintRequestUserInfo user={request.user} requestTypeId={request.request_type_id} />

              <Divider />

              <PrintRequestProductList requestProducts={request.request_products} />

              <Box className={classes.total}>
                <Typography>
                  <b>{`Total do Pedido: R$ ${request.total_price.toFixed(2)}`}</b>
                </Typography>
              </Box>
            </>
          )}
        </div>
      </div>
    </>
  )
}
