import React from 'react'
import {
  Paper,
  Box,
  Typography,
  Divider
} from '@material-ui/core'
import { isMobile } from 'react-device-detect'
import { format, parseISO } from 'date-fns'

function formatDate (createdAt) {
  return format(parseISO(createdAt), 'dd/MM/yyyy HH:mm')
}

export default function ListProductRequest ({ request }) {
  return (
    <>
      <Paper elevation={1} component={Box} p={1}>
        <Box p={1} display="flex" flexDirection="column">
          <Typography variant="h6" component="h6">
                Pedido #{request.request_number || request.id}
          </Typography>
          <Typography style={{ textAlign: 'right', marginTop: isMobile ? '-20px' : '-30px', color: '#d90000', fontSize: isMobile ? '11px' : '15px' }}>
            <b>{formatDate(request.created_at)}</b>
          </Typography>
        </Box>
        <Paper variant="outlined" component={Box} p={1} mb={2}>
          <Box p={1} display="flex" flexDirection="column">
            <Typography>
              <b>Usuário:</b> {request.user.name}
            </Typography>
            <Typography>
              <b>Telefone:</b> {request.user.phone.number}
            </Typography>
            <Typography>
              {request.table ? (
                <>
                  <b style={{ color: '#d90000' }}>Mesa: {request.table.number}</b>
                </>
              ) : (
                <b>Para Entrega</b>
              )}
            </Typography>

            <Divider/>

            {request.request_products.map((requestProduct) => (
              <div key={requestProduct.id}>
                <Box py={1} display="flex" flexDirection="column">
                  <Typography style={{ fontSize: isMobile ? '11px' : '13px' }}>
                    {requestProduct.amount}x - {requestProduct.product.name} -
                    <b style={{ fontSize: isMobile ? '11px' : '12px', float: 'right' }}> R$ {requestProduct.current_price.toFixed(2)}</b>
                  </Typography>

                  {requestProduct.note && (
                    <Typography>
                      <b>Anotações:</b> {requestProduct.note}
                    </Typography>
                  )}

                  {requestProduct.product_options_request_products.length > 0 && (
                    <Box pt={1} display="flex" flexDirection="column">
                      <Typography><b>Adicionais</b></Typography>

                      {requestProduct.product_options_request_products.map((requestProductOption) => (
                        <Typography key={requestProductOption.id}>
                          {requestProductOption.amount}x - {requestProductOption.product_option.name} - <b>Valor Unit.: R$ ${requestProductOption.product_option.price.toFixed(2)}</b>
                        </Typography>
                      ))}
                    </Box>
                  )}
                </Box>

                <Divider />
              </div>
            ))}

            <Box pt={1}>
              <Typography>
                <b>Preço Total: R$ {request.total_price.toFixed(2)}</b>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Paper>
    </>
  )
}
