import React, { useState, useEffect } from 'react'

import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  CircularProgress
} from '@material-ui/core'
import api from 'services/api'

const DialogSelectTable = ({ open, setOpen, handleSubmit, restaurantId }) => {
  const [value, setValue] = useState('')
  const [tables, setTables] = useState([])
  const [loading, setLoading] = useState(true)

  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    handleClose()
    handleSubmit(value)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const getTables = async () => {
    try {
      setLoading(true)
      const response = await api.get(`/restaurants/${restaurantId}/tables/availables/true`)

      setTables(response.data.object)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (open) getTables()

  // eslint-disable-next-line
  }, [open])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Selecione a mesa</DialogTitle>

      <DialogContent>
        <Box>
          {loading ? (
            <Box
              display='flex'
              width='100%'
              justifyContent='center'
              alignItems='center'
            >
              <CircularProgress />
            </Box>
          ) : (
            <FormControl variant="outlined" fullWidth>
              <InputLabel id="demo-simple-select-outlined-label">Mesa</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={value}
                onChange={handleChange}
                label="Mesa"
              >
                {tables.map((table) => <MenuItem key={table.id} value={table.id}>{table.number}</MenuItem>)}
              </Select>
            </FormControl>
          )}
        </Box>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={submit} disabled={value === ''} color="primary">Confirmar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogSelectTable
