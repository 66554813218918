import React from 'react'

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

function DialogConfirmation ({
  open,
  setOpen,
  handleSubmit,
  message,
  declineButton = 'Não',
  acceptedButton = 'Sim'
}) {
  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    handleClose()
    handleSubmit()
  }

  return (
    <Dialog open={open} setOpen={setOpen}>
      <DialogTitle>
        {message}
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>
          {declineButton}
        </Button>
        <Button onClick={submit} color="primary">
          {acceptedButton}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmation
