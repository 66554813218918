import React from 'react'

import Carousel from 'react-material-ui-carousel'

import banner from 'assets/images/banner.jpeg'
import banner2 from 'assets/images/banner2.jpg'
import banner3 from 'assets/images/banner3.jpg'
import banner4 from 'assets/images/banner4.jpg'

import {
  Grid,
  Box,
  Container,
  Card,
  CardContent,
  CardMedia,
  CardActionArea
} from '@material-ui/core'
import H1 from 'components/Typography/H1'
import Span from 'components/Typography/Span'
import { isMobile } from 'react-device-detect'

const HomeCarousel = ({ classes }) => {
  const carouselItemsTexts = [
    {
      title: 'Acesso dinâmico  a todos os Pratos',
      description: 'No Menushop é possível ilustrar os pratos, proporcionando uma fantástica experiência visual aos clientes. Você Também vai ter um site grátis exclusivo com as informações da sua empresa e um cardápio online para divulgação em mídias sociais.',
      banner: banner2
    },
    {
      title: 'Segurança e modernidade, para atuar em um novo cenário.',
      description: 'Com diversos países superando a pandemia da Covid-19, chegou a sua vez de começar a experimentar a flexibilização do comércio. Neste cenário, as empresas obtém um cardápio digital, que se torna uma solução para se adaptar ao novo normal e seguir trabalhando.',
      banner: banner3
    },
    {
      title: 'Mais rapidez nos atendimentos.',
      description: 'Com o aumento na procura pelos cardápios digitais nos últimos meses, a nossa tecnologia ajuda os estabelecimentos, a funcionarem com a equipe reduzida, evitando o contato direto e aglomerações.',
      banner: banner
    },
    {
      title: 'Sistema de Autoatendimento e economia!',
      description: 'O cardápio online traz economia de tempo e dinheiro, sem cardápios impressos, nem grandes equipes, com acompanhamento de pedidos direto do sistema. Os clientes podem fazer seus pedidos pelo celular, através QR-code.',
      banner: banner4
    }
  ]

  return (
    <div className={classes.padding_bottom_32}>
      <Carousel
        autoPlay
        interval={8000}
        animation={'fade'}
      >
        {
          carouselItemsTexts.map(({ title, description, banner }) =>
            <CarouselItem
              key={title}
              title={title}
              description={description}
              classes={classes}
              image={banner}
            />
          )
        }
      </Carousel>
    </div>
  )
}

const CarouselItem = ({ classes, image, title, description }) => (
  <Box >
    <Container className={classes.container}>
      {isMobile
        ? <Card className={classes.root} variant="outlined">
          <CardActionArea>
            <CardMedia
              component="img"
              alt="info"
              height="140"
              image={image}
            />
            <CardContent>
              <H1 className={classes.service_text_span} gutterBottom variant="h5" component="h2">
                {title}
              </H1>
              <Span className={classes.description_card_service_span} variant="body2" color="textSecondary" component="p">
                {description}
              </Span>
            </CardContent>
          </CardActionArea>
        </Card>
        : <Grid className={classes.carousel_section} container item xs={10} >
          <Grid item xs={12} md={6} className={classes.align_image_carrousel_center}>
            <img width="100%" src={image} alt="example-img" className={classes.max_width_image_carrousel}/>
          </Grid>
          <Grid item xs={1} md={1} />
          <Grid item xs={12} md={5}>
            <H1 className={classes.service_text_span}>{title}</H1>
            <Span className={classes.description_card_service_span}>{description}</Span>
          </Grid>
        </Grid>}
    </Container>
  </Box >
)

export default HomeCarousel
