import React from 'react'
import RestaurantContainer from './RestaurantContainer/'

import { Grid, Container, Box } from '@material-ui/core'

import useStyle from './RestaurantContainer/styles'
import { Pagination } from '@material-ui/lab'
const RestaurantGrid = ({ restaurants, count, page, changePage }) => {
  const classes = useStyle()
  return (
    <Container>
      <Box fontSize="h2.fontSize" fontWeight="fontWeightBold" my={3}>
        Restaurantes
      </Box>
      <Grid container spacing={3}>
        {
          restaurants.map((restaurant) => (
            <Grid item xs={12} md={6} lg={4} className={classes.alignCenter} key={restaurant.id}>
              <RestaurantContainer data={restaurant} />
            </Grid>
          ))
        }
      </Grid>
      <Box display="flex" justifyContent='flex-end' my={4}>
        <Pagination count={count} page={page} onChange={changePage} />
      </Box>
    </Container>
  )
}

export default RestaurantGrid
