import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => {
  return {
    dialog: {
      padding: 0
    },
    media: {
      width: '100%',
      height: '160px',
      backgroundColor: '#c4c4c4',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    },
    optionsHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    optionsChip: {
      backgroundColor: theme.palette.grey[900],
      color: theme.palette.grey[200]
    },
    radio: {
      display: 'flex',
      justifyContent: 'flex-end'
    }
  }
})
