/**
 * @param {string} search - Search string
 * @param {string} queryName - Query name
 * @returns {string | null} queryValue
 */
export function getQueryByName (search, queryName) {
  const queryObj = search.replace('?', '').split('&').reduce((prev, curr) => {
    const [key, value] = curr.split('=')

    return {
      [key]: value
    }
  }, {})

  try {
    return queryObj[queryName]
  } catch (e) {
    return null
  }
}
