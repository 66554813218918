import React, { useState } from 'react'

import CategoryProductOptionContext from 'contexts/categoryProductOptionContext'

function CategoryProductOptionProvider ({ children }) {
  const [categoryProductOption, setCategoryProductOption] = useState({})

  return (
    <CategoryProductOptionContext.Provider value={{ categoryProductOption, setCategoryProductOption }}>
      {children}
    </CategoryProductOptionContext.Provider>
  )
}

export default CategoryProductOptionProvider
