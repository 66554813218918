import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  dialog: {
    padding: 24
  },
  buttons: {
    marginTop: theme.spacing(2)
  }
}))
