import React, { useState } from 'react'
import clsx from 'clsx'
import {
  AppBar,
  Toolbar,
  Box,
  Drawer,
  IconButton,
  Avatar,
  Popover,
  List,
  ListItem,
  ListItemText,
  Hidden
} from '@material-ui/core'
import { FiMenu } from 'react-icons/fi'

import styles from './styles'

import { List as SidebarList } from './components'
import { ReactComponent as Logo } from 'assets/images/logo.svg'

import { useUser } from 'hooks'

export default function Sidebar ({ open, setOpen }) {
  const classes = styles()
  const [anchorEl, setAnchorEl] = useState(null)

  const { user, logout } = useUser()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const openPopover = Boolean(anchorEl)
  const id = openPopover ? 'simple-popover' : undefined

  const items = [
    {
      title: 'Geral',
      options: [
        {
          title: 'Restaurantes',
          url: '/admin',
          only: [1]
        },
        {
          title: 'Categorias',
          url: '/admin/categories',
          only: [1]
        },
        {
          title: 'Dashboard',
          url: '/app',
          only: [2]
        },
        {
          title: 'Produtos',
          url: '/app/products',
          only: [2]
        },
        {
          title: 'Pedidos',
          url: '/app/requests',
          only: [2]
        },
        {
          title: 'Pedidos na Mesa',
          url: '/garcon/requestsTablesAdd',
          only: [4]
        },
        {
          title: 'Mesas',
          url: '/app/tables',
          only: [2]
        }
      ],
      only: [1, 2,4]
    },
    {
      title: 'Categorias',
      options: [
        {
          title: 'Produtos',
          url: '/app/categories',
          only: [2]
        },
        {
          title: 'Adicionais',
          url: '/app/category-product-options',
          only: [2]
        }
      ],
      only: [2]
    },
    {
      title: 'Configurações',
      options: [
        {
          title: 'Configurações',
          url: '/app/settings',
          only: [2]
        }
      ],
      only: [2]
    },
    {
      title: 'Usuários',
      options: [
        {
          title: 'Usuários',
          url: '/app/users',
          only: [2]
        }
      ],
      only: [2]
    }
  ]

  const handleDrawerOpen = () => {
    if (open === true) {
      return setOpen(false)
    }
    setOpen(true)
  }

  return (
    <>
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Box className={classes.alignVertical}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={classes.menuButton}
            >
              <FiMenu />
            </IconButton>
            <Hidden xsDown>
              <Logo className={classes.image} />
            </Hidden>
          </Box>
          <Box display="flex" justifyContent="center">
            <Avatar aria-describedby={id} onClick={handleClick} className={classes.avatar}>
              {user.name && user.name.split('')[0]}
            </Avatar>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <List component="nav">
                <ListItem button>
                  <ListItemText primary="Sair" onClick={logout} />
                </ListItem>
              </List>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbarMain}></div>
        <SidebarList items={items} />
      </Drawer>
    </>
  )
}
