import React from 'react'

import { Box, Paper, Button } from '@material-ui/core'
import { CloudDownload as CloudDownloadIcon } from '@material-ui/icons'
import QRCode from 'qrcode.react'

const QRCodeSettings = ({ restaurant }) => {
  const downloadQR = () => {
    const canvas = document.getElementById('qrcode')
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = 'QRCode.png'
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Box mt={3}>
      <Box component={Paper} p={3} display="flex" alignItems="center" flexDirection="column">
        <QRCode
          id="qrcode"
          value={`${window.location.protocol}//${window.location.hostname}/restaurants/${restaurant.slug}`}
          size={290}
        />
        <Box mt={2} display="flex" flexDirection="column">
          <a href={`${window.location.protocol}//${window.location.hostname}/restaurants/${restaurant.slug}`}>
            {`${window.location.protocol}//${window.location.hostname}/restaurants/${restaurant.slug}`}
          </a>
          <Button
            color="primary"
            startIcon={<CloudDownloadIcon />}
            onClick={downloadQR}
          >
            Download QRCode
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default QRCodeSettings
