import React from 'react'
import { Box, Button } from '@material-ui/core'
import { FiPlus } from 'react-icons/fi'

import { Title } from 'components'
import useStyles from './styles'

import { useDialog } from 'hooks'

function Header ({ title, withButton = true }) {
  const classes = useStyles()
  const { handleOpen, setMode } = useDialog()
  const handleOpenDiolog = () => {
    setMode('create')
    handleOpen()
  }

  return (
    <Box className={classes.header} display="flex" justifyContent="space-between" alignItems="center">
      <Title title={title} />

      {withButton && (
        <Button
          color="secondary"
          variant="contained"
          startIcon={<FiPlus />}
          onClick={() => handleOpenDiolog()}
        >
          Novo
        </Button>
      )}
    </Box>
  )
}

export default Header
