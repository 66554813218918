import React from 'react'
import { Box, Container, Grid, Hidden } from '@material-ui/core'
import { CustomView, isMobile, isMobileOnly } from 'react-device-detect'
import { FiFileText, FiEdit2, FiBarChart2 } from 'react-icons/fi'
import { RiQrCodeLine } from 'react-icons/ri'

import { H1, Span, Navbar } from 'components'
import { TextService, Carousel, ClipPathTop, CardService, Footer } from './components'

import styles from './styles'
import theme from 'config/theme'

export default function Home (props) {
  const classes = styles(props)
  return (
    <>
      <Navbar site />
      <Box className={classes.first_section}>
        <Container className={`${classes.container} ${!isMobile && classes.padding_top_60} ${isMobile && classes.mobile_first_section}`}>
          <Grid className={classes.menu_shop_section} container item xs={10}>
            <Grid item xs={12} md={5}>
              <H1 className={classes.title_first_section}>Com MenuShop você faz pedido na palma da mão</H1>
              <p className={classes.subtitle_first_section}>
                Agora o cliente pode ver o cardápio de uma forma fácil e rápido.
                O cliente tem total autonomia para acessar o estabelecimento e fazer o seu pedido.
              </p>
            </Grid>
            <Hidden smDown>
              <Grid item xs={12} md={7} className={classes.image_grid_section}>
                <img src="assets/images/devices.png" alt="Devices" className={classes.devicesSVG} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </Box>
      <CustomView condition={!isMobileOnly}>
        <ClipPathTop />
      </CustomView>
      <CustomView condition={isMobileOnly}>
        <ClipPathTop classes={classes.mobile_align}/>
      </CustomView>
      <Carousel classes={classes} />
      <Box className={classes.third_section} id="services">
        <Container className={classes.container} >
          <Grid item xs={10} >
            <Grid item xs={12} md={12}>
              <div className={classes.ours_text_div}>
                <Span className={classes.text_style_services} variant="subtitle2">NOSSOS</Span>
              </div>
              <Span className={classes.service_text_span}>Serviços</Span>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className={classes.fourth_section}>
        <Container className={classes.container} >
          <Grid container item xs={10} >
            <Grid container item xs={12} sm={12} md={7}>
              <CardService
                CardIcon={FiFileText}
                classes={classes}
                backgroundIconColor={theme.palette.primary.main}
                title={'Cardápio Online'}
                description={'Nós oferecemos um cardápio online aos seus clientes com uma plataforma totalmente visual'}
              />
              <CardService
                CardIcon={FiEdit2}
                classes={classes}
                backgroundIconColor={theme.palette.primary.main}
                title={'Pedidos'}
                description={'Todos os pedidos são encaminhados para a cozinha facilitando o sistema de comandas'}
              />
              <CardService
                CardIcon={RiQrCodeLine}
                classes={classes}
                backgroundIconColor={theme.palette.secondary.main}
                title={'Gerador de QRcode'}
                description={'O sistema oferece links de seus cardápio facilitando o acesso, além de uma pagina web gratuíta'}
              />
              <CardService
                CardIcon={FiBarChart2}
                classes={classes}
                backgroundIconColor={theme.palette.secondary.main}
                title={'Gerenciar Mesas'}
                description={'O gerenciamento de mesas ficou mais fácil, com um painel dinamico mostrando todas as mesas e pedidos'}
              />
            </Grid>
            <Grid item xs={2} md={1} />
            <Grid item xs={12} sm={12} md={4} className={classes.grid_service_section}>
              <TextService
                title={'O Cardápio é só para restaurantes?'}
                text={
                  'O cardapio é para todos os estabelecimento de entrega, pedidos na mesa, hamburguerias, pizzarias, panificadoras, confeitarias, sorveterias, encomendas para festas, disk marmitas, e outros.'}
                classes={classes} />
              <TextService
                title={'Como funciona o cardápio online?'}
                text={
                  'O cardápio online é uma versão do cardápio físico, a diferença que os clientes poderão acessá-lo de de qualquer lugar, com informações, fotos e preços sempre atualizados.'
                }
                classes={classes} />
              <TextService
                title={'Como você controla seu cardápio?'}
                text={
                  'Com a opção de cardápio disponível em formato digital, o cliente não apenas pode conferir informações de cada prato como ao chegar no estabelecimento, ele aponta a câmera do seu smartphone para este código e visualiza o cardápio completo.'
                }
                classes={classes} />
              <TextService
                title={'Como funciona os pedidos?'}
                text={
                  'O Estabelecimento recebe a informação sobre o número da mesa, nome e telefone do cliente que escaneou o QR-Code e o pedido é transferido diretamente para a cozinha e o caixa.'
                }
                classes={classes} />
            </Grid>
          </Grid>
        </Container>
        <Footer classes={classes} />
      </Box>
    </>
  )
}
