import React from 'react'
import {
  Dialog,
  Box,
  Typography,
  Divider,
  InputLabel,
  FormControl,
  Button,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { Snackbar } from 'components'

import useStyles from './styles'
import schema from './schema'
import api from 'services/api'
import { useSnackbar } from 'hooks'

function ChangeStatusDialog ({
  open,
  setOpen,
  size = 'md',
  children,
  selectedTable,
  reload,
  ...rest
}) {
  const classes = useStyles()
  const { openSnackbar } = useSnackbar()

  function handleClose () {
    return setOpen(false)
  }

  const { handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data) => {
    try {
      await api.put(`/restaurants/tables/${selectedTable.id}/change_status`, data)
      reload()
      handleClose()
      openSnackbar('Status atualizado com sucesso')
    } catch (err) {
      openSnackbar('Ocorreu um erro ao atualizar o status, tente novamente mais tarde!')
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={size}
      fullWidth
      {...rest}
    >
      <Box
        className={classes.dialog}
      >
        <Box mb={2}>
          <Typography variant="h5">
            Troca do status
          </Typography>
        </Box>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth className={classes.selectInput} variant="outlined">
            <InputLabel id="categoryIdSelectLabel">Categoria</InputLabel>
            <Controller
              as={
                <Select
                  label="Categoria"
                  labelId="categoryIdSelectLabel"
                  id="categoryIdSelect"
                  variant="outlined"
                >
                  <MenuItem value={1}>Disponível</MenuItem>
                  <MenuItem value={2}>Mesa em andamento</MenuItem>
                  <MenuItem value={3}>Ocupado</MenuItem>
                </Select>
              }
              name="status"
              control={control}
              defaultValue={selectedTable.status}
              error={errors && errors.status}
            />
            <FormHelperText>{errors.status && errors.status.message}</FormHelperText>
          </FormControl>

          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={handleClose}>Voltar</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              className={classes.submitButton}
            >
              Enviar
            </Button>
          </Box>
        </form>

        <Snackbar />
      </Box>
    </Dialog>
  )
}

export default ChangeStatusDialog
