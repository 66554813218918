import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    width: '100%',
    height: '350px'
  },
  
  restaurantClosed: {
    backgroundColor:"red",
    color: "#FFFFFF",
    justifyContent: 'space-between',
    position: "fixed",
    paddingLeft: "30px",
    fontWeight: "bold",
    //zIndex: "9999",
    bottom: "100px",
    textAlign: "center",
    fontSize: "25px"
  }
})

