import React from 'react'

import {
  List,
  ListItem,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box
} from '@material-ui/core'

import { DropMenu } from 'components'

import useStyle from './styles'
import api from 'services/api'
import { useSnackbar } from 'hooks'

export default function SquareTable ({ type, table, reload, handleOpenChangeStatusDialog, handleQrcode }) {
  const classes = useStyle()
  const { openSnackbar } = useSnackbar()

  const handleDelete = async (id) => {
    try {
      const validate = window.confirm('Deseja excluir a mesa?')
      if (validate) {
        await api.delete(`/restaurants/tables/${id}`)

        openSnackbar('Mesa deletada com sucesso!')
        reload()
      }
    } catch (error) {
      openSnackbar('Erro ao deletar Mesa!', 'error')
    }
  }

  return (
    <Card className={[classes[type]]}>
      <CardContent className={classes.content}>
        <Box className={classes.iconButton}>
          <DropMenu>
            <List>
              <ListItem>
                <Button onClick={handleOpenChangeStatusDialog}>
                  TROCAR DE STATUS
                </Button>
              </ListItem>
              <ListItem>
                <Button onClick={() => handleDelete(table.id)}>
                  DELETAR
                </Button>
              </ListItem>
              <ListItem>
                <Button onClick={() => handleQrcode(table.id)}>
                  IMPRIMIR QRCODE
                </Button>
              </ListItem>
            </List>
          </DropMenu>
        </Box>
        <Typography variant="h3" className={classes.text}>
          {table.number}
        </Typography>
      </CardContent>
      <CardActions className={classes.action}>
        <Typography className={classes.text} variant="subtitle2">
          {type === 'available' ? 'Disponível'
            : type === 'orderInProgress' ? 'Mesa em andamento'
              : 'Ocupado'}
        </Typography>
      </CardActions>
    </Card>
  )
}
