import React from 'react'
import { Box } from '@material-ui/core'
import { Title } from 'components'
import useStyles from './styles'

//import { useDialog } from 'hooks'

function Header ({ title }) {
  const classes = useStyles()
  //const { handleOpen, setMode } = useDialog()
/*  const handleOpenDiolog = () => {
    setMode('create')
    handleOpen()
  }
*/
  return (
    <Box className={classes.header} display="flex" justifyContent="space-between" alignItems="center">
      <Title title={title} />
    </Box>
  )
}

export default Header
