import React from 'react'
import { Button, CircularProgress } from '@material-ui/core'

import { useLoading } from 'hooks'

function LoadingButton ({ children, size = 40, circularColor = 'inherit', ...rest }) {
  const { loading } = useLoading()
  return (
    <Button {...rest}>
      {loading ? <CircularProgress color={circularColor} size={size} /> : children}
    </Button>
  )
}

export default LoadingButton
