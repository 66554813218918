//import ReactDOM from "react-dom";
//import React, { useState, useEffect, useRef } from 'react'
import React from 'react'
import {
  //Grid,
 // Typography,
//  Button,
//  Chip,
//  Box,
 // Paper,
  Divider,
  // TextField
} from '@material-ui/core'


//import { FiPrinter,FiPlus } from 'react-icons/fi'
import { Snackbar } from 'components'

//import { useSnackbar } from 'hooks'

const PrintRequest = React.forwardRef((props, ref) => {
  //const { openSnackbar } = useSnackbar()
    return (
    <>
      <div ref={ref}  style={{ width:'280px', padding: '10px'}}>
        <div>
          {props.xselect?.id && (
            <div style={{ fontSize:'12px',lineHeight: '20px' }}>
              <div>
                <div>
                    Pedido #{props.xselect.request_number || props.xselect.id}
                    <b  style={{ float:'right' }}>{props.xselect.status}</b>
                    <p>Data Pedido: {props.xselect.created_at}</p>
                </div>
              </div>
              <div>
                <div>
                  <div>
                    <b>Cliente:</b> {props.xdescriptionRequests?.user?.name}
                  </div>
                  <div>
                    <b>Fone:</b> {props.xdescriptionRequests?.user?.phone?.number}
                  </div>
                  <div style={{ fontSize:'12px' }}>
                  Tipo: 
                    {props.xdescriptionRequests?.table ? (
                      <>
                         <b>Mesa: {props.xdescriptionRequests?.table?.number}</b>
                      </>
                    ): (
                     <>
                        <b>Entrega</b>
                     </>
                    )}
                  </div>
                   {props.xselect?.request_type_id === 2 && (
                    <div>
                        {props.xdescriptionRequests?.user?.users_address? (
                          <>
                            <p style={{ fontSize:'12px' }}>Endereço de entrega:</p> 
                            <p style={{ fontSize:'12px' }}>
                            {props.xdescriptionRequests?.user?.users_address?.address}, 
                            Nº {props.xdescriptionRequests?.user?.users_address?.number} - 
                            {props.xdescriptionRequests?.user?.users_address?.complement} - 
                            {props.xdescriptionRequests?.user?.users_address?.district} - 
                            {props.xdescriptionRequests?.user?.users_address?.zip_code} - 
                            {props.xdescriptionRequests?.user?.users_address?.city} / 
                            {props.xdescriptionRequests?.user?.users_address?.state} 
                            </p> 
                          </>
                        ): (
                          <p></p>
                        )}
                      </div>
                  )}
                      <Divider />
                  {props.xdescriptionRequests?.request_products.map((request_product) => (
                    <div key={request_product.id}>
                      <div>
                        <div style={{ fontSize:'12px',lineHeight: '25px', flexWrap: 'wrap' }}>
                          {request_product?.amount}x - {request_product?.product?.name} - 
                          <b style={{ fontSize:'12px',float: 'right' }}> R$ {request_product?.current_price?.toFixed(2)}</b>
                        </div>

                        {request_product?.note && (  
                          <em> 
                            <b>Anotações:</b> {request_product.note}
                          </em>
                        )}

                        {request_product?.product_options_request_products?.length > 0 && (
                          <div>
                            <div><b>Adicionais</b></div>
      
                            {request_product.product_options_request_products.map((product_options_request_product) => (
                              <em>
                                {product_options_request_product?.amount}x - {product_options_request_product?.product_option?.name} - <b>Valor Unit.: R$ {product_options_request_product?.current_price?.toFixed(2)}</b>
                              </em>
                            ))}
                          </div>
                        )}
                      </div>

                      <Divider />
                    </div>
                  ))}
                      {/* <Divider /> */}
                  
                  <div>
                    <div style={{ textAlign:'center' }}>
                      <b>Total do Pedido: R$ {props.xdescriptionRequests?.total_price?.toFixed(2)}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Snackbar />
    </>
  )
});

export default PrintRequest;
