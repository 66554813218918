import React from 'react'

const ClipPathTop = ({ classes }) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    className={classes ? classes.mobile_align : ''}
    viewBox="0 0 1440 320"
    id="clipPathTop"
  >
    <path fill="#D10119" fillOpacity="1" d="M2046.5 0L1957.09 26.8757L1531.42 140.835C1361.15 186.419 1190.88 232.003 1020.61 216.808C850.343 201.614 680.074 125.641 509.806 110.446C339.537 95.2514 169.269 140.835 84.1343 163.627L-1 186.419V0L2046.5 0Z"></path>
  </svg>
)

const ClipPathBottom = ({ classes }) => (
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1440 220"
    preserveAspectRatio="xMidYMid meet"
    fill="#D10119"
    className={classes.clip_path_bottom}
    style={{ marginBottom: '-4' }}
  >
    <g transform="translate(0.000000,220.000000) scale(0.100000,-0.100000)">
      <path d="M3090 2190 c-866 -49 -1916 -231 -3052 -531 l-38 -10 0 -824 0 -825
        7200 0 7200 0 0 931 c0 881 -1 930 -17 925 -58 -16 -1064 -284 -1243 -330
        -945 -244 -1820 -390 -2615 -436 -222 -13 -807 -13 -1010 0 -729 47 -1327 165
        -2768 549 -1354 361 -1921 477 -2622 537 -214 18 -817 26 -1035 14z m4775
        -1105 c21 -8 39 -25 45 -41 7 -17 10 -144 8 -345 -3 -316 -3 -319 -26 -341
        l-23 -23 -652 -3 c-477 -2 -659 1 -680 9 -57 24 -57 25 -57 370 0 308 1 317
        21 343 12 15 31 32 43 36 11 5 306 9 654 9 531 1 638 -1 667 -14z"
      />
      <path d="M6553 1070 c-36 -15 -55 -53 -51 -107 l3 -48 53 -14 52 -14 0 -169
        c0 -126 -3 -170 -12 -173 -7 -2 -32 -9 -55 -15 l-43 -11 0 -59 c0 -65 17 -94
        62 -105 13 -3 310 -4 660 -3 596 3 638 4 655 21 16 16 18 45 21 315 2 163 0
        312 -3 329 -12 65 5 63 -692 62 -345 0 -638 -4 -650 -9z m740 -131 c10 -13 21
        -36 24 -52 3 -16 13 -31 22 -35 24 -8 31 -23 31 -64 l0 -38 -51 0 -52 0 6 71
        c5 61 3 73 -14 90 -23 23 -52 25 -73 4 -21 -21 -31 -58 -17 -63 6 -2 11 -25
        11 -53 l0 -49 -50 0 -50 0 0 46 c0 37 4 47 20 51 12 3 20 14 20 27 1 30 23 66
        51 83 34 19 99 10 122 -18z m-53 -104 c0 -8 -4 -15 -10 -15 -5 0 -10 -9 -10
        -20 0 -22 16 -27 23 -7 2 7 8 8 13 3 5 -5 4 -11 -3 -13 -15 -5 -18 -53 -3 -53
        6 0 10 -11 10 -25 0 -14 5 -25 11 -25 14 0 39 25 39 39 0 6 9 11 20 11 11 0
        20 -4 20 -10 0 -5 6 -10 14 -10 8 0 16 -13 18 -30 4 -29 3 -30 -34 -30 -27 0
        -38 4 -38 15 0 9 10 15 26 15 14 0 23 4 19 10 -14 22 -65 -1 -65 -29 0 -6 -7
        -11 -15 -11 -8 0 -15 7 -15 15 0 8 -4 15 -10 15 -5 0 -10 11 -10 25 0 20 -5
        25 -25 25 -16 0 -25 -6 -25 -15 0 -9 -9 -15 -25 -15 -16 0 -25 6 -25 15 0 9 9
        15 23 15 25 0 58 41 38 48 -16 5 -14 29 6 52 21 24 33 26 33 5z m-120 -116 c0
        -14 25 -39 39 -39 6 0 11 -7 11 -15 0 -8 -7 -15 -15 -15 -8 0 -15 5 -15 11 0
        16 -29 41 -40 34 -5 -3 -10 -15 -10 -26 0 -10 -4 -19 -10 -19 -16 0 -11 38 8
        59 19 22 32 26 32 10z m100 -44 c0 -14 5 -25 10 -25 6 0 10 -7 10 -15 0 -8 -4
        -15 -10 -15 -5 0 -10 -13 -10 -30 0 -25 4 -30 25 -30 16 0 25 -6 25 -15 0 -8
        7 -15 15 -15 8 0 15 -7 15 -15 0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 -7
        15 -15 15 -8 0 -15 -7 -15 -15 0 -8 -7 -15 -15 -15 -10 0 -15 10 -15 30 0 17
        -4 30 -10 30 -5 0 -10 14 -10 30 0 17 5 30 10 30 6 0 10 7 10 15 0 8 -4 15
        -10 15 -5 0 -10 11 -10 25 0 16 6 25 15 25 9 0 15 -9 15 -25z m-50 -110 l0
        -65 -61 0 -62 0 7 38 c3 20 6 49 6 65 0 26 2 27 55 27 l55 0 0 -65z m120 40
        c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 5 15 10 15 6 0 10 -7 10 -15 0 -8 -4
        -15 -10 -15 -5 0 -10 -7 -10 -15 0 -9 10 -15 26 -15 23 0 25 3 19 30 -5 23 -3
        30 8 30 9 0 17 -12 19 -30 3 -20 0 -30 -9 -30 -7 0 -13 -7 -13 -15 0 -8 -4
        -15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -10 0 -15 10 -15 30 0
        20 -5 30 -15 30 -8 0 -15 7 -15 15 0 9 -9 15 -25 15 -16 0 -25 6 -25 15 0 9 9
        15 25 15 16 0 25 -6 25 -15z m-502 -172 c3 -22 1 -23 -82 -23 -85 0 -86 0 -86
        25 0 26 0 26 83 23 76 -3 82 -5 85 -25z m140 0 c3 -21 -1 -23 -37 -23 -42 0
        -57 11 -47 36 4 11 17 14 43 12 31 -2 39 -7 41 -25z"
      />
      <path d="M7108 814 c-5 -4 -8 -16 -8 -26 0 -13 7 -18 25 -18 19 0 25 5 24 23
        0 12 -3 16 -6 10 -7 -18 -21 -16 -25 3 -2 9 -6 12 -10 8z"
      />
      <path d="M7327 803 c5 -12 2 -15 -10 -10 -11 4 -17 1 -17 -8 0 -9 10 -15 25
        -15 14 0 25 4 25 9 0 17 -12 41 -21 41 -4 0 -5 -8 -2 -17z"
      />
      <path d="M7086 578 c-10 -41 -7 -48 24 -48 28 0 30 3 30 35 0 30 -3 35 -24 35
        -16 0 -26 -7 -30 -22z m44 -13 c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7
        15 15 15 8 0 15 -7 15 -15z"
      />
      <path d="M6500 716 l0 -184 48 12 47 12 3 160 2 161 -38 11 c-22 7 -44 12 -51
        12 -8 0 -11 -53 -11 -184z"
      />
    </g>
  </svg>
)

export {
  ClipPathBottom,
  ClipPathTop
}
