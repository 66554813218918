import React, { useState, useEffect } from 'react'

import { CircularProgress, Box, InputBase, Paper, Typography, IconButton } from '@material-ui/core'
import { BasicTemplate } from 'templates'
import { Scrollbar, RestaurantGrid } from './components'

import { Search } from '@material-ui/icons'
import useStyles from './styles'
import { useRestaurant } from 'hooks'

import api from 'services/api'

const RestaurantList = () => {
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(null)
  const [page, setPage] = useState(1)
  const [perPage] = useState(20)
  const [restaurants, setRestaurants] = useState([])
  const [categories, setCategories] = useState([])
  const [searchByRestaurant, setSearchByRestaurant] = useState('')

  const { categoryToFilter } = useRestaurant()
  const classes = useStyles()

  async function getRestaurantsAndCategories () {
    setLoading(true)
    try {
      const responseRestaurant = await api.get(`/restaurants?page=${page}&per_page=${perPage}&name=${searchByRestaurant}&category_id=${categoryToFilter}`)
      const responseCategory = await api.get('/categories?all_categories=true')
      setRestaurants(responseRestaurant.data.object.restaurants)
      setCategories(responseCategory.data.object)

      const countPagination = Math.floor(responseRestaurant.data.object.count / perPage)
      if (countPagination === 0) {
        setCount(1)
      } else {
        setCount(countPagination)
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  const changePage = (event, value) => {
    setPage(value)
  }

  const handleSubmit = (e) => {
    getRestaurantsAndCategories()
    e.preventDefault()
  }

  useEffect(() => {
    getRestaurantsAndCategories()

    // eslint-disable-next-line
  }, [page, categoryToFilter])

  return (
    <BasicTemplate>
      <Box className={classes.root} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
        <Box>
          <Typography variant='h4' className={classes.title}>Descubra novos restaurantes!</Typography>
        </Box>
        <Box width='70%' display='flex' justifyContent='center'>
          <Paper component="form" onSubmit={(e) => handleSubmit(e)} className={classes.rootSearchBar}>
            <InputBase
              className={classes.input}
              placeholder="Pesquisar restaurante"
              inputProps={{ 'aria-label': 'Pesquisar restaurante' }}
              onChange={(event => setSearchByRestaurant(event.target.value))}
            />
            <IconButton type="submit" className={classes.iconButton} aria-label="search">
              <Search />
            </IconButton>
          </Paper>
        </Box>
      </Box>
      {loading ? (
        <Box
          display='flex'
          height='80vh'
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress/>
        </Box>
      ) : (
        <>
          {categories.length >= 5 && (
            <Scrollbar categories={categories} />
          )}
          {restaurants.length > 0 ? (
            <RestaurantGrid restaurants={restaurants} count={count} page={page} changePage={changePage}/>
          ) : (
            <Box
              display='flex'
              width='100%'
              height='150px'
              justifyContent='center'
              alignItems='center'
            >
              <Typography variant='h6'>Nenhum restaurante encontrado</Typography>
            </Box>
          )}
        </>
      )}
    </BasicTemplate>
  )
}

export default RestaurantList
