import React from 'react'
import { List, ListItem, ListItemText, ListSubheader } from '@material-ui/core'

import history from 'services/history'
import { useUser } from 'hooks'
import useStyles from './styles'

function ListComponent ({ items }) {
  const { user } = useUser()
  const classes = useStyles()

  return (
    <>
      {items.filter((item) => { return item.only.includes(user.profile_id) }).map(({ title, options }, index) => (
        <List
          key={index}
          subheader={<ListSubheader className={classes.listSubHeader}>{title}</ListSubheader>}
        >
          {options.filter((option) => { return option.only.includes(user.profile_id) }).map(({ title, url }, index) => (
            <ListItem
              key={index}
              active="true"
              button
              onClick={() => { history.push(url) }}
            >
              <ListItemText primary={title} />
            </ListItem>
          ))}
        </List>
      ))}
    </>
  )
}

export default ListComponent
