import React from 'react'
import { Box, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'

import { getRestaurantStatus, CLOSED } from 'helpers/restaurantSchedules'

const useStyle = makeStyles({
  container: {
    position: 'fixed',
    bottom: '1em',
    left: '1em',
    zIndex: 1000
  }
})

export default function ClosedAlert ({ schedule }) {
  const classes = useStyle()

  const openingHours = getRestaurantStatus(schedule)

  if (!openingHours) return null

  return openingHours.status === CLOSED && (
    <Box mt={2} className={classes.container}>
      <Alert severity="error">Estamos fechados no momento</Alert>
    </Box>
  )
}
