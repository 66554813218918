import React from 'react'
import {
  CardMedia,
  CardContent,
  CardActionArea,
  Typography,
  Card,
  Box
} from '@material-ui/core'

import { getRestaurantStatus, OPENED } from 'helpers/restaurantSchedules'

import useStyles from './styles'

function ProductCard ({
  handleOpenDialog = () => {},
  product,
  restaurantId,
  attendance,
  showcase
}) {
  const classes = useStyles({ unavailable: product.inactive })

  const openingHours = getRestaurantStatus(attendance)

  const handleClickProduct = () => {
    if (product.inactive || showcase || !openingHours) return

    if (openingHours.status === OPENED) {
      handleOpenDialog(product.id)
    }
  }

  return (
    <Card className={classes.root} onClick={handleClickProduct}>
      <CardActionArea className={classes.actionArea}>
        <CardMedia
          className={classes.media}
          image={product.image ? product.image : '/static/images/cards/contemplative-reptile.jpg'}
          title="image-product"
        />
        <CardContent className={classes.cardContent}>
          <Box>
            <Typography gutterBottom variant="h6" component="h2">
              {product.name}
            </Typography>
            <Typography
              className={classes.descriptionTypography}
              variant="body1"
              color="textSecondary"
              align="justify"
            >
              {product.description}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent={product.inactive ? 'space-between' : 'flex-end'}
            mt={2}>
            {product.inactive && (
              <Typography color="primary">
                  INDISPONÍVEL
              </Typography>
            )}

            <Typography className={classes.priceTypography}>
                R$ {product.price.toFixed(2)}
            </Typography>
          </Box>

        </CardContent>
      </CardActionArea>

    </Card>
  )
}

export default ProductCard
