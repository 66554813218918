import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { format, parseISO } from 'date-fns'

import { requestStatusLabelHelper } from 'helpers'

/**
 * @param {String} created_at
 * @returns {String}
 */
function formatDate (createdAt) {
  return format(parseISO(createdAt), 'dd/MM/yyyy HH:mm')
}

const useStyles = makeStyles({
  container: {
    fontSize: 12,
    lineHeight: 20,
    marginBottom: 10
  }
})

export default function PrintRequestHeader ({ request }) {
  const classes = useStyles()

  return (
    <div className={classes.requestHeader}>
      <Typography>Pedido #{request.request_number || request.id}</Typography>
      <Typography>{requestStatusLabelHelper(request.status)}</Typography>
      <Typography>{`Data Pedido: ${formatDate(request.created_at)}`}</Typography>
    </div>
  )
}

PrintRequestHeader.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.number,
    request_number: PropTypes.number,
    status: PropTypes.string,
    created_at: PropTypes.string
  }).isRequired
}
