import React from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  Home,
  Login,
  P404,
  SendPassword,
  ChangePassword,
  RestaurantList,
  RestaurantDetails,
  Cart,
  UserRegister,
  UserRequestList,
  UserRequestShow
} from 'pages'

import AppRoutes from './AppRoutes'
import AdminRoutes from './AdminRoutes'
import GarconRoutes from './GarconRoutes'

import PrivateRoute from './PrivateRoute'
import { RestaurantProvider } from 'providers'

export default function Routes () {
  return (
    <Switch>
      <Route exact path="/site" component={Home}/>
      <Route exact path="/cart" component={Cart} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={UserRegister} />
      <Route exact path="/reset-password" component={SendPassword} />
      <Route exact path="/reset-password/:token" component={ChangePassword} />
      <Route exact path="/user-requests" component={UserRequestList} />
      <Route exact path="/user-requests/:id" component={UserRequestShow} />
      <Route exact path="/">
        <RestaurantProvider>
          <RestaurantList/>
        </RestaurantProvider>
      </Route>
      <Route exact path="/restaurants/:id" render={(props) => (
        <RestaurantProvider>
          <RestaurantDetails {...props}/>
        </RestaurantProvider>
      )} />
      <PrivateRoute isPermmitedBy={[1]} path="/admin" component={AdminRoutes} />
      <PrivateRoute isPermmitedBy={[2]} path="/app" component={AppRoutes} />
      <PrivateRoute isPermmitedBy={[4]} path="/garcon" component={GarconRoutes} />
      <Route component={P404} />
    </Switch>
  )
}
