import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import NavBar from 'components/Navbar'

import useStyles from './styles'

function SessionTemplate ({ children }) {
  const classes = useStyles()

  return (
    <>
      <NavBar />
      <Grid container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className={classes.root}
      >
        <Paper elevation={3} className={classes.paper} variant="outlined">
          {children}
        </Paper>
      </Grid>
    </>
  )
}

export default SessionTemplate
