import {
  isBefore,
  getDay,
  getDate,
  getMonth,
  getYear,
  isAfter,
  format
} from 'date-fns'

function parseScheduleDatetime (today) {
  const year = getYear(today)
  const month = getMonth(today)
  const day = getDate(today)

  return function (datetime) {
    const [hour, minute] = datetime.split(':')

    return new Date(year, month, day, Number(hour), Number(minute))
  }
}

export const CLOSED = 'Fechado'
export const OPENED = 'Aberto'

/**
 * Return restaurant status based on its schedule.
 * @param {String} schedule - Schedule object stringified
 */
export function getRestaurantStatus (schedule) {
  if (!schedule) return

  const now = new Date()

  const scheduleObj = JSON.parse(schedule)
  const openingHours = scheduleObj[getDay(now)]

  if (!openingHours) return

  const parseDatetime = parseScheduleDatetime(now)

  const openAt = parseDatetime(openingHours.abre)
  const closesAt = parseDatetime(openingHours.fecha)

  if (isBefore(openAt, now) && !isAfter(now, closesAt)) {
    return {
      status: OPENED,
      openAt: format(openAt, 'HH:mm'),
      closesAt: format(closesAt, 'HH:mm')
    }
  }
  return {
    status: CLOSED,
    openAt: format(openAt, 'HH:mm'),
    closesAt: format(closesAt, 'HH:mm')
  }
}
