import React, { useState, useEffect } from 'react'

import UserContext from 'contexts/userContext'

function UserProvider ({ children }) {
  const loadUserToken = () => {
    const userToken = localStorage.getItem('@menu-shop/token')
    if (userToken === 'null') return null

    return userToken
  }
  const loadLoggedUser = () => {
    return JSON.parse(localStorage.getItem('@menu-shop/user')) || {}
  }

  const [token, setToken] = useState(loadUserToken())
  const [user, setUser] = useState(loadLoggedUser())
  const [signed, setSigned] = useState(Boolean(loadUserToken()))

  const signIn = (user, token) => {
    setUser(user)
    setToken(token)
    setSigned(true)

    localStorage.setItem('@menu-shop/user', JSON.stringify(user))
    localStorage.setItem('@menu-shop/token', token)
  }

  const logout = () => {
    setUser(null)
    setToken(null)
    setSigned(false)

    localStorage.removeItem('@menu-shop/user')
    localStorage.removeItem('@menu-shop/token')
  }

  useEffect(() => {
    if (token) {
      setSigned(true)
    } else {
      setSigned(false)
    }
  }, [token])

  return (
    <UserContext.Provider value={{ token, setToken, user, setUser, signed, setSigned, signIn, logout }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserProvider
