import React, { useState } from 'react'

import TableContext from 'contexts/tableContext'

function TableProvider ({ children }) {
  const [table, setTable] = useState({})

  return (
    <TableContext.Provider value={{ table, setTable }}>
      {children}
    </TableContext.Provider>
  )
}

export default TableProvider
