import React, { useState } from 'react'

import {
  Box,
  IconButton,
  InputBase
} from '@material-ui/core'
import { Link } from 'react-router-dom'

import {
  Search as SearchIcon
} from '@material-ui/icons'

import useStyles from './styles'

const InputSearch = ({ url, ...rest }) => {
  const classes = useStyles()
  const [name, setName] = useState('')

  return (
    <Box className={classes.searchBox} mb={2} pl={2} {...rest}>
      <InputBase className={classes.searchInput} name="name" placeholder="Nome do produto" onChange={(event) => setName(event.target.value)} />
      <IconButton component={Link} to={`${url}?name=${name}${window.location.hash}`}>
        <SearchIcon />
      </IconButton>
    </Box>
  )
}

export default InputSearch
