import React from 'react'
import { Dialog, Box } from '@material-ui/core'

import useStyles from './styles'

import { useDialog } from 'hooks'

function DialogComponent ({ size = 'md', children, ...rest }) {
  const classes = useStyles()
  const { open, setOpen } = useDialog()

  function handleClose () {
    return setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={size}
      fullWidth
      {...rest}
    >
      <Box
        className={classes.dialog}
      >
        {children}
      </Box>
    </Dialog>
  )
}

export default DialogComponent
