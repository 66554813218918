import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button
} from '@material-ui/core'
import { Pagination } from '@material-ui/lab'

import { DropMenu } from 'components'
import { DialogRestaurant, Header } from './components'
import { useRestaurant, useDialog } from 'hooks'

import api from 'services/api'
import useStyles from './styles'

function Restaurant () {
  const { setRestaurant } = useRestaurant()
  const { handleOpen, setMode } = useDialog()

  const [restaurants, setRestaurants] = useState([])
  const [count, setCount] = useState(null)
  const [page, setPage] = useState(1)
  const [perPage] = useState(20)
  const classes = useStyles()

  const handleOpenDialog = (restaurant) => {
    setMode('edit')
    setRestaurant(restaurant)
    handleOpen()
  }

  const changePage = (event, value) => {
    setPage(value)
  }

  const handleActive = async (restaurant) => {
    try {
      await api.put(`/restaurants/${restaurant.id}`, {
        active: !restaurant.active
      })

      getRestaurants()
    } catch (error) {
      console.log(error)
    }
  }

  const getRestaurants = async () => {
    try {
      const restaurants = await api.get(`/restaurants?page=${page}&per_page=${perPage}`)

      console.log(restaurants.data)
      setRestaurants(restaurants.data.object.restaurants)
      const countPagination = Math.floor(restaurants.data.object.count / perPage)
      if (countPagination === 0) {
        setCount(1)
      } else {
        setCount(countPagination)
      }
    } catch (error) {
      console.log(error)
    }
  }
  function reload () {
    getRestaurants()
  }

  useEffect(() => {
    getRestaurants()

    // eslint-disable-next-line
  }, [page])

  return (
    <>
      <Header title="Restaurantes" />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="10%">#</TableCell>
              <TableCell width="60%">Nome</TableCell>
              <TableCell width="15%">Slug</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {restaurants.map((restaurant, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">{restaurant.id}</TableCell>
                <TableCell>{restaurant.name}</TableCell>
                <TableCell>{restaurant.slug}</TableCell>
                <TableCell align="right">
                  <DropMenu>
                    <List>
                      <ListItem>
                        <Button onClick={() => handleOpenDialog(restaurant)}>
                          EDITAR
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button onClick={() => handleActive(restaurant)}>
                          {restaurant.active ? 'DESATIVAR' : 'ATIVAR'}
                        </Button>
                      </ListItem>
                    </List>
                  </DropMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination className={classes.pagination} count={count} page={page} onChange={changePage} />

      <DialogRestaurant reload={reload} />
    </>
  )
}

export default Restaurant
