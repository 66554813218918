import React from 'react'

import SessionTemplate from 'templates/SessionTemplate'
import TitleLabel from 'components/TitleLabel'
import { Form } from './components'

const UserRegister = () => {
  return (
    <SessionTemplate>
      <TitleLabel title="Registrar" label="Registre na plataforma" />
      <Form />
    </SessionTemplate>
  )
}

export default UserRegister
