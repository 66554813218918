import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'

function Title ({ title }) {
  useEffect(() => {
    document.title = title
  }, [title])

  return <Typography variant="h4">{title}</Typography>
}

export default Title
