import React from 'react'
import { Router } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'

import Routes from 'routes'
import history from 'services/history'
import theme from 'config/theme'

import { AppProvider } from 'providers'

function App () {
  return (
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Router history={history}>
          <Routes />
        </Router>
      </AppProvider>
    </ThemeProvider>
  )
}

export default App
