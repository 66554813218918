import React from 'react'
import { Typography } from '@material-ui/core'

function HelperText ({ children }) {
  return (
    <Typography variant="body2">{children}</Typography>
  )
}

export default HelperText
