import React, { useState } from 'react'

import DialogContext from '../contexts/dialogContext'

function DialogProvider ({ children }) {
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState('create')

  function handleOpen () {
    return setOpen(true)
  }

  function handleClose () {
    return setOpen(false)
  }

  return (
    <DialogContext.Provider value={{
      open,
      setOpen,
      handleOpen,
      handleClose,
      mode,
      setMode
    }}>
      {children}
    </DialogContext.Provider>
  )
}

export default DialogProvider
