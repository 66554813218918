import React from 'react'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'

import useStyles from './styles'

function ProductCard ({
  handleOpenDialog = () => {},
  product
}) {
  const classes = useStyles()

  const handleClickProduct = () => {
    handleOpenDialog(product.id)
  }

  return (
    <ListItem button onClick={handleClickProduct}>
      <ListItemAvatar>
        <Avatar alt={product.name} src={product.image} />
      </ListItemAvatar>
      <ListItemText primary={product.name} secondary={product.description} />
      <Typography className={classes.priceTypography}>
        R$ {product.price.toFixed(2)}
      </Typography>
    </ListItem>
  )
}

export default ProductCard
