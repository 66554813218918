import React from 'react'

import { Box, Container, Grid, Typography, Button, Hidden } from '@material-ui/core'

import useStyles from './styles'

const Footer = ({ price, openDialogConfirmation }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Container component={Box} p={2}>
        <Grid container>
          <Grid item xs={6}>
            <Typography>Total:</Typography>
            <Typography className={classes.priceTypography}>R$ {price.toFixed(2)}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              <Hidden smDown>
                <Button variant="contained" size="large" onClick={openDialogConfirmation} className={classes.cartButton}>Fazer Pedido</Button>
              </Hidden>
              <Hidden mdUp>
                <Button variant="contained" onClick={openDialogConfirmation} className={classes.cartButton}>Fazer Pedido</Button>
              </Hidden>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Footer
