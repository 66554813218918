import React from 'react'
import { Paper, Box, Typography, Grid } from '@material-ui/core'
import { Star as StarIcon } from '@material-ui/icons'

import restaurantNotFoundImg from 'assets/images/logoMenuShop.svg'
import history from 'services/history'
import { getRestaurantStatus } from 'helpers/restaurantSchedules'

import useStyle from './styles'

const RestaurantContainer = ({ data }) => {
  const imageUrl = data.img || restaurantNotFoundImg
  const classes = useStyle()

  const redirect = (category) => {
    history.push(`/restaurants/${category}`)
  }

  const openingHours = getRestaurantStatus(data.schedule)

  return (
    <Paper className={classes.root} key={data.id} onClick={() => redirect(data.slug)}>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12} className={classes.ratingAlign}>
            <img src={data.logo ?? imageUrl} alt='logo-restaurant' className={classes.imgContainer} />
            <Box ml={3}>
              <Typography variant="body1" className={classes.titleName}>
                {data.name}
              </Typography>
              <Typography variant="body2">
                {data.category}
              </Typography>
            </Box>
            <Box display="flex" position="absolute" right={0} bottom={0}>
              {openingHours && (
                <Typography variant='body1' className={classes.situacao}>{openingHours.status}</Typography>
                )}
              {openingHours && openingHours.openAt && openingHours.closesAt && (
                <Typography variant='body1' className={classes.funcionamento}>Expediente: {openingHours.openAt} às {openingHours.closesAt}</Typography>
              )}
              <Typography variant='body1' className={classes.rating}>{data.rating}</Typography>
              <StarIcon className={classes.rating} />
              <Typography variant='body1' className={classes.ratingTotal}>({data.ratingTotal || '-'})</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

export default RestaurantContainer
