import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    overflowY: 'scroll',
    height: '90vh'
  }
})

export default function Scroll ({ children }) {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      {children}
    </div>
  )
}
