import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { TextField, Box } from '@material-ui/core'
import { useParams } from 'react-router'
import { yupResolver } from '@hookform/resolvers'

import history from 'services/history'
import api from 'services/api'
import schema from './schema'
import { useSnackbar, useLoading } from 'hooks'

import { Snackbar, LoadingButton } from 'components'

function Form () {
  const { token } = useParams()
  const { openSnackbar } = useSnackbar()
  const { setLoading } = useLoading()

  const { register, errors, control, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      password: '',
      passwordConfirmation: ''
    },
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const submitForm = async (data) => {
    setLoading(true)
    try {
      const response = await api.put(`/reset-password/${token}`, data)
      openSnackbar(response.data.message)
      history.push('/login')
    } catch (error) {
      openSnackbar(error.response.data.message, 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(submitForm)}>
        <Controller
          name="password"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              type="password"
              label="Senha"
              inputRef={register}
              fullWidth
              error={!!errors.password}
              helperText={errors && errors.password && errors.password.message}
            />
          }
          control={control}
        />

        <Controller
          name="passwordConfirmation"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Confirmação de senha"
              inputRef={register}
              type="password"
              fullWidth
              error={!!errors.passwordConfirmation}
              helperText={errors && errors.passwordConfirmation && errors.passwordConfirmation.message}
            />
          }
          control={control}
        />

        <Box my={1}>
          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            size={30}
          >
            Cadastrar
          </LoadingButton>
        </Box>
      </form>
      <Snackbar />
    </>
  )
}

export default Form
