/* eslint-disable space-before-function-paren */
import React from 'react'

import { Box, Typography } from '@material-ui/core'

import useStyle from './styles'

function Categories({ imgUrl, title, id }) {
  const classes = useStyle()

  return (
    <Box
      className={classes.root}
      key={id}
    >
      <Box className={classes.image} style={{ background: `url(${imgUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}/>
      <Box mt={2}>
        <Typography>
          {title}
        </Typography>
      </Box>
    </Box>
  )
}

export default Categories
