import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {

  },
  removeStyleLink: {
    textDecoration: 'none'
  }
})
