import yup from 'config/yup'

export default yup.object().shape({
  name: yup.string().required(),
  description: yup.string().required(),
  cnpj: yup.number().required(),
  category_id: yup.number().required(),
  userName: yup.string().required(),
  userEmail: yup.string().email().required(),
  userPassword: yup.string().required()
})
