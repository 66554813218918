import React, { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Typography,
  Chip,
  Box,
  Paper,
  Divider
} from '@material-ui/core'

import { PrintRequest } from './components'

import { TableGrid } from './components'
import { Snackbar } from 'components'
import api from 'services/api'
import {mapRequests} from './requestMapper'

function RequestsTables () {
const [requests, setRequests] = useState()
  const [select, setSelection] = useState({})
  const [descriptionRequests, setDescriptionRequests] = useState()
   const componentRef = useRef();

  const getRequests = async () => {
    try {
      var result = await api.get('/requests')

      for (var i = 0; i < result.data.length; i++) {

          if (result.data[i].request_type_id === 1) {
            result.data[i].request_type_name = "Local";
          } else if (result.data[i].request_type_id === 2) {
            result.data[i].request_type_name = "Entrega";
          } else if (result.data[i].request_type_id === 3) {
            result.data[i].request_type_name = "Retirada";
          }
      }

      const dataStatus = mapRequests(result.data)
      setRequests(dataStatus)
      updateSelection(dataStatus)
    } catch (err) {
      console.log(err)
    }
  }

  const updateSelection = (requests) => {
     
    const filteredRequest = requests.filter((request) => request.id === select.id)
    if (filteredRequest.length === 0) return

    setSelection(filteredRequest[0])
  } 

  const automaticLoadRequest = () => {
    setInterval(getRequests, 60000)

  }

  useEffect(() => {
    getRequests()
    automaticLoadRequest()
  })
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h4">
            Pedidos da Mesa
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper elevation={1} component={Box} p={1}>
            <Box
              p={1}
              display="flex"
            >
              <Typography variant="h6" component="h6">
                Pedidos Ativos
              </Typography>
              
            </Box>
            <TableGrid
              rows={requests}
              setSelection={setSelection}
              select={select}
              descriptionRequests={descriptionRequests}
              setDescriptionRequests={setDescriptionRequests}
            />
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          {select?.id && (
            <Paper elevation={1} component={Box} p={1}>
              <Box p={1} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  Pedido #{select.id}
                </Typography>
                <Chip label={select.status} />
              </Box>
              <Paper variant="outlined" component={Box} p={1} mb={2}>
                <Box p={1} display="flex" flexDirection="column">
                  <Typography variant="subtitle1" component="subtitle1">
                    <b>Usuário:</b> {descriptionRequests?.user?.name}
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    <b>Telefone:</b> {descriptionRequests?.user?.phone?.number}
                  </Typography>
                  <Typography>
                    {descriptionRequests?.table ? (
                      <>
                        <b>Mesa:</b> {descriptionRequests?.table?.number}
                      </>
                    ): (
                      <b>Para Entrega</b>
                    )}
                  </Typography>

                  <Divider/>
                  
                  {descriptionRequests?.request_products.map((request_product) => (
                    <div key={request_product.id}>
                      <Box py={1} display="flex" flexDirection="column">
                        <Typography variant="subtitle1" component="subtitle1">
                          <b>Produto:</b> {request_product?.amount}x - {request_product?.product?.name} - 
                          <b style={{ fontSize:'12px',float: 'right' }}> R$ {request_product?.current_price?.toFixed(2)}</b>
                        </Typography>

                        {request_product?.note && (  
                          <Typography variant="subtitle1" component="subtitle1"> 
                            <b>Anotações:</b> {request_product.note}
                          </Typography>
                        )}

                        {request_product?.product_options_request_products?.length > 0 && (
                          <Box pt={1} display="flex" flexDirection="column">
                            <Typography><b>Adicionais</b></Typography>
      
                            {request_product.product_options_request_products.map((product_options_request_product) => (
                              <Typography variant="subtitle1" component="subtitle1">
                                {product_options_request_product?.amount}x - {product_options_request_product?.product_option?.name} - <b>Valor Unit.: R$ {product_options_request_product?.current_price?.toFixed(2)}</b>
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>

                      <Divider />
                    </div>
                  ))}
                  
                  <Box pt={1}>
                    <Typography>
                      <b>Preço Total: R$ {descriptionRequests?.total_price?.toFixed(2)}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
                {/*
                  // todo: add print to garcon
                */}
                <div style={{ display:'none' }}>
                 <PrintRequest xselect={select}  xdescriptionRequests={descriptionRequests} ref={componentRef} />
                </div>
            </Paper>
          )}
        </Grid>
      </Grid>
      <Snackbar />
    </>
  )

}

export default RequestsTables
