import React, { useState } from 'react'

import UsersContext from 'contexts/usersContext'

function UsersProvider ({ children }) {
  const [users, setUsers] = useState({})

  return (
    <UsersContext.Provider value={{ users, setUsers }}>
      {children}
    </UsersContext.Provider>
  )
}

export default UsersProvider
