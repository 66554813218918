import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export default makeStyles((theme) => {
  const drawerWidth = 240

  return ({
    appBar: {
      backgroundColor: grey[900],
      color: '#FFF',
      display: 'flex',
      justifyContent: 'space-between',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 20
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: 0
    },
    toolbarMain: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    left_tollbar: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
    },
    links: {
      marginLeft: 88
    },
    link: {
      textDecoration: 'none',
      color: grey[200],
      fontFamily: 'Ubuntu, Roboto, sans-serif',
      fontSize: 16
    },
    image: {
      height: 50
    },
    button: {
      color: '#FFF'
    },
    margin_right_16: {
      marginRight: 16
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    },
    alignVertical: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      cursor: 'pointer'
    }
  })
})
