import React from 'react'

import styles from './styles'

function H1 (props) {
  const classes = styles(props)
  const { children, color = 'dark', ...rest } = props
  const fontColor = color === 'light' ? classes.colorLight : classes.colorDark

  return <h1 className={`${fontColor}`} {...rest}>{children}</h1>
}

export default H1
