import React, { useState, useEffect, useMemo } from 'react'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import SearchIcon from '@material-ui/icons/Search'

import api from 'services/api'

import Products from './Products'
import useStyles from './styles'
import { Button } from '@material-ui/core'

export default function AddProductsModal ({ isVisible, restaurantId, onDismiss, onClose }) {
  const classes = useStyles()
  const [products, setProducts] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (!isVisible) return
    if (products.length) return;

    (async () => {
      const params = {
        restaurant_id: restaurantId
      }
      const { data } = await api.get('/products', { params })

      setProducts(data.object.flatMap(productCategory => productCategory.products))
    })()
  }, [isVisible, products, restaurantId])

  const filteredProducts = useMemo(() => {
    if (search) {
      return products.filter(product => {
        // todo: filtrar por código
        return (
          product.name
            .toLowerCase()
            .includes(search.toLowerCase())
        )
      })
    }

    return products
  }, [search, products])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const handleDismiss = () => {
    onDismiss()
  }

  return (
    <Dialog
      open={isVisible}
      onClose={onClose}
    >
      <Box className={classes.dialog}>
        <Input
          id="search-products"
          className={classes.searchInput}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder='Buscar por nome ou código'
          value={search}
          onChange={handleSearch}
        />

        <Products products={filteredProducts} restaurantId={restaurantId} />

        <Box className={classes.buttons}>
          <Button
            type='button'
            variant="outlined"
            fullWidth
            onClick={handleDismiss}
          >
            Cancelar
          </Button>
        </Box>
      </Box>
    </Dialog>
  )
}
