import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { ProductCard, ProductDialog } from './components'

export default function Products ({ products, restaurantId }) {
  const [open, setOpen] = useState(false)
  const [productId, setProductId] = useState(0)

  const handleOpenDialog = (productId) => {
    setProductId(productId)
    return setOpen(true)
  }

  const handleCloseDialog = () => {
    setProductId({})
    return setOpen(false)
  }

  return (
    <>
      <Grid container spacing={4}>
        {products.map((product) => (
          <ProductCard
            key={String(product.id)}
            product={product}
            handleOpenDialog={handleOpenDialog}
          />
        ))}
      </Grid>

      <ProductDialog
        open={open}
        handleClose={handleCloseDialog}
        productId={productId}
        restaurantId={restaurantId}
      />
    </>
  )
}
