import React, { useState } from 'react'

import SnackbarContext from '../contexts/snackbarContext'

function SnackbarProvider ({ children }) {
  const [open, setOpen] = useState(null)
  const [message, setMessage] = useState()
  const [type, setType] = useState('success')

  const openSnackbar = (message = '', type = 'success') => {
    setMessage(message)
    setType(type)
    setOpen(true)
  }

  return (
    <SnackbarContext.Provider value={{ open, setOpen, message, setMessage, type, setType, openSnackbar }}>
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
