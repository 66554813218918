import React, { useState } from 'react'
import {
  Grid,
  Paper,
  Box,
  Button,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Switch
} from '@material-ui/core'
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'

import { useSnackbar } from 'hooks'
import api from 'services/api'
import useStyles from './styles'
import schema from './restaurantSchema'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

function RestaurantSettings ({ restaurant, setRestaurant, getRestaurants }) {
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [showcase, setShowcase] = useState(restaurant.showcase ? true : false)
  const atendimento = JSON.parse(restaurant.schedule)

  const { openSnackbar } = useSnackbar()
  const classes = useStyles()

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const upload = async (e) => {
    setLoadingUpload(true)
    try {
      const formData = new FormData()
      const file = e.target.files[0]
      formData.append('file', file)

      const { data } = await api.post(`/restaurants/${restaurant.id}/logo`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })

      openSnackbar('Logo atualizada com sucesso.')

      setRestaurant({ ...restaurant, logo: data.object.logo })
    } catch (err) {
      openSnackbar('Ocorreu um erro ao atualizar a logo, tente novamente em alguns instantes.', 'error')
    }
    setLoadingUpload(false)
  }

  const onSubmit = async ({ name, description}) => {
   
    const dias = document.querySelectorAll("input[name^=dia_]:checked")
    const open = document.querySelectorAll("input[name^=open_]")
    const close = document.querySelectorAll("input[name^=close_]")
    const horarios = []
    
    dias.forEach(function(obj){
        let d = obj.value
        let abre = open[d].value
        let fecha = close[d].value


        if(abre !== "" && fecha !== ""){
          horarios[d] = {"abre":abre,"fecha":fecha}
        }
    })

    let schedule = Object.assign({},horarios)
    schedule = JSON.stringify(schedule)

    try {
      await api.put(`/restaurants/${restaurant.id}`, {
        name,
        description,
        schedule,
        showcase
      })

      openSnackbar('Restaurante atualizado com sucesso.')
      getRestaurants()
    } catch (err) {
      openSnackbar('Ocorreu um erro ao atualizar o restaurante, tente novamente em alguns instantes.', 'error')
    }
  }

  const diasSemana = ["Dom","Seg","Ter","Qua","Qui","Sex","Sab"]

  const [isEnabled,setIsEnabled] = useState(false)

  const getValue = (e,val) => {
    if(val == true){
      //document.getElementById("showcase").value = 't'
      setShowcase(true)
    }else{
      //document.getElementById("showcase").value = 'f'
      setShowcase(false)
    }
  }

  return (
    <Box mt={3}>
      <Grid container spacing={2}>
        <Grid item md={5} xs={12}>
          <Box component={Paper} p={3}>
            {restaurant.logo &&
              <Box display="flex" justifyContent="center" mb={2}>
                <img src={restaurant.logo} className={classes.image} alt="logo restaurante" />
              </Box>
            }
            <Button variant="contained" component="label" disabled={loadingUpload} fullWidth startIcon={<CloudUploadIcon />}>
              {loadingUpload ? 'Enviando' : 'Upload logo'}
              <input type="file" accept="image/png, image/jpeg" hidden onChange={upload}/>
            </Button>
          </Box>
        </Grid>
        <Grid item md={7} xs={12}>
          <Box component={Paper} p={3}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="name"
                as={
                  <TextField
                    variant="outlined"
                    margin="normal"
                    label="Nome"
                    inputRef={register}
                    fullWidth
                    error={!!errors.name}
                    helperText={errors.name && errors.name.message}
                  />
                }
                defaultValue={restaurant.name || ''}
                control={control}
              />
              <Controller
                name="description"
                as={
                  <TextField
                    variant="outlined"
                    margin="normal"
                    label="Descrição"
                    inputRef={register}
                    fullWidth
                    multiline
                    rows={3}
                    error={!!errors.description}
                    helperText={errors.description && errors.description.message}
                  />
                }
                defaultValue={restaurant.description || ''}
                control={control}
              />

              <Switch
                onChange={getValue}
                defaultChecked={showcase}
              />
              <label>Vitrine</label>
              
                <div style={{display:'block',width:'100%',marginBottom:'20px'}}>
                  <div style={{float:'left',marginLeft:isMobile ? '14px' : '10px', width: isMobile ? '20%' : '17%'}}>&nbsp;</div>
                  <div style={{float:'left',marginLeft:'10px', width: isMobile ? '33%' : '25%'}}>Abre</div>
                  <div style={{float:'left',marginLeft:'10px', width: isMobile ? '33%' : '50%'}}>Fecha</div>
                </div>
                
                {
                diasSemana.map((item, index) => {

                let hora = ""

                if(atendimento !== null && atendimento[index] !== null && atendimento[index] !== undefined){
                  hora = atendimento[index]
                }
                
                return(
                  <Box display="flex" justifyContent="flex-begin">

                  <FormGroup>
                      
                      <div style={{display:'inline',marginLeft:'10px', width:'100%'}}>

                        <FormControlLabel control={<Checkbox defaultChecked={hora.abre} />} style={{fontSize: '12px', width: isMobile ? '20%' :'22%',marginTop:'4%'}} label={item} name={"dia_"+index} value={index}  />

                        <FormControl  style={{marginTop:'1px',marginLeft:'10px', width: isMobile ? '33%' :'120px'}} className={classes.selectInput} variant="outlined">
                          <Controller
                            name={"open_" + index}
                            as={
                              <TextField
                                variant="outlined"
                                margin="normal"
                                type="time"
                                size="small"
                                label=""
                                inputRef={register}
                                id={"open_" + index}
                              />
                            }
                            defaultValue={hora.abre || ''}
                            control={control}
                          />
                        </FormControl>

                        <FormControl  style={{marginTop:'1px',marginLeft:'10px', width: isMobile ? '33%' : '120px'}} className={classes.selectInput} variant="outlined">
                            <Controller
                              name={"close_" + index}
                              as={
                                <TextField
                                  variant="outlined"
                                  margin="normal"
                                  type="time"
                                  size="small"
                                  label=""
                                  inputRef={register}
                                  id={"close_" + index}
                                />
                              }
                              defaultValue={hora.fecha || ''}
                              control={control}
                            />
                        </FormControl>
                      </div>

                  </FormGroup> 
                  </Box>
                  )              
                })
              }
            
              <Box mt={3} display="flex" justifyContent="flex-end">
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  className={classes.submitButton}
                >
                  Enviar
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default RestaurantSettings
