import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    margin: '0.25em 0'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  option: {
    margin: '0.2em 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})

function showProductOption (productOption) {
  return `${productOption.amount}x - ${productOption.product_option.name}`
}

export default function PrintRequestProductList ({ requestProducts }) {
  const classes = useStyles()

  return requestProducts.map(requestProduct => (
    <Box key={requestProduct.id} className={classes.container}>
      <Box className={classes.item}>
        <Typography>
          {`${requestProduct.amount}x - ${requestProduct.product.name}`}
        </Typography>
        <Typography>
          <b>{`R$ ${requestProduct.current_price.toFixed(2)}`}</b>
        </Typography>
      </Box>

      {!!requestProduct.note && (
        <Typography>
          <em>
            <b>Anotações:</b> {requestProduct.note}
          </em>
        </Typography>
      )}

      {!!requestProduct.product_options_request_products.length && (
        <>
          <Typography>
            <b>Adicionais</b>
          </Typography>

          {requestProduct.product_options_request_products.map(productOption => (
            <Typography key={productOption.id} className={classes.option}>
              <em>
                {showProductOption(productOption)}
              </em>

              <b>Valor Unit.: R$ {productOption.product_option.price.toFixed(2)}</b>
            </Typography>
          ))}
        </>
      )}

      <Divider />
    </Box>
  ))
}
