import React, { useState } from 'react'

import { Container, Box } from '@material-ui/core'

import useStyles from './styles'

import Slider from 'react-slick'

import { Categories } from './category'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import { useRestaurant } from 'hooks'

import categoryNotFoundImg from 'assets/images/logoMenuShop.svg'

const ScrollBar = ({ categories }) => {
  const classes = useStyles()
  const { setCategoryToFilter } = useRestaurant()

  const resizeSlider = (width) => {
    if (width < 400) {
      return 1
    }
    if (width <= 550) {
      return 2
    }
    if (width <= 800) {
      return 3
    }
    if (width <= 1000) {
      return 4
    }
    if (width >= 1000) {
      return 5
    }
  }

  const [toShow, setToShow] = useState(resizeSlider(window.innerWidth))

  function sleep (ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  window.addEventListener('resize', async function () {
    await sleep(10)
    setToShow(resizeSlider(window.innerWidth))
  })

  function NextArrow (props) {
    const { className, style, onClick } = props

    return (
      <ChevronRight className={className}
        style={{ ...style, display: 'block', color: '#212121' }}
        onClick={onClick}
      />
    )
  }

  function PrevArrow (props) {
    const { className, style, onClick } = props

    return (
      <ChevronLeft
        className={className}
        style={{ ...style, display: 'block', color: '#212121' }}
        onClick={onClick}
      />
    )
  }

  var settings = {
    infinite: true,
    speed: 300,
    slidesToShow: toShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow className={classes.arrow} />,
    prevArrow: <PrevArrow className={classes.arrow} />
  }

  return (
    <Box my={6} mx={2}>
      <Container>
        <Slider {...settings}>
          {categories.map((category, index) => (
            <Box key={`category-${index}`} onClick={() => setCategoryToFilter(category.id)}>
              <Categories title={category.name} imgUrl={category.image ?? categoryNotFoundImg} id={category.id} />
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  )
}

export default ScrollBar
