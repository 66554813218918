import { unstable_createMuiStrictModeTheme as createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#D10119'
    },
    secondary: {
      main: '#335DFD'
    },
    typography: {
      label: {
        textTransform: 'capitalize'
      }
    }
  },
  overrides: {
    MuiSwitch: {
      colorSecondary: {
        '&$checked': {
          color: '#03a600'
        }
      },
      track: {
        '$checked$checked + &': {
          opacity: 0.7,
          backgroundColor: '#03a600'
        }
      }
    }
  }
})

export default responsiveFontSizes(theme)
