import React, { useState, useEffect, useMemo } from 'react'
import withWidth from '@material-ui/core/withWidth'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import { useLocation } from 'react-router-dom'

import { ButtonCount, Snackbar } from 'components'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'hooks'
import api from 'services/api'
import { getQueryByName } from 'helpers/query'

import useStyles from './styles'
import { log } from 'services/logger'

const ProductDialog = ({
  open,
  handleClose = () => {},
  productId,
  restaurantId,
  width
}) => {
  const [product, setProduct] = useState({})
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(1)
  const [selectedOptions, setSelectedOptions] = useState([])
  const { openSnackbar } = useSnackbar()
  const classes = useStyles()
  const { search } = useLocation()
  const tableId = useMemo(() => getQueryByName(search, 'table'), [search])

  const getProduct = async () => {
    setLoading(true)
    try {
      const product = await api.get(`/products/${productId}`)
      const productOption = await api.get(`/products/${productId}/options`)

      setProduct({ ...product.data.object, options: productOption.data.object })
    } catch (err) {
      openSnackbar('Não foi possível buscar o produto.', 'error')
    }
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      getProduct()
    }

  // eslint-disable-next-line
  }, [open])

  const { register, control, handleSubmit } = useForm()

  const onSubmit = async ({ note }) => {
    try {
      await api.post('requests/products', {
        restaurant_id: restaurantId,
        request_type_id: 1,
        table_id: tableId,
        request_products: [{
          product_id: product.id,
          amount: count,
          note: note,
          product_options_request_products: selectedOptions.map(selectedProduct => ({
            product_option_id: selectedProduct.id,
            request_product_id: product.id,
            amount: selectedProduct.price
          }))
        }]
      })

      await log('Produto adicionado')
      handleClose()
      openSnackbar('Produto adicionado com sucesso.', 'success')
    } catch (e) {
      await log('ProductDialog > onSubmit', e)
      openSnackbar('Não foi possível adicionar o produto.', 'error')
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        fullScreen={width === 'xs'}
        className={classes.dialog}
      >
        {loading ? (
          <Box
            display='flex'
            height='70vh'
            width='100%'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress/>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              className={classes.media}
              style={{ backgroundImage: `url(${product.image})` }}
            />
            <Box p={2}>
              <Typography gutterBottom variant="h5">
                {product.name}
              </Typography>
              <Typography gutterBottom variant="body1">
                {product.description}
              </Typography>

              <Box mb={2}>
                <ButtonCount count={count} setCount={setCount} />
              </Box>

              <Typography gutterBottom variant="caption">
                Observação
              </Typography>

              <Controller
                name="note"
                as={
                  <TextField
                    placeholder="Por exemplo: Tirar Cebola ..."
                    name="note"
                    variant="outlined"
                    margin="none"
                    inputRef={register}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={6}
                  />
                }
                defaultValue=""
                control={control}
              />

              {product.options.map((option, optionIndex) => (
                <>
                  {option?.category_product_option?.product_options?.length > 0 && (
                    <Box mt={2} key={option.id}>
                      <Box
                        className={classes.optionsHeader}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          <Typography variant="body1" className={classes.optionsHeaderText}>
                            {option.category_product_option.name}
                          </Typography>
                          {option.category_product_option.input !== 'checkbox' &&
                            <Typography variant="caption">
                              Selecione apenas 1
                            </Typography>
                          }
                        </Box>
                        {option.category_product_option.required && (
                          <Chip
                            size="small"
                            label="Obrigatório"
                            className={classes.optionsChip}
                          />
                        )}
                      </Box>
                      <Table>
                        {option?.category_product_option?.product_options?.map((productOption, productOptionIndex) => (
                          <TableRow key={productOption.id} role="checkbox">
                            <TableCell>{productOption.name}</TableCell>
                            <TableCell>R$ {productOption.price.toFixed(2)}</TableCell>
                            <TableCell align="right">
                              <Controller
                                name={`options[${optionIndex}].productOptions[${productOptionIndex}]`}
                                render={({ onChange, value, ...rest }) => {
                                  const handleChange = ({ target }) => {
                                    onChange(target.checked)

                                    if (target.checked) {
                                      setSelectedOptions(prevState => [...prevState, productOption])
                                      return
                                    }

                                    setSelectedOptions(prevState => {
                                      return prevState.filter(selectedOption => selectedOption.id !== productOption.id)
                                    })
                                  }

                                  return (
                                    <Checkbox
                                      {...rest}
                                      onChange={handleChange}
                                      checked={value}
                                    />
                                  )
                                }}
                                defaultValue={false}
                                control={control}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Box>
                  )}
                </>
              ))}

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" fullWidth variant="outlined" onClick={handleClose}>Voltar</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button type="submit" color="primary" fullWidth variant="contained">Adicionar</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        )}
      </Dialog>

      <Snackbar />
    </>
  )
}

export default withWidth()(ProductDialog)
