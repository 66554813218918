import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button
} from '@material-ui/core'

import { DropMenu } from 'components'
import { Header, DialogUser } from './components'

import { useDialog, useUser, useSnackbar } from 'hooks'

import api from 'services/api'

function Users () {
  const { setMode, handleOpen } = useDialog()
  const { openSnackbar } = useSnackbar()
  const { user } = useUser()

  const [userss, setUserss] = useState([])
  const [userEdit, setUserEdit] = useState([])
  const [profiles, setProfiles] = useState([])

  function handleOpenDialog (dadosUser) {
    setMode('edit')
    setUserEdit(dadosUser)
    handleOpen()

  }

  async function getUsers() {
    const response = await api.get(`/users2?restaurant_id=${user.restaurant_id}`)
    setUserss(response.data)
  }

  async function getProfiles() {
    const response = await api.get(`/profiles`)
    setProfiles(response.data.object)
  }

  async function handleDelete (categoryId) {
    try {
      const response = await api.delete(`/categories/${categoryId}`)
      openSnackbar(response.data.message)
      reload()
    } catch (error) {
      openSnackbar(error.response.data.message || error.response.data.error, 'error')
    }
  }

  function reload () {
    getUsers()
    getProfiles()
  }

  const trataPerfil = (perfil) => {
    if (perfil === "garcon") {
      return 'Garçon'
    } else if (perfil === "restaurant_responsible") {
      return 'Administrador'
    } 
  }

  useEffect(() => {
    getUsers()
    getProfiles()
 }, [])

  return (
    <>
      <Header title="Listagem de Usuários" />
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="5%">ID</TableCell>
              <TableCell width="20%">Nome</TableCell>
              <TableCell width="20%">E-mail</TableCell>
              <TableCell width="20%">Perfil</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userss.map((rows, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{rows.id}</TableCell>
                  <TableCell>{rows.name}</TableCell>
                  <TableCell>{rows.email}</TableCell>
                  <TableCell>{trataPerfil(rows.profile.name)}</TableCell>
                  <TableCell align="right">
                    <DropMenu>
                      <List>
                        <ListItem>
                          <Button onClick={() => handleOpenDialog(rows)}>
                            EDITAR
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button onClick={() => handleDelete(rows.id)}>
                            DELETAR
                          </Button>
                        </ListItem>
                      </List>
                    </DropMenu>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
       <DialogUser restaurant_id={user.restaurant_id} getUsers={getUsers} userss={userEdit}  setUserEdit={setUserEdit} profiles={profiles} reload={reload} />
    </>
  )
}

export default Users
