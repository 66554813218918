import React from 'react'

import { DataGrid } from '@material-ui/data-grid'

import api from 'services/api'

function TableGrid ({ select, setSelection, descriptionRequests, setDescriptionRequests, rows }) {
  const columns = [
    { field: 'request_number', headerName: 'Nº', width: 70 },
    { field: 'status', headerName: 'Status', width: 170 },
    { field: 'total_price', headerName: 'Preço', width: 170 },
    { field: 'request_type_name', headerName: 'Tipo', width: 170 },
    { field: 'created_at', headerName: 'Data Pedido', width: 170 }
  ]
  const onSelectionChanged = (selections) => {
    for (var i = 0; i < rows.length; i++) {
        if (rows[i].id === selections[0]) {
            
           if (select !== selections) {
              setSelection(rows[i])
              getDescription(selections[0])
            }

        }
    }

  }   

  const getDescription = async (id) => {

    try {

      const requestsProductsDescription = await api.get(`/requests/${id}`)
      setDescriptionRequests(requestsProductsDescription.data.object)

    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      {rows && <DataGrid
        rows={rows}
        columns={columns}
        style={{cursor:'pointer'}}
        NoRowsOverlay
        pageSize={5}
        onSelectionModelChange={onSelectionChanged}
      />}
    </div>
  )
}

export default TableGrid
