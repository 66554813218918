import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
  submitButton: {
    marginLeft: theme.spacing(2)
  },
  buttons: {
    marginTop: theme.spacing(3)
  },
  selectInput: {
    marginTop: theme.spacing(2)
  },
  InputLabel: {
    marginTop: -theme.spacing(1)
  }
}))
