import React from 'react'

import styles from './styles'

function Span (props) {
  const classes = styles(props)
  const { children, color = 'dark', ...rest } = props
  const fontColor = color === 'light' ? classes.colorLight : classes.colorDark

  return <span className={`${fontColor}`} {...rest}>{children}</span>
}

export default Span
