import React, { useState } from 'react'
import {
  Typography,
  Divider,
  TextField,
  Button,
  Box,
  Grid
} from '@material-ui/core'
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { Dialog, Snackbar, DialogImage } from 'components'
import schema from './schema'
import useStyles from './styles'
import api from 'services/api'

import { useDialog, useSnackbar, useCategory } from 'hooks'

function DialogCategory ({ reload }) {
  const [openImage, setOpenImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)

  const classes = useStyles()
  const { handleClose, mode } = useDialog()
  const { openSnackbar } = useSnackbar()
  const { category } = useCategory()

  const hasImage = !!category.image

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data) => {
    try {
      if (mode === 'create') {
        await api.post('/categories', data)
        handleClose()
        openSnackbar('Categoria criada com sucesso')
      }
      if (mode === 'edit') {
        await api.put(`/categories/${category.id}`, data)
        handleClose()
        openSnackbar('Categoria atualizada com sucesso')
      }
      reload()
    } catch (error) {
      openSnackbar('Ocorreu um erro', 'error')
    }
  }

  const upload = async (e) => {
    setLoadingUpload(true)
    try {
      const formData = new FormData()
      const file = e.target.files[0]
      formData.append('file', file)

      await api.post(`/categories/${category.id}/image`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })

      handleClose()
      reload()
      openSnackbar('Imagem atualizada com sucesso.')
    } catch (err) {
      console.log(err)

      openSnackbar('Ocorreu um erro ao atualizar a imagem, tente novamente em alguns instantes.', 'error')
    }
    setLoadingUpload(false)
  }

  return (
    <>
      <Dialog maxWidth="sm">
        <Typography variant="h5">
          Cadastro de categorias
        </Typography>
        <Divider />
        {mode === 'edit' && (
          <Box my={2}>
            <Grid container spacing={2}>
              {hasImage &&
                <Grid item xs={6}>
                  <Button variant="outlined" fullWidth onClick={() => setOpenImage(true)}>Ver Imagem</Button>
                </Grid>
              }

              <Grid item xs={hasImage ? 6 : 12}>
                <Button variant="contained" component="label" disabled={loadingUpload} fullWidth startIcon={<CloudUploadIcon />}>
                  {loadingUpload ? 'Enviando' : 'Upload imagem'}
                  <input type="file" accept="image/png, image/jpeg" hidden onChange={upload} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Nome"
                inputRef={register}
                fullWidth
                defaultValue={
                  category.name || ''
                }
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            }
            control={control}
          />
          <Controller
            name="description"
            as={
              <TextField
                variant="outlined"
                multiline
                rows={3}
                defaultValue={
                  category.description || ''
                }
                margin="normal"
                label="Descrição"
                inputRef={register}
                fullWidth
                error={!!errors.description}
                helperText={
                  errors.description &&
                  errors.description.message
                }
              />
            }
            control={control}
          />

          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={handleClose}>Voltar</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              className={classes.submitButton}
            >
              Enviar
            </Button>
          </Box>
        </form>
      </Dialog>

      <DialogImage
        open={openImage}
        handleClose={() => setOpenImage(false)}
        imageUrl={category.image}
      />

      <Snackbar />
    </>
  )
}

export default DialogCategory
