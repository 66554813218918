import { makeStyles } from '@material-ui/core/styles'
import theme from 'config/theme'
import { grey } from '@material-ui/core/colors'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'

const flexAllCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

export default makeStyles({
  margin_right_16: {
    marginRight: 16
  },
  first_section: {
    backgroundColor: theme.palette.primary.main
  },
  title_first_section: {
    marginBottom: isMobileOnly && '24px',
    color: 'white'
  },
  subtitle_first_section: {
    marginTop: '16px',
    textAlign: 'justify',
    color: 'white',
    fontSize: '16px',
    lineHeight: '20px'
  },
  mobile_first_section: {
    padding: '24px 0px 24px 0px',
    marginBottom: isMobileOnly && '0px'
  },
  container: flexAllCenter,
  padding_top_60: {
    paddingTop: '60px'
  },
  padding_bottom_32: {
    paddingBottom: '32px'
  },
  menu_shop_section: flexAllCenter,
  image_grid_section: {
    marginBottom: isTablet ? '-32px' : '-60px',
    zIndex: 2,
    display: isMobileOnly && 'none'
  },
  second_section: {
  },
  carousel_section: {
    ...flexAllCenter
  },
  third_section: {
  },
  ours_text_div: {
    ...flexAllCenter,
    width: 'fit-content',
    height: '40px',
    marginBottom: isMobile ? '16px' : '6px'
  },
  service_text_span: {
    fontSize: 36,
    color: grey[900],
    fontWeight: 700,
    fontFamily: 'Ubuntu, Roboto, sans-serif',
    marginBottom: '16px'
  },
  fourth_section: {
    marginTop: isMobile ? '36px' : '64px'
  },
  grid_service: {
    padding: '16px'
  },
  div_service: {
    backgroundColor: '#FFF',
    padding: '24px',
    borderRadius: '10px',
    border: '1px solid ' + grey[300]
  },
  back_box_icons: {
    ...flexAllCenter,
    height: '50px',
    width: '50px',
    borderRadius: '0px 6px 6px 6px',
    color: '#FFF',
    marginBottom: '40px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)'
  },
  description_card_service_div: {
    marginTop: '16px',
    marginBottom: '16px'
  },
  description_card_service_span: {
    fontWeight: 'regular',
    color: grey[700],
    fontSize: 16
  },
  title_card_service: {
    fontWeight: 'bold',
    fontSize: 22,
    color: grey[800]
  },
  grid_service_section: {
    flexDirection: 'column',
    marginBottom: isMobile && '48px',
    ...flexAllCenter
  },
  text_service_card_service: {
    ...flexAllCenter,
    padding: '6px 0px 24px 0px',
    fontSize: 16,
    color: grey[700]
  },
  clip_path_bottom: {
    marginTop: '64px'
  },
  footer_links_container: {
    backgroundColor: '#D10119',
    width: '100%',
    height: isMobile ? '140px' : '6vh'
  },
  left_tollbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  link: {
    textDecoration: 'none',
    color: '#EEE',
    fontFamily: 'Ubuntu, Roboto, sans-serif',
    fontSize: 16
  },
  link_social: {
    marginRight: '8px'
  },
  links: {
    /* marginLeft: isMobile ? 12 : 88 */
  },
  footer: {
    backgroundColor: grey[900],
    width: '100%'
  },
  footer_medias_container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px'
  },
  logo_bottom: {
    height: '40px',
    marginBottom: '24px'
  },
  max_width_image_carrousel: {
    maxWidth: '500px'
  },
  align_image_carrousel_center: {
    textAlign: 'center'
  },
  mobile_align: {
    marginTop: '-40px'
  },
  text_style_services: {
    color: '#414141',
    fontWeight: 600
  },
  devicesSVG: {
    width: '100%',
    height: '100%'
  }
})
