import React, { useEffect, useState } from 'react'
import {
  Dialog,
  Typography,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  FormHelperText,
  CircularProgress
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import { Snackbar } from 'components'

import useStyles from './styles'
import api from 'services/api'
import schema from './schema'

import { useSnackbar } from 'hooks'

function DialogCategoryProductOptionProduct ({ open, mode, handleClose, product, productOption = {}, reload }) {
  const { openSnackbar } = useSnackbar()

  const [categoryProductOptions, setCategoryProductOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const classes = useStyles()

  const { handleSubmit, errors, control } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    validationSchema: schema
  })

  const getCategoryProductOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get('/category-product-option')

      setCategoryProductOptions(response.data.object)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getCategoryProductOptions()
  }, [])

  const onSubmit = async (data) => {
    try {
      await api.post('/category-product-option-product', {
        product_id: product.id,
        category_product_option_id: data.option_id
      })
      openSnackbar('Opcional criado com sucesso')
      handleClose()

      reload()
    } catch (error) {
      openSnackbar('Ocorreu um erro', 'error')
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="sm"
      >
        <Box className={classes.dialog}>
          <Typography variant="h5">Categoria de Opcionais</Typography>
          <Divider />
          {loading ? (
            <Box
              width="100%"
              alignItems="center"
              justifyContent="center"
              display="flex"
              p={5}
            >
              <CircularProgress />
            </Box>
          ) : (
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl fullWidth className={classes.selectInput} variant="outlined">
                <InputLabel id="optionalIdSelectLabel">Opcional</InputLabel>
                <Controller
                  as={
                    <Select
                      label="Opcional"
                      labelId="optionalIdSelectLabel"
                      id="optionalIdLabel"
                      variant="outlined"
                      disabled={mode === 'show'}
                    >
                      {categoryProductOptions.map(categoryProductOption => (
                        <MenuItem key={categoryProductOption.id} value={categoryProductOption.id}>
                          {categoryProductOption.name}
                        </MenuItem>
                      ))}
                    </Select>
                  }
                  name="option_id"
                  control={control}
                  defaultValue='vanilla'
                  error={errors && errors.option_id}
                />
                <FormHelperText>{errors.option_id && errors.option_id.message}</FormHelperText>
              </FormControl>

              <Box className={classes.buttons}>
                <Button variant="outlined" onClick={handleClose}>Voltar</Button>
                {mode !== 'show' && <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  className={classes.submitButton}
                >
                  Enviar
                </Button>}
              </Box>
            </form>
          )}
        </Box>
      </Dialog>

      <Snackbar />
    </>
  )
}

export default DialogCategoryProductOptionProduct
