import React from 'react'
import { Grid, Icon } from '@material-ui/core'
import Span from 'components/Typography/Span'
import H1 from 'components/Typography/H1'

const CardService = ({ CardIcon, classes, backgroundIconColor, title, description }) => (
  <Grid item xs={12} sm={6} md={6} className={classes.grid_service}>
    <div className={classes.div_service}>
      <div className={classes.back_box_icons} style={{ backgroundColor: backgroundIconColor }}>
        <Icon>
          <CardIcon color="#fff" />
        </Icon>
      </div>
      <H1 className={classes.title_card_service}>{title}</H1>
      <div className={classes.description_card_service_div}>
        <Span className={classes.description_card_service_span}>{description}</Span>
      </div>
    </div>
  </Grid>
)

export default CardService
