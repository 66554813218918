import React from 'react'

import SessionTemplate from 'templates/SessionTemplate'
import { TitleLabel, HelperText } from 'components'
import { Form } from './components'

const Change = () => {
  return (
    <SessionTemplate>
      <TitleLabel title="Nova senha" label="Cadastre sua" />
      <HelperText>
        Digite sua nova senha
      </HelperText>
      <Form />
    </SessionTemplate>
  )
}

export default Change
