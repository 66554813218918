import React, { useState, useEffect } from 'react'

import {
  CircularProgress,
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  List,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Hidden
} from '@material-ui/core'
import {
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons'

import { BasicTemplate } from 'templates'
import { HeadSession, GridProducts, InputSearch, ClosedAlert } from './components'
import { ListItemLink } from 'components'
import { useQuery } from 'hooks'

import api from 'services/api'

const Details = (props) => {
  const [loading, setLoading] = useState(true)
  const [response, setResponse] = useState([])
  const [restaurant, setRestaurant] = useState([])
  const restaurantId = props.match.params.id
  const query = useQuery()

  const getProducts = async () => {
    let queryURL = ''
    const nameFilter = query.get('name')
    if (nameFilter) { queryURL = `/products/?slug=true&restaurant_id=${restaurantId}&name=${nameFilter}` } else { queryURL = `/products/?slug=true&restaurant_id=${restaurantId}` }
    const response = await api.get(queryURL)
    const restaurantData = await api.get(`/restaurants?slug=true&id=${restaurantId}`)
    setResponse(response.data.object)
    setRestaurant(restaurantData.data.object.restaurants[0])
    setLoading(false)
  }

  useEffect(() => {
    getProducts()

    // eslint-disable-next-line
  }, [loading, query.get('name')])

  return (
    <BasicTemplate>
      {loading ? (
        <Box
          display='flex'
          height='80vh'
          width='100%'
          justifyContent='center'
          alignItems='center'
        >
          <CircularProgress/>
        </Box>
      ) : (
        <Box minWidth='100%' minHeight='80vh'>
          <ClosedAlert schedule={restaurant.schedule} />
          <HeadSession restaurant={restaurant} />
          <Container>
            <Grid container spacing={4}>
              <Grid item md={3} xs={12}>
                <Box mt={4}>
                  <Hidden mdUp>
                    <Accordion mt={1}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Box fontWeight="700">Filtros</Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12}>
                            <InputSearch url={window.location.pathname} width="100%" />
                          </Grid>
                          {response.length > 0 && (
                            <Box>
                              <Box fontWeight="700" mb={1}>Categorias</Box>
                              <List>
                                {response.map((category) => (
                                  <ListItemLink key={category.id} href={`${window.location.pathname}${window.location.search}#${category.id}`}>
                                    {category.name}
                                  </ListItemLink>
                                ))}
                              </List>
                            </Box>
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Hidden>
                  <Hidden smDown>
                    <Box component={Paper} p={2}>
                      <Box fontWeight="700" fontSize="h4.fontSize" mb={2}>Filtros</Box>

                      <InputSearch url={window.location.pathname} />

                      {response.length > 0 && (
                        <>
                          <Box fontWeight="700" mb={1}>Categorias</Box>
                          <List>
                            {response.map((category) => (
                              <ListItemLink key={category.id} href={`${window.location.pathname}${window.location.search}#${category.id}`}>
                                {category.name}
                              </ListItemLink>
                            ))}
                          </List>
                        </>
                      )}
                    </Box>
                  </Hidden>
                </Box>
              </Grid>
              {response.length > 0 ? (
                <>
                  <Grid item md={9} xs={12}>
                    {response.map((data) => (
                      <Box my={4} key={data.id} id={data.id}>
                        <Grid container>
                          <Box fontWeight="700" fontSize="h4.fontSize" mb={3}>
                            {data.name}
                          </Box>
                          <GridProducts products={data.products} restaurantId={restaurantId} />
                        </Grid>
                      </Box>
                    ))}
                  </Grid>
                </>
              ) : (
                <Grid item md={9} sm={12}>
                  <Box
                    display='flex'
                    width='100%'
                    height='50vh'
                    justifyContent='center'
                    alignItems='center'
                  >
                    <Typography variant='h6'>Nenhum produto encontrado</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Container>
        </Box>
      )}
    </BasicTemplate>
  )
}

export default Details
