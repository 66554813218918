import React, { useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import { FiShoppingBag } from 'react-icons/fi'
import { Box, Fab } from '@material-ui/core'

import { Footer } from 'components'
import NavBar from 'components/Navbar'
import useStyles from './styles'
import clsx from 'clsx'
import history from 'services/history'

function BasicTemplate ({ children, footer = true, className = {}, ...rest }) {
  const classes = useStyles()
  const location = useLocation()
  useEffect(() => {

   });

  return (
    <Box className={classes.root} minHeight="100vh">
      <NavBar />
      <Box className={clsx(classes.content, className)} {...rest}>
        {children}
      </Box>
      {footer && <Footer mt={4} />}
      {location.pathname !== '/cart' && (
        <Fab color="primary" className={classes.fab} onClick={() => history.push('/cart')}>
        <FiShoppingBag size={24} />
         </Fab>
      )}
    </Box>
  )
}

export default BasicTemplate
