import { makeStyles, colors } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4)
  },
  finishedStatusText: {
    textDecoration: 'line-through',
    color: colors.grey[500]
  }
}))
