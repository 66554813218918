import React from 'react'

import { FiTrash } from 'react-icons/fi'

import { Box, Grid, Typography, IconButton } from '@material-ui/core'
import { ButtonCount } from 'components'

import useStyles from './styles'

import restaurantNotFoundImg from 'assets/images/logoMenuShop.svg'

const ProductItem = ({ product, updateAmount, productCartData, deleteProduct, productPrice }) => {
  const classes = useStyles()

  return (
    <Box
      mb={2}
      p={1}
      border={1}
      borderRadius="borderRadius"
      borderColor="grey.500"
    >
      <Grid container>
        <Grid
          item
          sm={8}
          xs={12}
        >
          <Box
            display="flex"
            flexDirection="row"
          >
            <Box
              className={classes.image}
              style={{ backgroundImage: `url(${product.image})` }}
              mr={2}
            >
              {!product.image && <img src={restaurantNotFoundImg} width="100px" alt={product.name} />}
            </Box>

            <Box mt={1}>
              <Typography variant="subtitle1" className={classes.typographyName}>
                {product.name}
              </Typography>
              <Typography variant="caption" display="block" gutterBottom>
                {productPrice.toFixed(2)} X {productCartData?.amount}
              </Typography>
              <Typography variant="subtitle2" className={classes.typographyPrice}>
                R$ {productPrice.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            height="100%"
          >
            <Box className={classes.boxTrashIcon} mr={2}>
              <IconButton size="medium" onClick={deleteProduct}>
                <FiTrash color="#000" />
              </IconButton>
            </Box>
            <ButtonCount mt={0} count={productCartData?.amount || 0} setCount={(number) => updateAmount(number)}></ButtonCount>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductItem
