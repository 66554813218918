import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Dialog from '@material-ui/core/Dialog'
import Box from '@material-ui/core/Box'
import { Button, makeStyles } from '@material-ui/core'
import { useLocation } from 'react-router-dom'

import api from 'services/api'
import PrintRequest from 'components/PrintRequest'
import { useSnackbar } from 'hooks'

import ListProductRequest from './ListProductRequest'
import { getQueryByName } from 'helpers/query'

const useStyles = makeStyles({
  dialog: {
    padding: 24
  },
  buttons: {
    display: 'grid',
    gap: 8
  }
})

/**
 * @component
 * @example
 * <TableRequestModal isVisible onClose={() => console.log('Closed!')} />
 */
export default function TableRequestModal ({ isVisible = false, onClose }) {
  const classes = useStyles()
  const [request, setRequest] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { openSnackbar } = useSnackbar()
  const { search } = useLocation()

  useEffect(() => {
    if (!isVisible) return

    const tableId = getQueryByName(search, 'table')

    if (!tableId) return

    (async () => {
      try {
        const params = { table_id: tableId }
        const { data: [firstRequest] } = await api.get('/requests', { params })

        if (!firstRequest) throw new Error()

        const { data } = await api.get(`/requests/${firstRequest.id}`)

        setRequest(data.object)
        setIsLoading(false)
      } catch (e) {
        handleClose()
        openSnackbar('Esta mesa não possui pedidos.', 'warning')
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  const handleClose = () => {
    onClose()
    handleResetRequest()
  }

  const handleResetRequest = () => {
    setRequest()
  }

  return (
    <Dialog open={isVisible && request} onClose={handleClose} fullWidth>
      <Box className={classes.dialog}>
        {!isLoading && !!request && (
          <>
            <ListProductRequest request={request} />

            <Box className={classes.buttons}>
              <PrintRequest request={request} />

              <Button
                variant='outlined'
                onClick={handleClose}
                fullWidth
              >
              Voltar
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  )
}

TableRequestModal.protoTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func.isRequired
}
