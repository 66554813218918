import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  Container,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  IconButton,
  Box,
  CircularProgress,
  Typography
} from '@material-ui/core'
import { VisibilityOutlined as VisibilityOutlinedIcon } from '@material-ui/icons'

import { Header } from 'components'
import { BasicTemplate } from 'templates'

import useStyles from './styles'
import api from 'services/api'
import history from 'services/history'
import { requestStatusLabelHelper } from 'helpers'

const UserRequestList = () => {
  const classes = useStyles()

  const [requests, setRequests] = useState([])
  const [loading, setLoading] = useState(true)

  const getRequests = useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get('/requests')

      setRequests(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    getRequests()
  }, [getRequests])

  const { concluded, opened } = useMemo(() => ({
    concluded: requests.filter(request => request.status === 3),
    opened: requests.filter(request => request.status !== 3)
  }), [requests])

  const handleShowRequest = (requestId) => {
    history.push(`/user-requests/${requestId}`)
  }

  const tipoPedido = (tipo) => {
    if (tipo === 1) {
      return 'Local'
    }
    if (tipo === 2) {
      return 'Entrega'
    }
    if (tipo === 3) {
      return 'Retirada'
    }
  }

  return (
    <BasicTemplate>
      <Container className={classes.container}>
        <Header title="Pedidos" withButton={false} />

        {loading && (
          <Box
            width="100%"
            alignItems="center"
            justifyContent="center"
            display="flex"
            p={5}
          >
            <CircularProgress />
          </Box>
        )}

        {!loading && !!opened.length && (
          <>
            <Typography
              variant="h4"
              style={{ marginBottom: '0.5em' }}
            >
              Em andamento
            </Typography>

            <TableContainer component={Paper} style={{ marginBottom: '2em' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell width="10%">#</TableCell>

                    <TableCell width="35%">Restaurante</TableCell>

                    <TableCell width="25%">Status</TableCell>

                    <TableCell width="25%">Tipo Pedido</TableCell>

                    <TableCell width="20%">Preço (RS)</TableCell>

                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {opened.map((request) => (
                    <TableRow key={String(request.id)}>
                      <TableCell>{request.request_number || request.id}</TableCell>

                      <TableCell>{request.restaurant.name}</TableCell>

                      <TableCell>
                        {requestStatusLabelHelper(request.status)}
                      </TableCell>

                      <TableCell>
                        {tipoPedido(request.request_type_id)}
                      </TableCell>

                      <TableCell>{request.total_price.toFixed(2)}</TableCell>

                      <TableCell align="right">
                        <IconButton color="primary" onClick={() => handleShowRequest(request.id)}>
                          <VisibilityOutlinedIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}

        <Typography
          variant="h4"
          style={{ marginBottom: '0.5em' }}
        >
          Concluídos
        </Typography>

        {!loading && !!concluded.length && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="10%">#</TableCell>

                  <TableCell width="35%">Restaurante</TableCell>

                  <TableCell width="25%">Status</TableCell>

                  <TableCell width="25%">Tipo Pedido</TableCell>

                  <TableCell width="20%">Preço (RS)</TableCell>

                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {concluded.map((request) => (
                  <TableRow key={String(request.id)}>
                    <TableCell>{request.request_number || request.id}</TableCell>

                    <TableCell>{request.restaurant.name}</TableCell>

                    <TableCell
                      className={classes.finishedStatusText}
                    >
                      {requestStatusLabelHelper(request.status)}
                    </TableCell>

                    <TableCell>
                      {tipoPedido(request.request_type_id)}
                    </TableCell>

                    <TableCell>{request.total_price.toFixed(2)}</TableCell>

                    <TableCell align="right">
                      <IconButton color="primary" onClick={() => handleShowRequest(request.id)}>
                        <VisibilityOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Container>
    </BasicTemplate>
  )
}

export default UserRequestList
