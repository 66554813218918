const requestStatusLabelHelper = (status) => {
  switch (status) {
    case 1:
      return 'Em andamento'
    case 2:
      return 'Em preparo'
    case 3:
      return 'Concluído'
    case 4:
      return 'Saiu para entrega'
    case 5:
      return 'Pronto, aguardando retirada'
    default:
      return '-'
  }
}

export default requestStatusLabelHelper
