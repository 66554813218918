import React from 'react'
import { TextField, Box } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import history from 'services/history'
import api from 'services/api'
import schema from './schema'
import { useSnackbar, useLoading } from 'hooks'

import { Snackbar, LoadingButton } from 'components'

function Form () {
  const { openSnackbar } = useSnackbar()
  const { setLoading } = useLoading()

  const { register, errors, control, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: {
      email: ''
    },
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      const response = await api.post('/reset-password', data)

      openSnackbar(response.data.message)
      history.push('/login')
    } catch (error) {
      openSnackbar(error.response.data.message, 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Email"
              inputRef={register}
              fullWidth
              error={!!errors.email}
              helperText={errors && errors.email && errors.email.message}
            />
          }
          control={control}
        />

        <Box my={1}>

          <LoadingButton
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            size={30}
          >
            Recuperar
          </LoadingButton>
        </Box>
      </form>

      <Snackbar />
    </>
  )
}

export default Form
