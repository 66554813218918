import React from 'react'
import { makeStyles } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

/**
 * @typedef {Object} IUserAddress
 * @property {String} address
 * @property {String} number
 * @property {String} complement
 * @property {String} district
 * @property {String} zip_code
 * @property {String} city
 * @property {String} state
 *
 * @param {IUserAddress} userAddress
 * @returns {String}
 */
function getAddress ({
  address,
  number,
  complement,
  district,
  city,
  state,
  zip_code: zipCode
}) {
  return `${address}, Nº ${number} - ${complement} - ${district} - ${zipCode} ${city} - ${state}`
}

const useStyles = makeStyles({
  userInfo: {
    margin: '1em 0'
  }
})

export default function PrintRequestUserInfo ({ user, requestTypeId }) {
  const classes = useStyles()

  return (
    <Box className={classes.userInfo}>
      <Typography>
        <b>Cliente:</b> {user.name}
      </Typography>

      <Typography>
        <b>Fone:</b> {user.phone.number}
      </Typography>

      {requestTypeId === 2 && !!user.users_address && (
        <>
          <Typography>Endereço de entrega:</Typography>
          <Typography>
            {getAddress(user.users_address)}
          </Typography>
        </>
      )}
    </Box>
  )
}
