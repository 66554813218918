import React, { useState } from 'react'

import CategoryContext from 'contexts/categoryContext'

function CategoryProvider ({ children }) {
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState({})

  return (
    <CategoryContext.Provider value={{ categories, setCategories, category, setCategory }}>
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryProvider
