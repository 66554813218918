import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  boxInformation: {
    height: '100%'
  },
  chart: {
    height: '350px'
  }
}))
