import React from 'react'
import { Typography } from '@material-ui/core'

import useStyles from './styles'

function TitleLabel ({ title, label }) {
  const classes = useStyles()
  return (
    <>
      <Typography variant="caption">{label}</Typography>
      <Typography className={classes.title} variant="h4">{title}</Typography>
    </>
  )
}

export default TitleLabel
