import React from 'react'
import { useUser } from 'hooks'
import { Route, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

export default function PrivateRoute ({ component: Component, isPermmitedBy, ...rest }) {
  const { signed, user } = useUser()

  if (!signed) {
    return <Redirect to="/login" />
  }

  if (!isPermmitedBy.includes(user.profile_id)) {
    return <Redirect to="/login" />
  }

  return <Route {...rest} component={Component} />
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
}
