import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { PlatformTemplate } from 'templates'
import { Restaurant, Category } from 'pages'
import { RestaurantProvider, CategoryProvider } from 'providers'

export default function Routes () {
  return (
    <PlatformTemplate>
      <Switch>
        <Route exact path="/admin">
          <RestaurantProvider>
            <Restaurant />
          </RestaurantProvider>
        </Route>
        <Route exact path="/admin/categories">
          <CategoryProvider>
            <Category />
          </CategoryProvider>
        </Route>
      </Switch>
    </PlatformTemplate>
  )
}
