import React from 'react'

import { Dialog, DialogTitle, DialogActions, Button } from '@material-ui/core'

function DialogConfirmation ({ open, setOpen, handleSubmit }) {
  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    handleClose()
    handleSubmit()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        Confirmação de pedido
      </DialogTitle>
      <DialogActions>
        <Button onClick={handleClose}>
          Não
        </Button>
        <Button onClick={submit} color="primary">
          Pedir
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogConfirmation
