import React, {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import Typography  from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import TextInput from 'components/TextInput'
import api from 'services/api'
import { useSnackbar } from 'hooks'

import schema from './schema'
import useStyles from './styles'

/**
 * @typedef {Object} IAddress
 * @property {string} address
 * @property {string} city
 * @property {string} complement
 * @property {string} district
 * @property {string} number
 * @property {string} state
 * @property {string} zip_code
 * 
 * @param {IAddress} address 
 */
function getAdress({address, city, district, number, state, zip_code}) {
  return `${address}, ${number}, ${city}, ${district}, ${state} (${zip_code})`;
}

export default function DialogAddressForm ({ address, onCancel, onAddressCreated }) {
  const classes = useStyles()
  const [isEditing, setIsEditing] = useState(false)
  const { openSnackbar } = useSnackbar()
  const { formState: { errors }, control, reset, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues:{
      address: address?.address || '',
      number: address?.number || '',
      complement: address?.complement || '',
      district: address?.district || '',
      zipCode: address?.zip_code || '',
      city: address?.city || '',
      state: address?.state || ''
    },
  })
  useEffect(() => {
    if (address) {
      const { zip_code, ...rest} = address
      reset({...rest, zipCode: zip_code})
    }
  }, [address])

  const handleUseAddress = () => {
    onAddressCreated(address)
  }

  const handleEditAddress = () => {
    setIsEditing(true)
  }

  const onSubmit = async ({ zipCode, ...formData }) => {
    try {
      const method = address ? 'put' : 'post'
    
      const { data } = await api[method]('/users/address', { zip_code: zipCode, ...formData })

      openSnackbar(data.message, 'success')
      onAddressCreated(data.object)
    } catch (e) {
      openSnackbar('Erro ao tentar cadastrar endereço.', 'error')
    }
  }

  const handleCancel = () => {
    if (isEditing) {
      setIsEditing(false);
      return;
    }

    onCancel();
  }

  if (address && !isEditing) {
    return (
      <div className={classes.form}>
        <Typography>{getAdress(address)}</Typography>

        <div className={classes.buttons}>
          <Button
            color="primary"
            onClick={handleUseAddress}
            disabled={false}
          >
            Usar
          </Button>

          <Button
            color="secondary"
            onClick={handleEditAddress}
            disabled={false}
          >
            Editar
          </Button>
        </div>
      </div>
    )
  }

  return (
    <form className={classes.form}>
      <TextInput
        control={control}
        name="address"
        label="Rua"
        error={errors.address}
      />
      <TextInput
        control={control}
        name="number"
        label="Número"
        error={errors.number}
      />
      <TextInput
        control={control}
        name="complement"
        label="Complemento"
        error={errors.complement}
      />
      <TextInput
        control={control}
        name="district"
        label="Bairro"
        error={errors.district}
      />
      <TextInput
        control={control}
        name="zipCode"
        label="Cep"
        error={errors.zipCode}
      />
      <TextInput
        control={control}
        name="city"
        label="Cidade"
        error={errors.city}
      />
      <TextInput
        control={control}
        name="state"
        label="Estado"
        error={errors.state}
      />

      <div className={classes.buttons}>
        <Button
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={false}
        >
          Enviar
        </Button>

        <Button
          color="secondary"
          onClick={handleCancel}
          disabled={false}
        >
          Cancelar
        </Button>
      </div>
    </form>
  )
}
