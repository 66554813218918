import { makeStyles } from '@material-ui/core'

export default makeStyles({
  searchBox: {
    display: 'flex',
    border: '1px solid #E0E0E0',
    borderRadius: 6,
    backgroundColor: '#FAFAFA'
  },
  searchInput: {
    flex: 1
  }
})
