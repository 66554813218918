import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    minHeight: 'calc(100vh - 75px)'
  },
  paper: {
    padding: 16,
    margin: 8,
    maxWidth: 420,
    width: '100%'
  }
})
