import React from 'react'
import {
  Dialog,
  Box,
  Typography,
  Divider
} from '@material-ui/core'

import { Snackbar } from 'components'
import QrcodeTable from '../QrcodeTable/QrcodeTable';
import useStyles from './styles'

function ChangeOpenQrcode ({
  open,
  setOpen,
  size = 'md',
  selectedTable,
  reload,
  handleQrcode,
  ...rest
}) {
  const classes = useStyles()

  function handleClose () {
    return setOpen(false)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth={size}
      fullWidth
      {...rest}
    >
       <Box
        className={classes.dialog}
      >
        <Box mb={2}>
          <Typography variant="h5">
            Qrcode Mesa Nº {selectedTable.number}
          </Typography>
        </Box>
        <Divider />
        <QrcodeTable restaurant={selectedTable} />

        <Snackbar />
      </Box>
    </Dialog>
  )
}

export default ChangeOpenQrcode

