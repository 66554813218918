import React, { useState } from 'react'
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  List,
  ListItem,
  Button
} from '@material-ui/core'
import { FiPlusCircle } from 'react-icons/fi'

import api from 'services/api'

import { DropMenu, DialogConfirmation } from 'components'
import DialogProductOption from '../DialogProductOption/DialogProductOption'

import { useSnackbar } from 'hooks'
import useStyles from './styles'

function ProductOption ({ categoryProductOption, reload }) {
  const [openOption, setOpenOption] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [deleteProductOption, setDeleteProductOption] = useState({})
  const [productOptionDialog, setProductOptionDialog] = useState({})
  const [productOptions, setProductOptions] = useState(categoryProductOption.product_options)

  const [modeOption, setModeOption] = useState('create')
  const classes = useStyles()
  const { openSnackbar } = useSnackbar()

  const handleEditProductOption = (productOption) => {
    setModeOption('edit')
    setProductOptionDialog(productOption)
    setOpenOption(true)
  }
  const handleCreateProductOption = () => {
    setModeOption('create')
    setProductOptionDialog({})
    setOpenOption(true)
  }

  const handleOpenDialogDestroy = (productOption) => {
    setOpenConfirmationDialog(true)
    setDeleteProductOption(productOption)
  }

  const destroyProductOption = async () => {
    try {
      await api.delete(`category-product-option/${categoryProductOption.id}/options/${deleteProductOption.id}`)

      getProductOptions()
      openSnackbar('Opcional deletado com sucesso!')
    } catch (error) {
      openSnackbar('Não foi possível remover o opcional!', 'error')
    }
  }
  
  const getProductOptions = async () => {
    return api.get(`/category-product-option/${categoryProductOption.id}/options`).then(response => {
      setProductOptions(response.data.object)
      return response.data.object
    })
  }

  return (
    <>
      <Typography variant="h6" className={classes.title}>Opcionais</Typography>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell width="40%">Produto</TableCell>
              <TableCell width="30%">Preço (R$)</TableCell>
              <TableCell width="20%">Disponível</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => handleCreateProductOption()}>
                  <FiPlusCircle />
                </IconButton>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productOptions?.map(productOption => (
              <TableRow key={productOption.id}>
                <TableCell>{productOption.name}</TableCell>
                <TableCell>{productOption.price.toFixed(2)}</TableCell>
                <TableCell>{productOption.available ? 'Sim' : 'Não'}</TableCell>
                <TableCell align="right">
                  <DropMenu>
                    <List>
                      <ListItem>
                        <Button onClick={() => handleEditProductOption(productOption)}>
                          EDITAR
                        </Button>
                      </ListItem>
                      <ListItem>
                        <Button onClick={() => handleOpenDialogDestroy(productOption)}>
                          DELETAR
                        </Button>
                      </ListItem>
                    </List>
                  </DropMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <DialogConfirmation
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        message="Deseja excluir?"
        handleSubmit={destroyProductOption}
      />
      <DialogProductOption
        open={openOption}
        mode={modeOption}
        handleClose={() => setOpenOption(false)}
        categoryProductOption={categoryProductOption}
        productOption={productOptionDialog}
        reload={() => getProductOptions()}
      />
    </>
  )
}

export default ProductOption
