import React, { useState, useEffect } from 'react'
import {
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  FormHelperText
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { Dialog, Snackbar } from 'components'
import editSchema from './editSchema'
import createSchema from './createSchema'
import useStyles from './styles'
import api from 'services/api'

import { useDialog, useSnackbar, useRestaurant } from 'hooks'

function DialogRestaurant({ reload }) {
  const [categories, setCategories] = useState([])

  const classes = useStyles()
  const { handleClose, mode } = useDialog()
  const { openSnackbar } = useSnackbar()
  const { restaurant } = useRestaurant()

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: mode === 'create' ? createSchema : editSchema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(mode === 'create' ? createSchema : editSchema)
  })

  useEffect(() => {
    getCategories()
  }, [])

  const getCategories = async () => {
    try {
      const categories = await api.get('/categories')

      setCategories(categories.data.object)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data) => {
    try {
      if (mode === 'create') {
        await api.post('/restaurants', {
          user: {
            name: data.userName,
            email: data.userEmail,
            password: data.userPassword,
          },
          name: data.name,
          description: data.description,
          cnpj: data.cnpj,
          category_id: data.category_id,
	        schedule:data.schedule
        })
        handleClose()
        openSnackbar('Restaurante criado com sucesso')
      }
      if (mode === 'edit') {
        await api.put(`/restaurants/${restaurant.id}`, data)
        handleClose()
        openSnackbar('Restaurante atualizado com sucesso')
      }

      reload()
    } catch (error) {
      openSnackbar('Ocorreu um erro', 'error')
    }
  }

  return (
    <>
      <Dialog maxWidth="sm">
        <Typography variant="h5">
          Cadastro de restaurantes
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Nome"
                inputRef={register}
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
              />
            }
            defaultValue={restaurant.name || ''}
            control={control}
          />
          <Controller
            name="description"
            as={
              <TextField
                variant="outlined"
                multiline
                rows={3}
                margin="normal"
                label="Descrição"
                inputRef={register}
                fullWidth
                error={!!errors.description}
                helperText={errors?.description?.message}
              />
            }
            defaultValue={restaurant.description || ''}
            control={control}
          />
          <Controller
            name="cnpj"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="CNPJ"
                inputRef={register}
                fullWidth
                type="number"
                error={!!errors.cnpj}
                helperText={errors?.cnpj?.message}
              />
            }
            defaultValue={restaurant.cnpj || ''}
            control={control}
          />

          <FormControl fullWidth className={classes.selectInput} variant="outlined">
            <InputLabel id="categoryIdSelectLabel">Categoria</InputLabel>
            <Controller
              as={
                <Select
                  label="Categoria"
                  labelId="categoryIdSelectLabel"
                  id="categoryIdSelect"
                  variant="outlined"
                >
                  {categories.map(category => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              }
              name="category_id"
              control={control}
              defaultValue={restaurant.category_id || 'vanilla'}
              error={errors && errors.category_id}
            />
            <FormHelperText>{errors.category_id && errors.category_id.message}</FormHelperText>
          </FormControl>

          <Divider />

          <Typography variant="h6">Responsável</Typography>

          <Controller
            name="userName"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Nome"
                inputRef={register}
                fullWidth
                error={!!errors.userName}
                helperText={errors?.userName?.message}
                disabled={mode === 'edit'}
              />
            }
            defaultValue={restaurant?.user?.name || ''}
            control={control}
          />
          <Controller
            name="userEmail"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Email"
                type="email"
                inputRef={register}
                fullWidth
                error={!!errors.userEmail}
                helperText={errors?.userEmail?.message}
                disabled={mode === 'edit'}
              />
            }
            defaultValue={restaurant?.user?.email || ''}
            control={control}
          />
          {mode === 'create' &&
            <Controller
              name="userPassword"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Senha"
                  inputRef={register}
                  type="password"
                  fullWidth
                  error={!!errors.userPassword}
                  helperText={errors?.userPassword?.message}
                  disabled={mode === 'edit'}
                />
              }
              defaultValue={''}
              control={control}
            />
          }

          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={handleClose}>Voltar</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              className={classes.submitButton}
            >
              Enviar
            </Button>
          </Box>
        </form>
      </Dialog>

      <Snackbar />
    </>
  )
}

export default DialogRestaurant
