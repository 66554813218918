import React from 'react'

import SessionTemplate from 'templates/SessionTemplate'
import { TitleLabel, HelperText } from 'components'
import { Form } from './components'

function Send () {
  return (
    <SessionTemplate>
      <TitleLabel title="Senha" label="Esqueceu a" />
      <HelperText>
        Digite seu email, para enviarmos mais instruções
      </HelperText>
      <Form />
    </SessionTemplate>
  )
}

export default Send
