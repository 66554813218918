import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export default makeStyles({
  appbar: {
    backgroundColor: grey[900],
    color: '#FFF',
    height: 75,
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1000,
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto'
  },
  tollbar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  leftTollbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  links: {
    marginLeft: 88
  },
  link: {
    textDecoration: 'none',
    color: grey[200],
    fontFamily: 'Ubuntu, Roboto, sans-serif',
    fontSize: 16
  },
  image: {
    height: 50
  },
  button: {
    color: 'gray',
    alignSelf: 'flex-start'
  },
  buttonNavbar: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'center'
  },
  margin_right_16: {
    marginRight: 16
  },
  iconButtonNavBar: {
    marginRight: '15px'
  },
  drawer: {
    padding: 16,
    width: '50vw'
  },
  avatar: {
    cursor: 'pointer'
  },
  linkRedirect: {
    padding: '0 8px',
    color: '#FFF',
    textDecoration: 'none'
  }
})
