import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  categoryBox: {
    marginBottom: theme.spacing(3)
  },
  categoryName: {
    marginBottom: theme.spacing(2),
    fontWeight: 450
  }
}))
