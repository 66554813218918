import React, { useState, useEffect } from 'react'

import {
  Container,
  Box,
  Button,
  Divider,
  Typography,
  CircularProgress,
  Snackbar
} from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'

import { BasicTemplate } from 'templates'
import {
  DialogConfirmation,
  DialogFeedback,
  ProductItem,
  Footer,
  DialogConsuption,
  DialogSelectTable,
  DialogAddress
} from './components'

import { useCart, useUser, useSnackbar } from 'hooks'

import history from 'services/history'
import api from 'services/api'
import useStyles from './styles'

function Cart () {
  const { cart: cartData, setCartStorage } = useCart()
  const { openSnackbar } = useSnackbar()
  const { signed, user } = useUser()
  const classes = useStyles()

  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalPrice, setTotalPrice] = useState(0)
  const [openDialogConfirmation, setOpenDialogConfirmation] = useState(false)
  const [openDialogConsuption, setOpenDialogConsuption] = useState(false)
  const [openDialogFeedback, setOpenDialogFeedback] = useState(false)
  const [openDialogTable, setOpenDialogTable] = useState(false)
  const [table, setTable] = useState(null)
  const [consuption, setConsuption] = useState()
  const [requestId, setRequestId] = useState()
  const [userAddress, setUserAddress] = useState()
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false)

  const getUserAddress = async () => {
    try {
      const { data } = await api.get('/users')
      setUserAddress(data.object.users_address)
    } catch (e) {
      // do nothing
    }
  }

  useEffect(() => {
    getUserAddress()
  }, [])

  const getProduct = async (productCart) => {
    try {
      const productResponse = await api.get(`/products/${productCart.product_id}`)

      if (productCart.product_options_request_products.length > 0) {
        const optionsResponse = await api.get(`/products/${productCart.product_id}/options`)

        let productOptions = []

        optionsResponse.data.object.forEach((option) => {
          if (option.category_product_option.product_options.length > 0) productOptions.push(...option.category_product_option.product_options)
        })

        return { ...productResponse.data.object, options: productOptions }
      }

      return productResponse.data.object
    } catch (err) {
      console.warn(err)
    }
  }

  const requestProducts = async () => {
    let ordenedCartProduct = []
    setLoading(true)

    await Promise.all(cartData.map(async (productCart) => {
      const productResponse = await getProduct(productCart)

      var productsArray = products
      productsArray.push({ ...productResponse, cart: productCart })

      setProducts(productsArray)
      ordenedCartProduct.push(productCart)
    }))

    setCartStorage(ordenedCartProduct)
    calcTotalPrice()
    setLoading(false)
  }

  const calcProductPrice = (product, productCart) => {
    let additionalPrice = 0

    if (productCart.product_options_request_products.length > 0) {
      productCart.product_options_request_products.forEach((productOption) => {
        product.options.every((option) => {
          if (productOption.product_option_id === option.id) {
            additionalPrice += option.price
            return false
          }
          return true
        })
      })
    }
    
    return (product.price + additionalPrice) * productCart.amount
  }

  const calcTotalPrice = () => {
    let price = 0

    products.forEach((product) => {
      price += calcProductPrice(product, product.cart)
    })

    setTotalPrice(price)
  }

  const handleOpenMakeRequest = () => {
    if (!signed) {
      openSnackbar('Para fazer pedido, é necessário estar logado!', 'error')
      history.push('/login')
      return
    }

    setOpenDialogConsuption(true)
  }
  
  const handleSubmitConsuption = (consuption) => {
    setConsuption(consuption)

    if (consuption === '2') {
      setIsAddressModalVisible(true)
      return
    }

    if (consuption === '1') {
      setOpenDialogTable(true)
      return
    }

    setOpenDialogConfirmation(true)
  }

  const handleSubmitTable = (table) => {
    setTable(table)
    setOpenDialogConfirmation(true)
  }

  const handleSubmitConfirmartion = async () => {
    try {
      const restaurantData = await api.get(`/restaurants?slug=true&id=${cartData[0]?.restaurant_id}`)
      const response = await api.post('/requests', {
        restaurant_id: restaurantData.data.object.restaurants[0].id,
        request_type_id: Number(consuption),
        table_id: consuption === '1' ? table : undefined,
        request_products: cartData
      })

      setCartStorage([])
      setProducts([])
      setRequestId(response?.data?.object?.request_response?.id)
      setOpenDialogFeedback(true)
    } catch (err) {
      openSnackbar(err.message, 'error')
    }
  }

  const handleSubmitFeedback = () => {
    history.push(`/user-requests/${requestId}`)
  }

  const updateAmount = (amount, index) => {
    var productCart = cartData
    productCart[index].amount = amount
    setCartStorage(productCart)
    calcTotalPrice()
  }

  const deleteProduct = async (index) => {
    var productsCart = cartData
    var productsList = products

    productsCart.splice(index, 1)
    productsList.splice(index, 1)
    
    setProducts([...productsList])
    setCartStorage(productsCart)
    calcTotalPrice()
  }

  useEffect(() => {
    requestProducts()

    // eslint-disable-next-line
  }, [])

  const handleCloseAddressForm = () => {
    setIsAddressModalVisible(false)
  }

  const handleAddressCreated = (address) => {
    setUserAddress(address)
    handleCloseAddressForm()
    setOpenDialogConfirmation(true)
  }

  return (
    <BasicTemplate footer={false} className={classes.template}>
      {loading ? (
        <Box
          display="flex"
          height="80vh"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress/>
        </Box>
      ) : (
        <>
          <Container>
            <Box py={3}>
              <Button onClick={() => history.goBack()} startIcon={<ArrowBackIcon size="small" />}>
                Voltar
              </Button>
            </Box>
            <Divider />
            <Box py={3}>
              {products.length === 0 && (
                <Box display="flex" justifyContent="center">
                  <Typography>SACOLA VAZIA</Typography>
                </Box>
              )}

              {products.map((product, index) => (
                <ProductItem
                  key={`${product.id}-${index}`}
                  product={product}
                  updateAmount={(number) => updateAmount(number, index)}
                  productCartData={product.cart}
                  deleteProduct={() => deleteProduct(index)}
                  productPrice={calcProductPrice(product, product.cart)}
                />
              ))}
            </Box>
          </Container>

          {products.length > 0 && (
            <Footer price={totalPrice} openDialogConfirmation={handleOpenMakeRequest} />
          )}
        </>
      )}

      <DialogConsuption
        open={openDialogConsuption}
        setOpen={setOpenDialogConsuption}
        handleSubmit={handleSubmitConsuption}
      />
      <DialogSelectTable
        open={openDialogTable}
        setOpen={setOpenDialogTable}
        handleSubmit={handleSubmitTable}
        restaurantId={cartData[0]?.restaurant_id}
      />
      <DialogConfirmation
        open={openDialogConfirmation}
        setOpen={setOpenDialogConfirmation}
        handleSubmit={handleSubmitConfirmartion}
      />
      <DialogFeedback
        open={openDialogFeedback}
        setOpen={setOpenDialogFeedback}
        handleSubmit={handleSubmitFeedback}
      />
      <DialogAddress
        isVisible={isAddressModalVisible}
        address={userAddress}
        onClose={handleCloseAddressForm}
        onAddressCreated={handleAddressCreated}
      />
      <Snackbar />
    </BasicTemplate>
  )
}

export default Cart
