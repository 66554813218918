import api from './api'

/**
 * @param {Error} error
 */
function parseError (error) {
  if (!error) return null

  const exception = {}

  if (error.name) exception.name = error.name
  if (error.message) exception.message = error.message
  if (error.stack) exception.stack = error.stack

  return exception
}

/**
 *
 * @param {string} message - Anything to be logged on server.
 * @param {Error} error - Error to be printed.
 *
 * @returns {Promise<void>}
 */
export async function log (message, error) {
  const e = parseError(error)

  try {
    await api.post('logs', { message: `[WEB]: ${message}`, error: e })
  } catch (e) {
    console.log('Failed to log')
  }
}
