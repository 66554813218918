import React from 'react'
import {
  Box,
  Dialog,
  IconButton
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'

import useStyles from './styles'

function DialogProduct ({
  open = false,
  handleClose = () => {},
  imageUrl = ''
}) {
  const classes = useStyles()

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <Box className={classes.dialog}>
        <Box display="flex" justifyContent="center" alignItems="center" width="100%">
          <img className={classes.image} src={imageUrl} alt="imagem" />
        </Box>
      </Box>
      <Box className={classes.closeButton}>
        <IconButton onClick={handleClose} color="primary">
          <CloseIcon />
        </IconButton>
      </Box>
    </Dialog>
  )
}

export default DialogProduct
