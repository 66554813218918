import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  available: {
    backgroundColor: '#28A745'
  },
  orderInProgress: {
    backgroundColor: '#FFBE00'
  },
  busyWithoutOrder: {
    backgroundColor: '#335DFD'
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#FFF',
    padding: theme.spacing(3),
    position: 'relative'
  },
  action: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    color: '#FFF',
    textAlign: 'center'
  },
  text: {
    color: '#FFF',
    textOverflow: 'ellipsis'
  },
  iconButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}))
