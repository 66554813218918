import React, { useState, useEffect, useCallback } from 'react'
import { Container, Box, Typography, Chip, Divider, CircularProgress, Button, Paper } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons'
import { useParams } from 'react-router'

import { Title } from 'components'
import { BasicTemplate } from 'templates'

import useStyles from './styles'
import api from 'services/api'
import { requestStatusLabelHelper } from 'helpers'
import history from 'services/history'
import { useRealtimeUpdates } from 'hooks/useUpdater'

const UserRequestShow = () => {
  const { id } = useParams()
  const classes = useStyles()

  const [request, setRequest] = useState({})
  const [loading, setLoading] = useState(true)
//  const typeResquest = getTypeReq();response.data.object.request_type_id == 2 ? true : false;

  const getRequest = useCallback(async () => {
    try {
      setLoading(true)
      const response = await api.get(`/requests/${id}`)
      setRequest(response.data.object)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, []);

  useEffect(() => {
    getRequest()
  }, [])

  useRealtimeUpdates(async () => {
    try{
      const { data } = await api.get(`/requests/${id}/status`);

      setRequest(prevRequest => ({
        ...prevRequest,
        status: data.object.status
      }))
    } catch(e) {
      // do nothing
    }
  })

  return (
    <BasicTemplate>
      <Container className={classes.container}>
        <Box py={3}>
          <Button onClick={() => history.goBack()} startIcon={<ArrowBackIcon size="small" />}>
            Voltar
          </Button>
          <Divider />
        </Box>
        {loading ? (
          <Box
            width="100%"
            alignItems="center"
            justifyContent="center"
            display="flex"
            p={5}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Paper component={Box} p={2}>
            <Box className={classes.header} display="flex" justifyContent="space-between" alignItems="center">
              <Title title={`Pedido #${request.request_number || request.id}`} />

              <Chip label={requestStatusLabelHelper(request?.status)} color={requestStatusLabelHelper(request?.status) === 'Concluído' ? 'default' : 'primary'} />
            </Box>
            <Box p={1} display="flex" flexDirection="column">
              {request?.request_products?.map((request_product) => (
                <>
                  <Box py={1} display="flex" flexDirection="column">
                    <Typography variant="subtitle1" component="subtitle1">
                      <b>Produto:</b> {request_product?.amount}x - {request_product?.product?.name}  <div  style={{display: 'flex',color:'#d90000'}}><b>R$ {request_product?.current_price?.toFixed(2)}</b></div>
                    </Typography>

                    {request_product?.note && (  
                      <Typography variant="subtitle1" component="subtitle1">
                        <b>Anotações:</b> {request_product.note}
                      </Typography>
                    )}

                    {request_product?.product_options_request_products?.length > 0 && (
                      <Box pt={1} display="flex" flexDirection="column">
                        <Typography><b>Adicionais</b></Typography>

                        {request_product.product_options_request_products.map((product_options_request_product) => (
                          <Typography variant="subtitle1" component="subtitle1">
                            {product_options_request_product?.amount}x - {product_options_request_product?.product_option?.name} 
                          </Typography>
                        ))}
                      </Box>
                    )}
                  </Box>

                  <Divider />
                  
                </>
              ))}
              
              <Box pt={1}>
                <Typography>
                  <b>Preço Total: R$ {request?.total_price?.toFixed(2)}</b>
                </Typography>
              </Box>

              {request.request_type_id === 2  && request.user.users_address? (
                <Box py={1} display="flex" flexDirection="column">
                       <Typography variant="subtitle1" component="subtitle1">
                        <b>Endereço de Entrega:</b>
                    </Typography>
                    <p>{request.user.users_address.address}, {request.user.users_address.number} - {request.user.users_address.complement} - {request.user.users_address.district} - {request.user.users_address.zip_code} - {request.user.users_address.city}/{request.user.users_address.state}</p>
                </Box>
              ) : (
                <Box py={1} display="flex" flexDirection="column">
                </Box>
              )}

            </Box>
          </Paper>
        )}
      </Container>
    </BasicTemplate>
  )
}

export default UserRequestShow
