import React, { useEffect, useState, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Paper, Grid } from '@material-ui/core'

import api from 'services/api'
import { useUser } from 'hooks'

import {
  Header,
  SquareTable,
  ChangeStatusDialog,
  AddProductsModal,
  TableRequestModal
} from './components'
import useStyles from './styles'
import { getQueryByName } from 'helpers/query'

export default function RequestsTablesAdd () {
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false)
  const [isAddProductModalVisible, setIsAddProductModalVisible] = useState(false)
  const [isRequestModalVisible, setIsRequestModalVisible] = useState(false)
  const classes = useStyles()
  const [tables, setTables] = useState([])
  const { user } = useUser()
  const { search } = useLocation()

  const getTables = async () => {
    try {
      const response = await api.get(`/restaurants/${user.restaurant_id}/tables`)
      setTables(response.data.tables)
    } catch (error) {
      console.log((error.response && error.response.data) || error)
    }
  }

  const handleTableOption = (option) => {
    switch (option.id) {
      case 'changeStatus': {
        setOpenChangeStatusDialog(true)
        break
      }
      case 'addProduct': {
        setIsAddProductModalVisible(true)
        break
      }
      case 'request': {
        setIsRequestModalVisible(true)
        break
      }
      default: break
    }
  }

  const handleDismissAddProductModal = () => {
    setIsAddProductModalVisible(false)
  }

  const handleCloseAddProductModal = () => {
    reload()
    handleDismissAddProductModal()
  }

  const handleCloseRequestModal = () => {
    setIsRequestModalVisible(false)
  }

  const squareType = (status) => {
    if (status === 1) {
      return 'available'
    } else if (status === 2) {
      return 'orderInProgress'
    } else {
      return 'busyWithoutOrder'
    }
  }

  const reload = () => {
    getTables()
  }

  useEffect(() => {
    getTables()
  }, [])

  const selectedTable = useMemo(() => {
    const tableId = getQueryByName(search, 'table')

    if (tableId) {
      return tables.find(table => table.id === tableId)
    }
  }, [search, tables])

  return (
    <>
      <Header title="Mesas" />
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {tables.map(table => (
            <Grid key={table.id} item xs={6} sm={4} md={3} lg={2}>
              <SquareTable
                type={squareType(table.status)}
                key={table.id}
                table={table}
                onClickOption={handleTableOption}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      <ChangeStatusDialog
        open={openChangeStatusDialog}
        setOpen={setOpenChangeStatusDialog}
        selectedTable={selectedTable}
        reload={reload}
      />

      <AddProductsModal
        isVisible={isAddProductModalVisible}
        restaurantId={user.restaurant_id}
        onClose={handleCloseAddProductModal}
        onDismiss={handleDismissAddProductModal}
      />

      <TableRequestModal
        isVisible={isRequestModalVisible}
        onClose={handleCloseRequestModal}
      />
    </>
  )
}
