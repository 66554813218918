import React from 'react'
import {
  Typography,
  Divider,
  TextField,
  Button,
  Box
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { Snackbar, Dialog } from 'components'
import schema from './schema'
import useStyles from './styles'
import api from 'services/api'

import { useDialog, useSnackbar, useTable } from 'hooks'

function DialogNew ({ reload }) {
  const classes = useStyles()
  const { openSnackbar } = useSnackbar()
  const { table } = useTable()
  const { handleClose, mode } = useDialog()

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data) => {
    try {
      if (mode === 'create') {
        await api.post('/restaurants/tables', data)
        handleClose()
        openSnackbar('Mesa criada com sucesso')
      }
      if (mode === 'edit') {
        await api.put(`/restaurants/tables/${table.id}`, data)
        handleClose()
        openSnackbar('Mesa atualizada com sucesso')
      }

      reload()
    } catch (error) {
      openSnackbar('Ocorreu um erro', 'error')
    }
  }

  return (
    <>
      <Dialog maxWidth="sm">
        <Typography variant="h5">
          Cadastro de mesa
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="number"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Número"
                inputRef={register}
                fullWidth
                error={!!errors.number}
                helperText={errors.number && errors.number.message}
              />
            }
            defaultValue={table.number || ''}
            control={control}
          />

          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={handleClose}>Voltar</Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              className={classes.submitButton}
            >
              Enviar
            </Button>
          </Box>
        </form>
      </Dialog>

      <Snackbar />
    </>
  )
}

export default DialogNew
