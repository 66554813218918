import React from 'react'
import { Controller } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'

export default function TextInput ({ name, control, label, error, ...props }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        onChange,
        value
      }) => (
        <TextField
          variant='outlined'
          margin="normal"
          helperText={error ? error.message : ''}
          error={!!error}
          value={value}
          onChange={onChange}
          fullWidth
          label={label}
          {...props}
        />
      )}
    />
  )
}
