import { makeStyles } from '@material-ui/core'
import { grey, green } from '@material-ui/core/colors'

export default makeStyles((theme) => ({
  root: {
    backgroundColor: grey[200]
  },
  priceTypography: {
    fontSize: 20,
    color: '#03a600',
    fontWeight: 600
  },
  cartButton: {
    color: theme.palette.getContrastText('#03a600'),
    backgroundColor: '#03a600',
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))
