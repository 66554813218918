import React, { useState, useEffect } from 'react'
import {
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Box,
  CircularProgress
} from '@material-ui/core'
import { Snackbar } from 'components'
import { FiPlusCircle, FiTrash } from 'react-icons/fi'

import DialogCategoryProductOptionProduct from '../DialogCategoryProductOptionProduct'
import useStyles from './styles'
import api from 'services/api'
import { useSnackbar } from 'hooks'

function CategoryProductOptionProduct ({ product }) {
  const [openOption, setOpenOption] = useState(false)
  const [modeOption, setModeOption] = useState('create')
  const [productOptionDialog, setProductOptionDialog] = useState({})
  const [productOptions, setProductOptions] = useState([])
  const [loading, setLoading] = useState(false)

  const { openSnackbar } = useSnackbar()

  const classes = useStyles()

  const handleDeleteCategoryProductOptionProduct = async (id) => {
    try {
      await api.delete(`category-product-option-product/${id}`)
      getOptions()
      openSnackbar('Excluído com sucesso')
    } catch (error) {
      openSnackbar('Erro ao excluir', 'error')
    }
  }
  const handleCreateCategoryProductOptionProduct = () => {
    setModeOption('create')
    setProductOptionDialog({})
    setOpenOption(true)
  }

  const getOptions = async () => {
    setLoading(true)
    try {
      const response = await api.get(`/products/${product.id}/options`)

      setProductOptions(response.data.object)
    } catch (error) {
      console.log(error)
    }
    setLoading(false)
  }

  useEffect(() => {
    getOptions()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Typography variant="h6" className={classes.title}>Categoria de Opcionais</Typography>
      {loading ? (
        <Box
          width="100%"
          alignItems="center"
          justifyContent="center"
          display="flex"
          p={5}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="80%">Opcional</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleCreateCategoryProductOptionProduct()}>
                    <FiPlusCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productOptions.map(({ id, category_product_option: categoryProductOption }) => (
                <TableRow key={categoryProductOption.id}>
                  <TableCell>{categoryProductOption.name}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleDeleteCategoryProductOptionProduct(id)}>
                      <FiTrash />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <DialogCategoryProductOptionProduct
        open={openOption}
        handleClose={() => setOpenOption(false)}
        product={product}
        mode={modeOption}
        productOption={productOptionDialog}
        reload={() => getOptions()}
      />

      <Snackbar />
    </>
  )
}

export default CategoryProductOptionProduct
