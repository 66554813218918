import React, { useMemo } from 'react'
import history from 'services/history'

import {
  List,
  ListItem,
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box
} from '@material-ui/core'

import { DropMenu } from 'components'

import useStyle from './styles'

const TABLE_OPTIONS = [
  {
    id: 'changeStatus',
    label: 'TROCAR DE STATUS'
  },
  {
    id: 'addProduct',
    label: 'ADICIONAR PRODUTO'
  },
  {
    id: 'request',
    label: 'VER PEDIDO'
  }
]

export default function SquareTable ({
  type,
  table,
  onClickOption
}) {
  const classes = useStyle()

  const tableStatus = useMemo(() => {
    switch (type) {
      case 'available': return 'Disponível'
      case 'orderInProgress': return 'Mesa em andamento'
      default: return 'Ocupado'
    }
  }, [type])

  const handleOpenDropMenu = () => {
    history.push(`?table=${table.id}`)
  }

  const handleClickOption = option => {
    // todo: close tooltip
    onClickOption(option)
  }

  return (
    <Card className={[classes[type]]}>
      <CardContent className={classes.content}>
        <Box className={classes.iconButton}>
          <DropMenu onOpen={handleOpenDropMenu}>
            <List>
              {TABLE_OPTIONS.map(option => (
                <ListItem key={option.id}>
                  <Button onClick={() => handleClickOption(option)}>
                    {option.label}
                  </Button>
                </ListItem>
              ))}
            </List>
          </DropMenu>
        </Box>

        <Typography variant="h3" className={classes.text}>
          {table.number}
        </Typography>
      </CardContent>

      <CardActions className={classes.action}>
        <Typography className={classes.text} variant="subtitle2">
          {tableStatus}
        </Typography>
      </CardActions>
    </Card>
  )
}
