import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export default makeStyles({
  firstSection: {
    display: 'flex',
    height: '75px',
    backgroundColor: grey[200],
    alignItems: 'center'
  },
  firstSectionText: {
    color: grey[900]
  },
  marginRight16: {
    marginRight: 16
  },
  textDecorationNone: {
    textDecoration: 'none'
  },
  link: {
    color: grey[200],
    fontFamily: 'Ubuntu, Roboto, sans-serif',
    fontSize: 16
  },
  linkSocial: {
    marginRight: '8px'
  },
  footer: {
    backgroundColor: grey[900],
    width: '100%'
  },
  footerMediasContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '50px'
  }
})
