import React from 'react'
import {
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  FormHelperText
} from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'

import { Dialog, Snackbar } from 'components'
import ProductOption from '../ProductOption'

import useStyles from './styles'
import api from 'services/api'
import schema from './schema'

import { useDialog, useSnackbar, useCategoryProductOption } from 'hooks'

export default function DialogCategoryProductOption () {
  const classes = useStyles()

  const { handleClose, mode } = useDialog()
  const { openSnackbar } = useSnackbar()
  const { handleSubmit, register, errors, control } = useForm({
    validationSchema: schema
  })
  const { categoryProductOption } = useCategoryProductOption()

  const onSubmit = async (data) => {
    try {
      if (mode === 'create') {
        await api.post('/category-product-option', data)
        openSnackbar('Criado com sucesso!', 'success')
      } else {
        await api.put(`/category-product-option/${categoryProductOption.id}`, data)
        openSnackbar('Editado com sucesso!', 'success')
      }
      handleClose()
    } catch (error) {
      openSnackbar('Ocorreu um erro!', 'error')
    }
  }

  return (
    <>
      <Dialog maxWidth="md">
        <Typography variant="h5">
          Cadastro de categoria
        </Typography>
        <Divider />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            as={
              <TextField
                variant="outlined"
                margin="normal"
                label="Nome"
                inputRef={register}
                fullWidth
                error={!!errors.name}
                helperText={errors.name && errors.name.message}
              />
            }
            defaultValue={categoryProductOption.name || ''}
            control={control}
          />

          <FormControl fullWidth className={classes.selectInput} variant="outlined">
            <InputLabel id="inputSelect">Tipo</InputLabel>
            <Controller
              as={
                <Select
                  label="Tipo"
                  labelId="inputSelect"
                  id="inputSelect"
                  variant="outlined"
                  disabled={mode === 'show'}
                >
                  <MenuItem value='vanilla'></MenuItem>
                  <MenuItem value='checkbox'>Selecionar várias opções</MenuItem>
                  <MenuItem value='radio'>Selecionar apenas uma</MenuItem>
                </Select>
              }
              name="input"
              control={control}
              defaultValue={
                categoryProductOption.input || 'vanilla'
              }
              error={errors && errors.input}
            />
            <FormHelperText>{errors.input && errors.input.message}</FormHelperText>
          </FormControl>

          <FormControl fullWidth className={classes.selectInput} variant="outlined">
            <InputLabel id="requiredSelect">Obrigatório</InputLabel>
            <Controller
              as={
                <Select
                  label="Obrigatório"
                  labelId="requiredSelect"
                  id="requiredSelect"
                  variant="outlined"
                  disabled={mode === 'show'}
                >
                  <MenuItem value='vanilla'></MenuItem>
                  <MenuItem value='true'>Sim</MenuItem>
                  <MenuItem value='false'>Não</MenuItem>
                </Select>
              }
              name="required"
              control={control}
              defaultValue={String(categoryProductOption.required) || 'vanilla'}
              error={errors && errors.required}
            />
            <FormHelperText>{errors.required && errors.required.message}</FormHelperText>
          </FormControl>

          {mode === 'edit' && <ProductOption categoryProductOption={categoryProductOption} />}

          <Box className={classes.buttons}>
            <Button variant="outlined" onClick={handleClose}>Voltar</Button>
            {mode !== 'show' &&
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit(onSubmit)}
                className={classes.submitButton}
              >
                Enviar
              </Button>
            }
          </Box>
        </form>
      </Dialog>
      <Snackbar />
    </>
  )
}
