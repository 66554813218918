import React from 'react'

import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

import { useSnackbar } from 'hooks'

const SnackbarComponent = () => {
  const { open, setOpen, message, type } = useSnackbar()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert elevation={6} variant="filled" onClose={handleClose} severity={type}>
          {message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default SnackbarComponent
