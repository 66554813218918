import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    width: '100%'
  },
  media: ({ unavailable }) => ({
    height: '140px',
    backgroundColor: '#c4c4c4',
    opacity: unavailable ? 0.7 : 1,
    filter: `grayscale(${unavailable ? 1 : 0})`
  }),
  priceTypography: {
    fontSize: 16,
    color: '#03a600',
    fontWeight: 'medium'
  },
  actionArea: {
    height: '100%'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  descriptionTypography: {
    maxHeight: '96px',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 4,
    WebkitBoxOrient: 'vertical'
  },
  restaurantClosed: {
    backgroundColor: 'red',
    color: '#FFFFFF',
    justifyContent: 'space-between',
    width: '100%',
    position: 'absolute',
    paddingLeft: '30px',
    fontWeight: 'bold',
    zIndex: '9999',
    top: '0px'
  }
})
