import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    borderColor: theme.palette.grey[400],
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.grey[700]
    },
    flexShrink: 0,
    flexGrow: 1
  }
}))
