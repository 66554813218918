import React from 'react'
import Span from 'components/Typography/Span'

const TextService = ({ title, text, classes }) => (
  <div>
    <Span><b>{title}</b></Span>
    <Span className={classes.text_service_card_service}>{text}</Span>
  </div>
)

export default TextService
