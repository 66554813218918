import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { PlatformTemplate } from 'templates'
import { Category, Product, Table, CategoryProductOption, Settings, Users, Requests, Dashboard } from 'pages'
import { CategoryProvider, ProductProvider, UsersProvider ,TableProvider, CategoryProductOptionProvider } from 'providers'

export default function Routes () {
  return (
    <PlatformTemplate>
      <Switch>
        <Route exact path="/app">
          <Dashboard />
        </Route>
        <Route exact path="/app/categories">
          <CategoryProvider>
            <Category />
          </CategoryProvider>
        </Route>
        <Route exact path="/app/products">
          <ProductProvider>
            <Product />
          </ProductProvider>
        </Route>
        <Route exact path="/app/requests">
          <Requests />
        </Route>
        <Route exact path="/app/category-product-options">
          <CategoryProductOptionProvider>
            <CategoryProductOption />
          </CategoryProductOptionProvider>
        </Route>
        <Route exact path="/app/tables">
          <TableProvider>
            <Table />
          </TableProvider>
        </Route>
        <Route exact path="/app/settings">
          <Settings />
        </Route>
        <Route exact path="/app/users">
          <UsersProvider>
            <Users />
          </UsersProvider>
        </Route>
      </Switch>
    </PlatformTemplate>
  )
}
