import React, { useState, useEffect } from 'react'
import {
  List,
  ListItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box
} from '@material-ui/core'

import { Header, DropMenu, Snackbar, DialogConfirmation } from 'components'
import { DialogCategoryProductOption } from './components'

import api from 'services/api'
import { useDialog, useCategoryProductOption, useSnackbar } from 'hooks'
import useStyles from './styles'

export default function CategoryProductOption () {
  const [categoryProductOptions, setCategoryProductOptions] = useState([])
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [deleteCategoryProduct, setDeleteCategoryProduct] = useState(false)

  const classes = useStyles()
  const { setMode, handleOpen, open } = useDialog()
  const { setCategoryProductOption } = useCategoryProductOption()
  const { openSnackbar } = useSnackbar()

  async function getCategoryProductOptions () {
    try {
      const response = await api.get('/category-product-option')

      setCategoryProductOptions(response.data.object)
    } catch (error) {
      console.log(error)
    }
  }

  function reload () {
    getCategoryProductOptions()
  }

  function handleOpenDialogEdit (categoryProductOption, mode) {
    setMode(mode)
    setCategoryProductOption(categoryProductOption)
    handleOpen()
  }
  function handleOpenDialogDestroy (categoryProductOption) {
    setOpenConfirmationDialog(true)
    setDeleteCategoryProduct(categoryProductOption)
  }

  async function destroyCategory () {
    try {
      await api.delete(`/category-product-option/${deleteCategoryProduct.id}`)

      getCategoryProductOptions()
      openSnackbar('Categoria removida com sucesso!')
    } catch (error) {
      openSnackbar('Não foi possível remover a categoria!', 'error')
    }
  }

  useEffect(() => {
    if (!open) getCategoryProductOptions()
  }, [open])

  return (
    <>
      <Header title="Categorias de adicionais" />
      <Box className={classes.categoryBox}>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell width="40%">Categoria</TableCell>
                <TableCell width="20%">Tipo</TableCell>
                <TableCell width="20%">Obrigatório</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categoryProductOptions.map(categoryProductOption => (
                <TableRow key={categoryProductOption.id}>
                  <TableCell>{categoryProductOption.name}</TableCell>
                  <TableCell>{categoryProductOption.input === 'checkbox' ? 'Várias opções' : 'Apenas uma'}</TableCell>
                  <TableCell>{categoryProductOption.required ? 'Sim' : 'Não'}</TableCell>
                  <TableCell align="right">
                    <DropMenu>
                      <List>
                        <ListItem>
                          <Button onClick={() => handleOpenDialogEdit(categoryProductOption, 'edit')}>
                            EDITAR
                          </Button>
                        </ListItem>
                        <ListItem>
                          <Button onClick={() => handleOpenDialogDestroy(categoryProductOption)}>
                            DELETAR
                          </Button>
                        </ListItem>
                      </List>
                    </DropMenu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <DialogCategoryProductOption reload={reload} />
      <DialogConfirmation
        open={openConfirmationDialog}
        setOpen={setOpenConfirmationDialog}
        message="Deseja excluir?"
        handleSubmit={destroyCategory}
      />

      <Snackbar />
    </>
  )
}
