import React from 'react'
import { Container, Box, Paper, Typography, Grid } from '@material-ui/core'

import restaurantNotFoundImg from 'assets/images/logoMenuShop.svg'

import useStyles from './styles'

const HeadSession = ({ restaurant }) => {
  const classes = useStyles()
  return (
    <Container>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box my={5} display="flex" justifyContent="space-around">
            <Paper elevation={3} className={classes.imagePaper}>
              <img src={restaurant.logo ? restaurant.logo : restaurantNotFoundImg} alt="logo-restaurante" className={classes.image} />
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid item xs={12}>
            <Box my={5} fontWeight="700" fontSize='h3.fontSize'>
              {restaurant.name}
            </Box>
          </Grid>
          <Grid item xs={12} lg={10}>
            <Box mt={2}>
              <Typography variant="body1" color="textSecondary" >
                {restaurant.description}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

export default HeadSession
