//import ReactDOM from "react-dom";
import React, { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Typography,
  Button,
  Chip,
  Box,
  Paper,
  Divider,
  // TextField
} from '@material-ui/core'

import { useReactToPrint } from 'react-to-print';

//import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'


import PrintRequest from 'components/PrintRequest'

import { FiPrinter } from 'react-icons/fi'
import { TableGrid } from './components'
import { Snackbar } from 'components'

import { useSnackbar } from 'hooks'
import api from 'services/api'
import {mapRequests} from './requestMapper'
import { format } from 'date-fns';
import { parseISO } from 'date-fns'; 

function Requests () {
  const [requests, setRequests] = useState()
  const [select, setSelection] = useState({})
  const [descriptionRequests, setDescriptionRequests] = useState()
  const { openSnackbar } = useSnackbar()
   const componentRef = useRef();
   const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const getRequests = async () => {
    try {
      var result = await api.get('/requests')

      for (var i = 0; i < result.data.length; i++) {

          if (result.data[i].request_type_id === 1) {
            result.data[i].request_type_name = "Local";
          } else if (result.data[i].request_type_id === 2) {
            result.data[i].request_type_name = "Entrega";
          } else if (result.data[i].request_type_id === 3) {
            result.data[i].request_type_name = "Retirada";
          }
          console.log(result.data[i].created_at)

        const dataCad = format(parseISO(result.data[i].created_at),'dd/MM/yyyy HH:mm')
        result.data[i].created_at = dataCad

      }

      const dataStatus = mapRequests(result.data)
      setRequests(dataStatus)
      updateSelection(dataStatus)
    } catch (err) {
      console.log(err)
    }
  }

  const updateSelection = (requests) => {
     
    const filteredRequest = requests.filter((request) => request.id === select.id)
    if (filteredRequest.length === 0) return

    setSelection(filteredRequest[0])
  } 

 
  const updateStatus = async (id, statusId) => {
    try {
      await api.put(`/requests/${id}`,{
        status:  statusId 
      })

      getRequests()

      openSnackbar('Atualizado com sucesso')
    } catch (err) {
      openSnackbar('Não foi possível atualizar','error')
      console.log(err)
    }
  }

  const automaticLoadRequest = () => {
    setInterval(getRequests, 60000)

  }

  useEffect(() => {
    getRequests()
    automaticLoadRequest()
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4" component="h4">
            Pedidos
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Paper elevation={1} component={Box} p={1}>
            <Box
              p={1}
              display="flex"
              // alignItems="center" 
              // justifyContent="space-between"
            >
              <Typography variant="h6" component="h6">
                Pedidos Ativos
              </Typography>
              {/* <TextField
                id="search-requests"
                label="Buscar Pedido"
                variant="outlined"
                placeholder="ID, Mesa, Nome do cliente..."
              /> */}
            </Box>
            <TableGrid
              rows={requests}
              setSelection={setSelection}
              select={select}
              descriptionRequests={descriptionRequests}
              setDescriptionRequests={setDescriptionRequests}
            />
          </Paper>
        </Grid>
        <Grid item md={6} xs={12}>
          {select?.id && (
            <Paper elevation={1} component={Box} p={1}>
              <Box p={1} display="flex" flexDirection="row" justifyContent="space-between">
                <Typography variant="h6" component="h6">
                  Pedido #{select.request_number || select.id}
                </Typography>
                <Chip label={select.status} />
              </Box>
              <Paper variant="outlined" component={Box} p={1} mb={2}>
                <Box p={1} display="flex" flexDirection="column">
                  <Typography variant="subtitle1" component="subtitle1">
                    <b>Data Pedido:</b> {select?.created_at}
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    <b>Usuário:</b> {descriptionRequests?.user?.name}
                  </Typography>
                  <Typography variant="subtitle1" component="subtitle1">
                    <b>Telefone:</b> {descriptionRequests?.user?.phone?.number}
                  </Typography>
                  <Typography>
                    {select.request_type_id === 1 ? (
                      <>
                        <b>Mesa:</b> {descriptionRequests?.table?.number}
                      </>
                    ):''}

                    {select.request_type_id === 3 ? (
                      <>
                        <b>Pedido a retirar</b>
                      </>
                    ):''}

                    {select.request_type_id === 2 ? (
                      <>
                      <div>
                          <b>Para Entrega:</b> <span style={{ fontSize:'12px' }}>
                            {descriptionRequests?.user?.users_address?.address}, 
                            Nº {descriptionRequests?.user?.users_address?.number} - 
                            {descriptionRequests?.user?.users_address?.complement} - 
                            {descriptionRequests?.user?.users_address?.district} - 
                            {descriptionRequests?.user?.users_address?.zip_code} - 
                            {descriptionRequests?.user?.users_address?.city} / 
                            {descriptionRequests?.user?.users_address?.state} </span>
                      </div>
                      </>
                    ):''}
                  </Typography>

                  <Divider/>
                  
                  {descriptionRequests?.request_products.map((request_product) => (
                    <div key={request_product.id}>
                      <Box py={1} display="flex" flexDirection="column">
                        <Typography variant="subtitle1" component="subtitle1" style={{ fontSize:'11px',flexWrap: 'wrap'}}>
                          <b>Produto:</b> {request_product?.amount}x - {request_product?.product?.name} - 
                          <b style={{ fontSize: '11px',float: 'right' }}> R$ {request_product?.current_price?.toFixed(2)}</b>
                        </Typography>

                        {request_product?.note && (  
                          <Typography variant="subtitle1" component="subtitle1"> 
                            <b>Anotações:</b> {request_product.note}
                          </Typography>
                        )}

                        {request_product?.product_options_request_products?.length > 0 && (
                          <Box pt={1} display="flex" flexDirection="column">
                            <Typography><b>Adicionais</b></Typography>
      
                            {request_product.product_options_request_products.map((product_options_request_product) => (
                              <Typography variant="subtitle1" component="subtitle1">
                                {product_options_request_product?.amount}x - {product_options_request_product?.product_option?.name} - <b>Valor Unit.: R$ {product_options_request_product?.product_option?.price?.toFixed(2)}</b>
                              </Typography>
                            ))}
                          </Box>
                        )}
                      </Box>

                      <Divider />
                    </div>
                  ))}
                  
                  <Box pt={1}>
                    <Typography>
                      <b>Preço Total: R$ {descriptionRequests?.total_price?.toFixed(2)}</b>
                    </Typography>
                  </Box>
                </Box>
              </Paper>
              {select.statusId === 1 && (
                <Button color="primary" fullWidth onClick={() => updateStatus(select.id, 2)}>
                  Trocar para em preparo
                </Button>
              )}
               {select.request_type_id == 3 && select.statusId === 2 && (
                <Button color="primary" fullWidth onClick={() => updateStatus(select.id, 5)}>
                  Trocar para aguardando retirada
                </Button>
              )}

              {select.request_type_id == 1 && select.statusId === 2 && (
                <Button color="primary" fullWidth onClick={() => updateStatus(select.id, 3)}>
                  Trocar para concluído
                </Button>
              )}

              {select.request_type_id == 2 && select.statusId === 2 && (
                <Button color="primary" fullWidth onClick={() => updateStatus(select.id, 4)}>
                  Trocar para Saiu para entrega
                </Button>
              )}


              {select.request_type_id == 2 && select.statusId === 4 && (
                <Button color="primary" fullWidth onClick={() => updateStatus(select.id, 3)}>
                  Trocar para concluído
                </Button>
              )}
              {select.request_type_id == 3 && select.statusId === 5 && (
                <Button color="primary" fullWidth onClick={() => updateStatus(select.id, 3)}>
                  Trocar para concluído
                </Button>
              )}

              <PrintRequest request={descriptionRequests} />
            </Paper>
          )}
        </Grid>
      </Grid>
      <Snackbar />
    </>
  )
}

export default Requests
