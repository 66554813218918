import React, { useState, useEffect } from 'react'

import {
  Dialog,
  Typography,
  TextField,
  Box,
  CircularProgress,
  Grid,
  Button,
  Chip,
  Table,
  TableRow,
  TableCell,
  Checkbox,
  withWidth
} from '@material-ui/core'

import { ButtonCount, Snackbar } from 'components'

import { useForm, Controller } from 'react-hook-form'

import { useCart, useSnackbar } from 'hooks'

import useStyles from './styles'
import api from 'services/api'

const ProductDialog = ({
  open,
  handleClose = () => {},
  productId,
  restaurantId,
  width
}) => {
  const [product, setProduct] = useState({})
  const [loading, setLoading] = useState(true)
  const [count, setCount] = useState(1)

  const { openSnackbar } = useSnackbar()

  const {
    setCartStorage,
    getCartStorage
  } = useCart()

  const classes = useStyles()

  const getProduct = async () => {
    setLoading(true)
    try {
      const product = await api.get(`/products/${productId}`)
      const productOption = await api.get(`/products/${productId}/options`)

      setProduct({ ...product.data.object, options: productOption.data.object })
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (open) {
      getProduct()
    }

  // eslint-disable-next-line
  }, [open])

  const { register, control, handleSubmit } = useForm()

  const onSubmit = async (data) => {
    try {
      let selectedItems = []
      if (data.options) {
        data.options.forEach((option, optionIndex) => {
          const quantitySelected = option.productOptions.filter((productOption) => productOption === true).length
  
          if (quantitySelected === 0) {
            if (product.options[optionIndex].category_product_option.required) {
              throw new Error('Selecione as opções obrigatorias')
            }
          }
  
          if (quantitySelected > 1) {
            if (product.options[optionIndex].category_product_option.input !== 'checkbox') {
              throw new Error('Selecione apenas 1 opção')
            }
          }
  
          option.productOptions.forEach((productOption, productOptionIndex) => {
            if (productOption) {
              selectedItems
                .push(product.options[optionIndex]
                  .category_product_option
                  .product_options[productOptionIndex])
            }
          })
        })
  
        selectedItems = selectedItems.map((selectedItem) => ({
          product_option_id: selectedItem.id,
          amount: 1
        }))
      }

      const productCart = {
        restaurant_id: restaurantId,
        product_id: product.id,
        amount: count,
        note: data.note,
        product_options_request_products: selectedItems
      }

      const cart = getCartStorage()

      if (cart) {
        if (cart.length > 0)
          if (cart[0].restaurant_id !== restaurantId)
            throw new Error('Não é possível adicionar a sua sacola produtos de outro restaurante!')

        cart.push(productCart)
        setCartStorage(cart)
      } else {
        setCartStorage([productCart])
      }

      handleClose()
      openSnackbar('Produto adicionado a sacola')
    } catch (err) {
      openSnackbar(err.message, 'error')
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        fullWidth
        fullScreen={width === 'xs'}
        className={classes.dialog}
      >
        {loading ? (
          <Box
            display='flex'
            height='70vh'
            width='100%'
            justifyContent='center'
            alignItems='center'
          >
            <CircularProgress/>
          </Box>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              className={classes.media}
              style={{ backgroundImage: `url(${product.image})` }}
            />
            <Box p={2}>
              <Typography gutterBottom variant="h5">
                {product.name}
              </Typography>
              <Typography gutterBottom variant="body1">
                {product.description}
              </Typography>

              <Box mb={2}>
                <ButtonCount count={count} setCount={setCount} />
              </Box>

              <Typography gutterBottom variant="caption">
                Observação
              </Typography>

              <Controller
                name="note"
                as={
                  <TextField
                    placeholder="Por exemplo: Tirar Cebola ..."
                    name="note"
                    variant="outlined"
                    margin="none"
                    inputRef={register}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={6}
                  />
                }
                defaultValue=""
                control={control}
              />

              {product.options.map((option, optionIndex) => (
                <>
                  {option?.category_product_option?.product_options?.length > 0 && (
                    <Box mt={2} key={option.id}>
                      <Box
                        className={classes.optionsHeader}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box>
                          <Typography variant="body1" className={classes.optionsHeaderText}>
                            {option.category_product_option.name}
                          </Typography>
                          {option.category_product_option.input !== 'checkbox' &&
                            <Typography variant="caption">
                              Selecione apenas 1
                            </Typography>
                          }
                        </Box>
                        {option.category_product_option.required && (
                          <Chip
                            size="small"
                            label="Obrigatório"
                            className={classes.optionsChip}
                          />
                        )}
                      </Box>
                      <Table>
                        {option?.category_product_option?.product_options?.map((productOption, productOptionIndex) => (
                          <TableRow key={productOption.id} role="checkbox">
                            <TableCell>{productOption.name}</TableCell>
                            <TableCell>R$ {productOption.price.toFixed(2)}</TableCell>
                            <TableCell align="right">
                              <Controller
                                name={`options[${optionIndex}].productOptions[${productOptionIndex}]`}
                                render={({ onChange, value, ...rest }) => (
                                  <Checkbox
                                    {...rest}
                                    onChange={e => onChange(e.target.checked)}
                                    checked={value}
                                  />
                                )}
                                defaultValue={false}
                                control={control}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </Table>
                    </Box>
                  )}
                </>
              ))}

              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Button color="secondary" fullWidth variant="outlined" onClick={() => handleClose()}>Voltar</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button type="submit" color="primary" fullWidth variant="contained">Adicionar</Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </form>
        )}
      </Dialog>

      <Snackbar />
    </>
  )
}

export default withWidth()(ProductDialog)
