import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'

import DialogAddressForm from './DialogAddressForm'

export default function DialogAddress ({ isVisible, onClose, onAddressCreated, address }) {
  return (
    <Dialog open={isVisible} onClose={onClose}>
      <DialogTitle>
        {address ? 'Usar esse endereço?' : 'Informe o endereço de entrega.'}
      </DialogTitle>

      <DialogContent>
        <DialogAddressForm
          address={address}
          onCancel={onClose}
          onAddressCreated={onAddressCreated}
        />
      </DialogContent>
    </Dialog>
  )
}
