import { useContext } from 'react'

import snackbarContext from 'contexts/snackbarContext'

/**
 * @typedef {'success' | 'warning' | 'info' | 'error'} SnackbarType
 *
 * @typedef {Object} UseSnackBarValue
 * @property {string} message
 * @property {SnackbarType} type
 * @property {boolean} open
 * @property {(message: string) => void} setMessage
 * @property {(type: SnackbarType) => void} setType
 * @property {(messsage: string, type: SnackbarType) => void} openSnackbar
 *
 * @returns {UseSnackBarValue}
 */
function useSnackbar () {
  return useContext(snackbarContext)
}

export default useSnackbar
