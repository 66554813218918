import React, { useState, useEffect } from 'react'
import {
  Typography,
  Divider,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Box,
  FormHelperText,
  Grid
} from '@material-ui/core'
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons'

import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

import { Dialog, Snackbar, DialogImage } from 'components'
import CategoryProductOptionProduct from '../CategoryProductOptionProduct'

import schema from './schema'
import useStyles from './styles'
import api from 'services/api'

import { useDialog, useSnackbar, useProduct } from 'hooks'

const MODE = {
  create: 'Cadastrar',
  edit: "Editar",
  inactivate: "Inativar"
}

function DialogProduct ({ reload }) {
  const [openImage, setOpenImage] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [categories, setCategories] = useState([])

  const { handleClose, mode, open } = useDialog()
  const { openSnackbar } = useSnackbar()
  const { product, setProduct } = useProduct()

  const hasImage = !!product.image

  const reloadOption = async () => {
    try {
      const response = await api.get(`/products/${product.id}`)
      setProduct(response.data.object)
    } catch (err) {
      console.log(err)
    }
  }

  const classes = useStyles()

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    getCategories()
  }, [])

  useEffect(() => {
    if (mode === 'create') {
      setProduct({})
    }

    // eslint-disable-next-line
  }, [open])

  const getCategories = async () => {
    try {
      const categories = await api.get('/categories')

      setCategories(categories.data.object)
    } catch (error) {
      console.log(error)
    }
  }

  const onSubmit = async (data) => {
    try {
      const { code, ...rest } = data
      if (mode === 'create') {
        await api.post('/products', { code: code.length === 0 ? null : code, ...rest })
        handleClose()
        openSnackbar('Produto criado com sucesso')
      }
      if (mode === 'edit') {
        await api.put(`/products/${product.id}`, { code: code.length === 0 ? null : code, ...rest })
        handleClose()
        openSnackbar('Produto atualizado com sucesso.')
      }

      reload()
    } catch (error) {
      openSnackbar('Ocorreu um erro.', 'error')
    }
  }

  const upload = async (e) => {
    setLoadingUpload(true)
    try {
      const formData = new FormData()
      const file = e.target.files[0]
      formData.append('file', file)

      const { data } = await api.post(`/products/${product.id}/image`, formData, {
        headers: {
          'content-type': 'multipart/form-data'
        }
      })

      openSnackbar('Imagem atualizada com sucesso.')

      setProduct({ ...product, image: data.object.image })
    } catch (err) {
      console.log(err)

      openSnackbar('Ocorreu um erro ao atualizar a imagem, tente novamente em alguns instantes.', 'error')
    }
    setLoadingUpload(false)
  }

  const handleChangeStatus = async () => {
    try {
      await api.put(`products/inactivate/${product.id}`)
      openSnackbar('Produto inativado com sucesso')
      handleClose()
      reload()
    } catch (error) {
      openSnackbar('Ocorreu um erro ao inativar o produto!', 'error')
      handleClose()
    }
  }

  return (
    <>
      <Dialog maxWidth="md">
        <Typography variant="h5">
          {`${MODE[mode]} produto`}
        </Typography>
        <Divider />
        {mode === 'edit' && (
          <Box my={2}>
            <Grid container spacing={2}>
              {hasImage &&
                <Grid item xs={6}>
                  <Button variant="outlined" fullWidth onClick={() => setOpenImage(true)}>Ver Imagem</Button>
                </Grid>
              }

              <Grid item xs={hasImage ? 6 : 12}>
                <Button variant="contained" component="label" disabled={loadingUpload} fullWidth startIcon={<CloudUploadIcon />}>
                  {loadingUpload ? 'Enviando' : 'Upload imagem'}
                  <input type="file" accept="image/png, image/jpeg" hidden onChange={upload} />
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
        {mode === 'inactivate' ? (
          <>
            <Typography variant="h6" my={3}>Deseja inativar este produto?</Typography>
            <Typography my={2}>O produto inativo ficará indisponível para o consumidor</Typography>
            <Box className={classes.buttons}>
              <Button variant="outlined" onClick={handleClose}>Voltar</Button>
              {mode !== 'show' &&
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleChangeStatus}
                  className={classes.submitButton}
                >
                  Inativar
                </Button>
              }
            </Box>
          </>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="code"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Código"
                  type="number"
                  inputRef={register}
                  fullWidth
                  error={!!errors.code}
                  helperText={errors.code && errors.code.message}
                />
              }
              defaultValue={product.code || ''}
              control={control}
            />
            <Controller
              name="name"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Nome"
                  inputRef={register}
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name && errors.name.message}
                />
              }
              defaultValue={product.name || ''}
              control={control}
            />
            <Controller
              name="description"
              as={
                <TextField
                  variant="outlined"
                  multiline
                  rows={3}
                  margin="normal"
                  label="Descrição"
                  inputRef={register}
                  fullWidth
                  error={!!errors.description}
                  helperText={
                    errors.description &&
                    errors.description.message
                  }
                />
              }
              defaultValue={product.description || ''}
              control={control}
            />
            <Controller
              name="price"
              as={
                <TextField
                  variant="outlined"
                  margin="normal"
                  label="Preço"
                  type="number"
                  inputRef={register}
                  fullWidth
                  error={!!errors.price}
                  helperText={
                    errors.price &&
                    errors.price.message
                  }
                />
              }
              defaultValue={product?.price?.toFixed(2) || ''}
              control={control}
            />

            <FormControl fullWidth className={classes.selectInput} variant="outlined">
              <InputLabel id="categoryIdSelectLabel">Categoria</InputLabel>
              <Controller
                as={
                  <Select
                    label="Categoria"
                    labelId="categoryIdSelectLabel"
                    id="categoryIdSelect"
                    variant="outlined"
                    disabled={mode === 'show'}
                  >
                    {categories.map(category => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                }
                name="category_id"
                control={control}
                defaultValue={product.category_id || 'vanilla'}
                error={errors && errors.category_id}
              />
              <FormHelperText>{errors.category_id && errors.category_id.message}</FormHelperText>
            </FormControl>

            {mode === 'edit' && <CategoryProductOptionProduct product={product} reload={reloadOption} />}

            <Box className={classes.buttons}>
              <Button variant="outlined" onClick={handleClose}>Voltar</Button>
              {mode !== 'show' &&
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(onSubmit)}
                  className={classes.submitButton}
                >
                  Enviar
                </Button>
              }
            </Box>
          </form>
        )}
      </Dialog>

      <DialogImage
        open={openImage}
        handleClose={() => setOpenImage(false)}
        imageUrl={product.image}
      />

      <Snackbar />
    </>
  )
}

export default DialogProduct
