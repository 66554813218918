import { useEffect } from 'react'

/**
 * @param { Function } fn - Function to be executed each 10 seconds.
 * @param { Boolean } condition - Conditional, if `true` it stop updates.
 */
export const useRealtimeUpdates = (fn, condition) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (condition) {
        clearInterval(intervalId)
        return
      }

      fn()
    }, 10000)

    return () => clearInterval(intervalId)
  }, [])
}
