import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%'
  },
  content: {
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: 'auto'
  },
  footer: {
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 'auto'
  },
  fab: {
     position: 'fixed',
     bottom: theme.spacing(3),
   right: theme.spacing(3)

  }
  
}))
