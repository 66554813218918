import React, { useState, useEffect } from 'react'
import {
  Typography,
  List,
  ListItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box,
  Switch
} from '@material-ui/core'

import { Header, DropMenu, Snackbar } from 'components'
import { DialogProduct } from './components'

import api from 'services/api'
import { useDialog, useProduct, useSnackbar } from 'hooks'
import useStyles from './styles'

function Product () {
  const [categoriesProducts, setCategoriesProducts] = useState([])
  const classes = useStyles()
  const { setMode, handleOpen } = useDialog()
  const { setProduct } = useProduct()
  const { openSnackbar } = useSnackbar()

  async function getCategoriesProducts () {
    try {
      const response = await api.get('/products?available_options=false')

      setCategoriesProducts(response.data.object)
    } catch (error) {
      console.log(error)
    }
  }

  function reload () {
    getCategoriesProducts()
  }

  function handleOpenDialog (product, mode) {
    setMode(mode)
    setProduct(product)
    handleOpen()
  }

  async function handleDelete (productId) {
    try {
      const response = await api.delete(`/products/${productId}`)

      reload()
      openSnackbar(response.data.message)
    } catch (error) {
      openSnackbar('Ocorreu um erro ao excluir o produto.')
    }
  }

  useEffect(() => {
    getCategoriesProducts()
  }, [])

  const handleChangeProductStatus = async (product, categoryId) => {
    if (!product.inactive) {
      handleOpenDialog(() => {
        product.category_id = categoryId
        return product
      }, 'inactivate')
    } else {
      try {
        await api.put(`products/activate/${product.id}`)
        openSnackbar('Produto ativado com sucesso')
        reload()
      } catch (error) {
        openSnackbar('Ocorreu um erro ao ativar o produto', 'error')
      }
    }
  }

  return (
    <>
      <Header title="Produtos" />

      {categoriesProducts.map((category) => (
        <Box key={category.id} className={classes.categoryBox}>
          <Typography variant="h5" className={classes.categoryName}>{category.name}</Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width="5%">Código</TableCell>
                  <TableCell width="45%">Nome</TableCell>
                  <TableCell width="30%">Preço (R$)</TableCell>
                  <TableCell width="30%">Status do Produto</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {category.products.map(product => (
                  <TableRow key={`${category.id}-${product.id}`}>
                    <TableCell>{product.code}</TableCell>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.price.toFixed(2)}</TableCell>
                    <TableCell>
                      <Switch
                        checked={!product.inactive}
                        onChange={() => handleChangeProductStatus(product, category.id)}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <DropMenu>
                        <List>
                          <ListItem>
                            <Button fullWidth onClick={() => handleOpenDialog(() => {
                              product.category_id = category.id
                              return product
                            }, 'inactivate')}>
                              INATIVAR
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button fullWidth onClick={() => handleOpenDialog(() => {
                              product.category_id = category.id
                              return product
                            }, 'edit')}>
                              EDITAR
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button fullWidth onClick={() => handleOpenDialog(() => {
                              product.category_id = category.id
                              return product
                            }, 'show')}>
                              VISUALIZAR
                            </Button>
                          </ListItem>
                          <ListItem>
                            <Button fullWidth onClick={() => handleDelete(product.id)}>
                              DELETAR
                            </Button>
                          </ListItem>
                        </List>
                      </DropMenu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ))}

      <DialogProduct reload={reload} />
      <Snackbar />
    </>
  )
}

export default Product
