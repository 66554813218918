import yup from 'config/yup'

export default yup.object().shape({
  address: yup.string().required(),
  number: yup.string().required().test(number => {
    if (isNaN(number)) return false
    return true
  }),
  complement: yup.string().nullable(),
  district: yup.string().required(),
  zipCode: yup.string().min(8).max(9).required(),
  city: yup.string().required(),
  state: yup.string().required().max(2)
})
