import React, { useState, useEffect } from 'react'
import { isMobile, isMobileOnly, isTablet } from 'react-device-detect'
import { Grid, Box } from '@material-ui/core'
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons'

import { ProductCard, ProductDialog } from './components'

import api from 'services/api'

import useStyle from './styles'

const GridProducts = ({ products, restaurantId }) => {
  const [open, setOpen] = useState(false)
  const [productId, setProductId] = useState(0)
  const [schedule, setSchedule] = useState(0)
  const [showcase, setShowcase] = useState(0)
  const classes = useStyle()

  const getSchedule = async () => {
    const restaurantData = await api.get(`/restaurants?slug=true&id=${restaurantId}`)

    var schedule = restaurantData.data.object.restaurants[0].schedule

    setSchedule(schedule)
    setShowcase(restaurantData.data.object.restaurants[0].showcase)
  }

  useEffect(() => {
    getSchedule()
  }, [])

  const handleOpenDialog = (productId) => {
    setProductId(productId)
    return setOpen(true)
  }

  const handleCloseDialog = () => {
    setProductId({})
    return setOpen(false)
  }

  return (
    <>
      <Grid container spacing={4}>
        {products.map((product) => (
          <Grid item xs={12} sm={6} lg={4} key={product.id}>
            <Box display="flex" justifyContent="center">
              <ProductCard
                product={product}
                restaurantId={restaurantId}
                handleOpenDialog={handleOpenDialog}
                attendance={schedule}
                showcase={showcase}
              />
            </Box>
          </Grid>
        ))}

      </Grid>

      <ProductDialog
        open={open}
        handleClose={handleCloseDialog}
        productId={productId}
        restaurantId={restaurantId}
      />
    </>

  )
}

export default GridProducts
