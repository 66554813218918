import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4)
  },
  price_item: {
  	alignItems: 'right',
  	display: 'flex'
  }
}))
