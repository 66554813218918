import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  image: {
    width: '100px',
    height: '100px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing(1)
  },
  typographyName: {
    fontWeight: 700,
    textAlign: 'justify'
  },
  typographyPrice: {
    fontWeight: 600,
    textAlign: 'justify',
    color: '#03a600'
  },
  boxTrashIcon: {
    width: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))
