import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { PlatformTemplate } from 'templates'
import { RequestsTables, RequestsTablesAdd } from 'pages'

export default function Routes () {
  return (
    <PlatformTemplate>
      <Switch>
        <Route exact path="/garcon/requestsTables">
          <RequestsTables />
        </Route>
        <Route exact path="/garcon/RequestsTablesAdd">
          <RequestsTablesAdd />
        </Route>
      </Switch>
    </PlatformTemplate>
  )
}
