import React, { useState } from 'react'

import { Dialog, DialogTitle, DialogActions, Button, DialogContent, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

const DialogSelectTable = ({ open, setOpen, handleSubmit }) => {
  const [value, setValue] = useState()

  const handleClose = () => {
    setOpen(false)
  }

  const submit = () => {
    handleClose()
    handleSubmit(value)
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Consumo</DialogTitle>

      <DialogContent>
        <FormControl component="fieldset">
          <RadioGroup aria-label="consuption" name="consuption" value={value} onChange={handleChange}>
            <FormControlLabel value="3" control={<Radio />} label="Retirada" />
            <FormControlLabel value="2" control={<Radio />} label="Entrega" />
          </RadioGroup>
        </FormControl>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          Cancelar
        </Button>
        <Button onClick={submit} color="primary" disabled={!value}>
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogSelectTable
