import { makeStyles } from '@material-ui/core'

export default makeStyles({
  imagePaper: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '150px',
    borderRadius: '20px'
  },
  image: {
    height: '150px',
    width: '150px',
    borderRadius: '20px'
  }
})
