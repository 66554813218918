import React, { useState } from 'react'

import LoadingContext from '../contexts/loadingContext'

function LoadingProvider ({ children }) {
  const [loading, setLoading] = useState(false)

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </LoadingContext.Provider>
  )
}

export default LoadingProvider
