import { makeStyles } from '@material-ui/core'

export default makeStyles((theme) => ({
  dialog: {
    position: 'relative',
    padding: 24
  },
  image: {
    maxWidth: 912
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  }
}))
