import React from 'react'

import { DataGrid } from '@material-ui/data-grid'

function TableGrid ({ rows }) {
  const columns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'status', headerName: 'Status', width: 170 },
    { field: 'total_price', headerName: 'Preço', width: 170 }
  ]

  return (
    <div style={{ height: 400, width: '100%' }}>
      {rows && <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
      />}
    </div>
  )
}

export default TableGrid
