import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    width: '100%',
    maxWidth: '500px',
    minWidth: '320px',
    cursor: 'pointer'
  },
  imgContainer: {
    width: '108px',
    height: '108px',
    borderRadius: '8px'
  },
  rating: {
    color: '#FFBE00'
  },
  ratingAlign: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row'
  },
  ratingTotal: {
    color: '#BDBDBD'
  },
  titleName: {
    fontWeight: 'bold'
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  situacao: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    color: '#BDBDBD',
    fontWeight: 'bold',
    alignSelf: 'left'
  },
  funcionamento: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    color: '#BDBDBD',
    fontWeight: 'bold',
    alignSelf: 'left',
    top: '-50px',
    fontSize: '0.8rem'
  },
})
