import { makeStyles } from '@material-ui/core'

export default makeStyles({
  root: {
    width: '100%',
    height: '392px',
    backgroundImage: 'url(https://menushopimages.nyc3.digitaloceanspaces.com/assets/bannerRestaurants.png)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  rootSearchBar: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    minWidth: '250px',
    maxWidth: '500px'
  },
  card: {
    width: '400px',
    minWidth: '100px'
  },
  searchBar: {
    margin: '10px !important',
    width: '310px'
  },
  title: {
    color: '#FFFFFF',
    marginBottom: '30px',
    textAlign: 'center'
  },
  iconButton: {
    color: 'gray',
    padding: 10
  },
  input: {
    marginLeft: '8px',
    flex: 1
  }
})
