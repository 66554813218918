import React from 'react'
import { Container, Box, Grid, Icon, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { FiFacebook, FiInstagram } from 'react-icons/fi'
import { isMobile, CustomView } from 'react-device-detect'
import { ClipPathBottom } from '../'
import logoBottom from 'assets/images/logoBottom.png'

const Footer = ({ classes }) => (
  <>
    <CustomView condition={!isMobile}>
      <ClipPathBottom classes={classes} />
    </CustomView>
    <Box className={classes.first_section}>
      <Container className={`${classes.footer_links_container} ${classes.container}`}>
        <Grid container item xs={10} className={classes.container}>
          {
            isMobile &&
            <Grid container className={isMobile && classes.container} sm={12}>
              <img src={logoBottom} alt="Logo" className={classes.logo_bottom} /><br />
            </Grid>
          }
          <Box justifyContent="center">
            <Typography className={classes.link}>menushopmarilia@gmail.com</Typography>
            { /* <Box className={classes.links}>
              <Link className={`${classes.link} ${classes.margin_right_16}`} to="/">Institucional</Link>
              <Link className={`${classes.link} ${classes.margin_right_16}`} to="/">Restaurantes</Link>
              <Link className={`${classes.link} ${classes.margin_right_16}`} to="/">Contato</Link>
            </Box> */}
          </Box>
        </Grid>
      </Container>
    </Box>
    <Box className={classes.footer}>
      <Container className={classes.footer_medias_container}>
        <Box className={classes.links}>
          <Link className={`${classes.link} ${!isMobile && classes.margin_right_16}`} to="/">2020 - Menu Shop</Link>
        </Box>
        <Box>
          <a className={`${classes.link} ${classes.link_social}`} href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
            <Icon>
              <FiInstagram />
            </Icon>
          </a>
          <a className={`${classes.link} ${classes.link_social}`} href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
            <Icon>
              <FiFacebook />
            </Icon>
          </a>
        </Box>
      </Container>
    </Box>
  </>
)

export default Footer
