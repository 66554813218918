import React, { useEffect, useState } from 'react'

import { Paper, Grid } from '@material-ui/core'

import { Header, SquareTable, DialogNew, ChangeStatusDialog,ChangeOpenQrcode } from './components'
import useStyles from './styles'
import api from 'services/api'

import { useUser } from 'hooks'

export default function Table () {
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false)
  const [selectedTable, setSelectedTable] = useState({})
  const [openChangeQrcode, setOpenChangeQrcode] = useState(false)

  const classes = useStyles()
  const [tables, setTables] = useState([])
  const { user } = useUser()

  const getTables = async () => {
    try {
      const response = await api.get(`/restaurants/${user.restaurant_id}/tables`)
 
      setTables(response.data.tables)
    } catch (error) {
      console.log((error.response && error.response.data) || error)
    }
  }

  const handleOpenChangeStatusDialog = (table) => {
    setSelectedTable(table)
    setOpenChangeStatusDialog(true)
  }

  const handleQrcode = (table) => {
    setSelectedTable(table)
    setOpenChangeQrcode(true)
  }

  const squareType = (status) => {
    if (status === 1) {
      return 'available'
    } else if (status === 2) {
      return 'orderInProgress'
    } else {
      return 'busyWithoutOrder'
    }
  }

  const reload = () => {
    getTables()
  }

  useEffect(() => {
    getTables()

    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Header title="Mesas" />

      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {tables.map(table => (
            <Grid key={table.id} item xs={6} sm={4} md={3} lg={2}>
              <SquareTable
                type={squareType(table.status)}
                key={table.id}
                table={table}
                reload={reload}
                handleOpenChangeStatusDialog={() => handleOpenChangeStatusDialog(table)}
                handleQrcode={() => handleQrcode(table)}
               
              />
            </Grid>
          ))}
        </Grid>
      </Paper>

      <DialogNew reload={reload} />
      <ChangeStatusDialog
        open={openChangeStatusDialog}
        setOpen={setOpenChangeStatusDialog}
        selectedTable={selectedTable}
        reload={reload}
      />
      <ChangeOpenQrcode
        open={openChangeQrcode}
        setOpen={setOpenChangeQrcode}
        selectedTable={selectedTable}
        reload={reload}
         handleQrcode={() => handleQrcode(selectedTable)}
      />
    </>
  )
}
