import { makeStyles } from '@material-ui/core/styles'

export default makeStyles({
  root: {
    fontSize: 54
  },
  colorDark: {
    color: '#000'
  },
  colorLight: {
    color: '#FFF'
  }
})
