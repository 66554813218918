import React from 'react'
import { Container, Box, Icon, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'

import { FiFacebook, FiInstagram } from 'react-icons/fi'
import { isMobile } from 'react-device-detect'
import useStyle from './styles'

const Footer = (props) => {
  const classes = useStyle()
  return (
    <Box {...props}>
      <Box className={classes.firstSection}>
        <Container direction="row" justify="center">
          <Box display="flex">
            <Link className={`${classes.firstSectionText} ${classes.textDecorationNone}`} to="/site">
              <Typography>Site</Typography>
            </Link>
          </Box>
        </Container>
      </Box>
      <Box className={classes.footer}>
        <Container className={classes.footerMediasContainer}>
          <Box className={classes.links}>
            <Link className={`${classes.link} ${classes.textDecorationNone} ${!isMobile && classes.marginRight16}`} to="/">
              2020 - Menu Shop
            </Link>
          </Box>
          <Box>
            <a className={`${classes.link} ${classes.linkSocial}`} href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <Icon>
                <FiInstagram />
              </Icon>
            </a>
            <a className={`${classes.link} ${classes.linkSocial}`} href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <Icon>
                <FiFacebook />
              </Icon>
            </a>
          </Box>
        </Container>
      </Box>
    </Box>
  )
}

export default Footer
