import React from 'react'
import { Button, Box, TextField } from '@material-ui/core'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Snackbar as SnackbarComponent } from 'components'
import { useSnackbar, useLoading, useUser } from 'hooks'

import history from 'services/history'

import api from 'services/api'
import schema from './schema'

const Form = () => {
  const { openSnackbar } = useSnackbar()
  const { setLoading } = useLoading()
  const { signIn }  = useUser()

  const { register, handleSubmit, errors, control } = useForm({
    validationSchema: schema,
    defaultValues: {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      phoneNumber: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const onSubmit = async (data) => {
    setLoading(true)
    try {
      data.profile_id = 3

      await api.post('/users', {
        name: data.name,
        password: data.password,
        email: data.email,
        profile_id: 3,
        phone: {
          number: data.phoneNumber
        }
      })

      const response = await api.post('/sessions', {
        email: data.email,
        password: data.password,
      })

      signIn(response.data.user, response.data.token)

      openSnackbar('Usuário criado com sucesso!')

      history.push('/')
    } catch (error) {
      openSnackbar(error.response.data.message, 'error')
    }
    setLoading(false)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Nome"
              inputRef={register}
              fullWidth
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          }
          control={control}
        />
        <Controller
          name="email"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="E-mail"
              inputRef={register}
              fullWidth
              error={!!errors.email}
              helperText={errors?.email?.message}
            />
          }
          control={control}
        />
        <Controller
          name="password"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Senha"
              type="password"
              inputRef={register}
              fullWidth
              error={!!errors.password}
              helperText={errors?.password?.message}
            />
          }
          control={control}
        />
        <Controller
          name="passwordConfirmation"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Confirme a sua senha"
              type="password"
              inputRef={register}
              fullWidth
              error={!!errors.passwordConfirmation}
              helperText={errors?.passwordConfirmation?.message}
            />
          }
          control={control}
        />
        <Controller
          name="phoneNumber"
          as={
            <TextField
              variant="outlined"
              margin="normal"
              label="Telefone"
              inputRef={register}
              fullWidth
              error={!!errors.phoneNumber}
              helperText={errors?.phoneNumber?.message}
            />
          }
          control={control}
        />

        <Box my={1}>
          <Button type="submit" color="primary" fullWidth variant="contained">Cadastrar</Button>
        </Box>
      </form>

      <SnackbarComponent />
    </>
  )
}

export default Form
