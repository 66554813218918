import React from 'react'
import { Box, Button } from '@material-ui/core'
import { FiPlus } from 'react-icons/fi'

import { Title } from 'components'
import useStyles from './styles'

import { useDialog, useRestaurant } from 'hooks'
function Header ({ title }) {
  const classes = useStyles()
  const { handleOpen, setMode } = useDialog()
  const { setRestaurant } = useRestaurant()
  const handleOpenDiolog = () => {
    setMode('create')
    setRestaurant({})
    handleOpen()
  }

  return (
    <Box className={classes.header} display="flex" justifyContent="space-between" alignItems="center">
      <Title title={title} />

      <Button
        color="secondary"
        variant="contained"
        startIcon={<FiPlus />}
        onClick={() => handleOpenDiolog()}
      >
        Novo
      </Button>
    </Box>
  )
}

export default Header
